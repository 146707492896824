import * as React from "react";
import { Tabs, Button, Popover, Overlay } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import * as TaxDocumentStore from "../../../store/common/TaxDocumentStore";
import * as MailingReturnAddressStore from "../../../store/common/MailingReturnAddressStore";
import * as UserSettingStore from "../../../store/userManagement/UserSettingStore";
import * as TaxDocument from "../../common/TaxReturn";
import * as User from "../../../Core/ViewModels/User/UserViewModel";
import { IUserProfile } from "../../navigation/profile/ProfileObjects";
import {
    ICompanySettings,
    ReportingEngineType,
    SignatureFormSelectionType,
    DueDateType
} from "../../../Core/ViewModels/Company/CompanySettingsViewModel";
import { ProcessReturnConstants, ClientInfoValidation } from "../../helper/Constants";
import {
    DocumentStatus,
    IDocumentSettings,
    IDeliverySettings,
    ITaxDocumentAccessViewModel,
    ITaxDocumentViewModel
} from "../../common/TaxReturn";
import { GroupFinishPopover } from "../../GroupedReturns/TaxReturnProcessing/GroupFinishPopover";
import { HideLoader, ShowLoader } from "../../helper/Loader";
import { ISelectedDocumentForDeliveryViewModel } from "../../../store/common/TaxDocumentStore";
import { ControllerInfoProperty } from "../../../store/groupedreturns/GroupedReturnProcessStore";
import { TabGroupDelivery } from "../../../components/GroupedReturns/TaxReturnProcessing/Tabs/TabGroupDelivery";
import * as PdfStore from "../../../store/pdf/PdfStore";
import * as CompanyStore from "../../../store/company/CompanyStore";
import * as Company from "../../../store/company/CompanyStore";
import * as VoucherStore from "../../../store/ProcessReturn/voucher/VoucherStore";
import * as Helper from "../../helper/HelperFunctions";
import { CustomWindow, CustomWindowFooter, CustomWindowBody } from "./CustomWindow/CustomWindow";
import { IPdfDocumentFacade } from "../../../Core/Utilities/PdfDocumentFacade";
import { TabDeliveryOptions } from "../../common/ProcessReturnModal/Tabs/TabDeliveryOptions";
import { GroupsView } from "./GroupsView";
import {
    ClientInfoView,
    IBookmarkSection,
    Transmittalsview,
    VoucherInfoView,
    InvoiceInfoView,
    TaxReturnInfoView,
    K1sView,
    EfileInfoView,
    IDelegatedEROSigner,
    IShareHolder,
    ProcessReturnTabs,
    processReturnActionEndPoints,
    NO_INDEX
} from "./ProcessReturnModels";
import { TabClientInfo } from "./Tabs/TabClientInfo";
import { TabTransmittals } from "./Tabs/TabTransmittals";
import { TabTaxReturn } from "./Tabs/TabTaxReturn";
import { TabEFile } from "./Tabs/TabEFile";
import { TabK1s } from "./Tabs/TabK1s";
import { container } from "../../../Startup/inversify.config";
import { ILocalStore } from "../../../Core/Utilities/LocalStore";
import { TYPES } from "../../../Startup/types";
import { CarouselImage, PageCarousel } from "./PageCarousel/PageCarousel";
import { PDFPageProxy } from "pdfjs-dist";
import {
    IFormBase,
    DocumentGroups,
    IVoucher,
    ITaxingAuthority,
    ClientTypes,
    ITaxReturn,
    SignatureControlRole,
    IEFile,
    EntityType,
    ConsentStatus,
    DeliveryMode,
    IClientInfoViewModel,
    IDocumentAccess,
    SignatureType
} from "../../common/TaxReturn";
import { FinishPopover } from "./FinishProcessReturn/FinishPopover";
import { Guid } from "guid-typescript";
import { Guid as GRGuid } from "../../../Core/Utilities/Guid";
import { TabVoucher } from "./Tabs/TabVoucher";
import { TabInvoice } from "./Tabs/TabInvoice";
import { AttachmentsState, AttachmentsTableModel, IAttachmentsDictionary } from "../../../store/ProcessReturn/AttachmentsState";
import { TabAttachment } from "./Tabs/TabAttachments";
import * as SavedMessageStore from "../../../store/settings/SavedMessageStore";
import * as UserSignatureStore from "../../../store/common/UserSignatureStore";
import * as UserHelper from "../../helper/UserHelperFunctions";
import { VoucherSort } from "./Voucher/VoucherSort";
import { ISubDocument } from "../../../Core/Utilities/PdfDocumentFacade";
import { GroupFilesPreview } from "./GroupFilesPreview";
import * as Constants from "../../helper/Constants";
import { VenusNotifier } from "../../helper/VenusNotifier";
import { validateTaxReturn, validateRefudInfo, ValidateWebsite, validateRetentionPeriod } from "../../helper/Validations";
import { IBusinessReturnsState } from "../../../store/settings/BusinessReturnsStore";
import { IReportPopupState } from "../../common/PopupState";
import * as AttachmentInstructionStore from "../../../store/settings/AttachmentInstructionStore";
import { TabAdditionalEsignDocuments } from "./Tabs/TabAdditionalEsignDocuments";
import { TabPaperFile } from "./Tabs/TabPaperFile";
import { DocumentUploadData } from "./AdditionalEsignDocuments/AdditionalEsignDocument";
import * as AdditionalDocumentTypeStore from "../../../store/common/AdditionalDocumentTypeStore";
import { IWatermarkModel } from "../../../components/settings/GeneralSettingsComponents/WatermarkComponents/WatermarkSetting";
import { ICustomColumn, IColumnValues } from "../../settings/GeneralSettings";
import { TabEFileConstants } from "../../helper/Constants";
import { IGroupInfo } from "../../../store/groupedreturns/GroupedReturnProcessState";
import { initialGroupInfo } from "../../../store/groupedreturns/GroupedReturnProcessStore";
import { IReportProblemDetails } from "../../common/ReportProblem/ReportProblemModel";
import { cloneDeep, forEach } from "lodash";
import { ILogger } from "../../../Logger/Logger";
import { LoggerFactory } from "../../../Logger/LoggerFactory";
import { PaperFileUploadData } from "./PaperFile/PaperFile";
import * as PaperFileMessageStore from "../../../store/settings/PaperFiledMessageStore";
import { getRequestVerificationToken } from "../../../oidcClient/helpers";
import { logger } from "../../../routes/LoggedIn";
import { IBlobFile } from "../../../store/common";
import { ISSRSettings } from "../../../Core/ViewModels/Company/Settings/SSRSettingsViewModel";
import { SendTaxReturnResources } from "../../helper/ResourceIdConstants";
import { IAuthState } from "../../../store/auth/reducer";
import ClientInfoProperty from "../ClientInfoEnum";
import { ITaxClient, ITaxpayer } from "../TaxClient";
import MismatchModal from "../MismatchModal/MismatchModal";
import { addNewClientInfo, compareClientInfo, editClientInfo } from "../MismatchModal/MismatchModal.Api";
import {
    IClientInfoComparisonResult,
    IClientInfoMismatchType,
    getNewClientInfo,
    initialClientInfoComparisonResult,
    setNewClientInfoComparisonResult
} from "../MismatchModal/MismatchModal.model";
import { IParterUserModel } from "../../../Core/ViewModels/User/UserViewModel";
import { IDropdown } from "../../../Core/Common/Dropdown";
import LocationMismatchActionModal from "../LocationMismatchActionModal/LocationMismatchActionModal";
import {
    IDataToUpdateSMSNotificationDetails,
    ISMSNotificationDetails,
    initialSMSNotificationDetails
} from "../../../store/common/SMSNotificationDetailsStore";
import { IClientManagementSetting, ReconcileType } from "../../../store/company/SuiteClientManagementSettingStore";
import { IK1SSNDictionary, IPageWiseK1SSNDictionary } from "../../../store/ProcessReturn/K1SSNStore";
import { getUpdatedShareHolders, verifyIfShareHolderInfoUpdated } from "../../helper/HelperFunctions";
import { IHelptextModel } from "../../../Core/ViewModels/Common/IHelptextModel";

let moment = require("moment");
const isEqual = require("react-fast-compare");

export interface IModalState {
    isBusy: boolean;
    message: string;
}

interface IProcessReturnstate extends IModalState {
    key: any;
    signers: User.ISSRUserModel[];
    viewIndex: number | undefined;
    initialMFJValue: boolean;
    k1Pages: number[];
    isK1BookmarkLoaded: boolean;
    showCarousel: boolean;
    pdfPages: CarouselImage[];
    pdfCarouselActivePage: number;
    renderTrigger: string;
    shareHolderInfo: IShareHolder;
    delegatedEROSigners: IDelegatedEROSigner[];
    isEsign: boolean;
    showGroupFilesPreview: boolean;
    pages: number[];
    matchedForms: TaxDocument.IFormBase[];
    destinationGroup: TaxDocument.DocumentGroups;
    attachments: TaxDocument.IAttachment[];
    verifyK1Replaced: boolean;
    isK1Restored: boolean;
    isK1Replaced: boolean;
    selectedK1Bookmark: number;
    authoritiesOptions: TaxDocument.SelectOptions[];
    shareHolderOptions: TaxDocument.SelectOptions[];
    generateTaxPayer: boolean;
    renderCache: {
        voucher: {
            bookmarks: IBookmarkSection[];
            vouchers: IVoucher[];
        };
    };
    markAsReadyForDelivery: boolean;
    markAsReadyForDeliveryChanged: boolean;
    showClosePopover: boolean;
    disabledTaxpayerPreview: boolean;
    processingTaxReturn: TaxDocument.ITaxReturn | undefined;
    refreshTaxReturn: boolean;
    disableSaveClose: boolean;
    signatureTypeInDocSettings: string;
    isSignatureoptionsToggledInDeliveryOption: boolean;
    signatureFormSelectionType: TaxDocument.SignatureType;
    isSaveAsDefaultOrder: boolean;
    uploadedAdditionalEsignDocumentData: DocumentUploadData[];
    groupInfo: IGroupInfo;
    disableFinish: boolean;
    selectedCustomColumn: IColumnValues;
    isDoNotDisplayDatesEnabled: boolean;
    clonedTaxDocument: TaxDocument.ITaxReturn | undefined;
    logger: ILogger;
    paperFileUploadData: PaperFileUploadData[];
    isAddMultipleVoucher: number;
    showMismatchModal: boolean;
    clientInfoComparisonResult: IClientInfoComparisonResult;
    selectedOption: {
        save: boolean;
        finish: boolean;
    };
    popupInProgress: boolean;
    clientInfo: IClientInfoViewModel;
    prefilledClientInfo: TaxDocument.IPrefilledClientInfo;
    clientBasicInfoId: number;
    showLocationMismatchActionModel: boolean;
    showSMSTextNotifications: boolean;
    isTPOpted: boolean;
    isSpouseOpted: boolean;
    smsNotificationDetails: ISMSNotificationDetails;
    oldClientId: string;
    shareHolderDetails: IShareHolder[];
    tempEfileForms: IEFile[];
    deletedVouchers: IVoucher[];
    isDeliverFirstToUpdatedFromDeliveryTab: boolean;
    isPreparerMessageUpdated: boolean;
    isPaperfileInstructionUpdated: boolean;
}

interface IModalProps {
    show: boolean;
    onCancel: (id: number) => void;
}

const viewMax = ProcessReturnTabs.DeliveryOptions;
const viewMin = ProcessReturnTabs.ClientInfo;

export interface IProcessReturnProps extends IModalProps {
    key: string;
    auth: IAuthState;
    order: number;
    docId: number;
    activeTab: ProcessReturnTabs | undefined;
    taxDocuments: TaxDocumentStore.ITaxDocumentDictionary;
    updateTaxDocument: (taxDocument: TaxDocument.ITaxReturn) => void;
    saveTaxDocument: (taxDocument: TaxDocument.ITaxReturn) => void;
    sendToERO: (taxDocument: TaxDocument.ITaxReturn) => void;
    sendForReview: (taxDocument: TaxDocument.ITaxReturn) => void;
    approveForDelivery: (taxDocument: TaxDocument.ITaxReturn) => void;
    deliverToClient: (taxDocument: TaxDocument.ITaxReturn) => void;
    getK1InstructionFileName: (
        guid: string,
        taxYear: number,
        documentId: number,
        engagementType: TaxDocument.EngagementType
    ) => void;
    deleteK1Instruction: (guid: string, taxYear: number) => void;
    uploadK1Instruction: (
        engagementType: TaxDocument.EngagementType,
        documentGuid: string,
        taxYear: number,
        fileName: string,
        documentId: number,
        callback?: () => void
    ) => void;
    //attachments
    attachments: IAttachmentsDictionary;
    requestAttachments: (
        taxReturn: TaxDocument.ITaxReturn,
        fileName: string,
        file: any,
        processData: (data: AttachmentsTableModel, fileName: string, file: any) => void
    ) => void;
    downloadAttachments: (
        taxReturn: ITaxReturn,
        fileName: string,
        documentId: number,
        attachmentId: number,
        isArchivedReturn: boolean,
        callback: (url: string, fileName: string) => void
    ) => void;
    //Company
    companySettings: ICompanySettings;
    company: CompanyStore.ICompanyData;
    authorities: TaxDocument.ITaxingAuthority[];
    authorityLookup: Company.IAuthorityDictionary;
    userBasicProfile: IUserProfile;
    users: User.ISSRUserModel[];
    requestMailingReturnAddressList: () => void;
    mailingReturnAddressList: MailingReturnAddressStore.IMailingReturnAddressDictionary;
    userSettings: UserSettingStore.UserSettings;
    requestDelegatedSigners: (userId: number) => void;
    userSignatures: UserSignatureStore.IUserSignatures;
    attachmentInstructions: AttachmentInstructionStore.IAttachmentInstructionDictionary;
    //PdfRelated
    pdfDocuments: PdfStore.IPdfDocumentDictionary;
    loadPdf: (taxReturn: TaxDocument.ITaxReturn, reload: boolean) => void;
    returnType: TaxDocument.EngagementType;
    isAssignedToLoggedinUser?: boolean;
    generateTaxPayerView: (taxDocument: TaxDocument.ITaxReturn, clientType?: ClientTypes) => void;
    savedMessages: SavedMessageStore.ISavedMessageDictionary;
    getAllSavedMessages: (reload: boolean) => void;
    downloadK1DocumentLink: (
        documentGuid: string,
        type: string,
        taxYear: number,
        openPreviewCallBack: (url: string) => void
    ) => void;
    saveProcessReturnInfo: (
        taxReturn: TaxDocument.ITaxReturn,
        subDocuments: ISubDocument[],
        isK1Replaced: boolean,
        isK1Restored: boolean,
        isMFJChanged: boolean,
        apiEndPoint?: string,
        callback?: (data?: any) => void,
        taxCaddyLookupResult?: TaxDocument.TaxCaddyLookupResult,
        clientType?: ClientTypes,
        groupInfo?: IGroupInfo,
        resourceId?: string,
        isShareHolderUpdated?: boolean,
        updatedShareHolders?: IShareHolder[]
    ) => void;
    getVoucherUploadLink: (taxReturn: TaxDocument.ITaxReturn, callback: (data: any) => void) => void;
    getMultiVoucherUploadLink: (
        taxReturn: ITaxReturn,
        cbVoucher: IVoucher,
        callback: (data: any, voucher: IVoucher) => void,
        isFinalVoucher?: boolean
    ) => void;
    getTaxCaddyLookupDetails: (
        taxpayerName: string,
        email: string,
        ssn: string,
        taxYear: number,
        taxClientId: string,
        docId: number
    ) => void;
    voucherStore: VoucherStore.IVoucherState;
    requestVoucherDueDates: (taxReturn: TaxDocument.ITaxReturn, authorityId?: number, voucherNo?: TaxDocument.VoucherNo) => void;
    requestPaymentUrls: (taxReturn: TaxDocument.ITaxReturn) => void;
    requestVoucherFormNames: (taxReturn: TaxDocument.ITaxReturn) => void;
    isK1Restored: boolean;
    closePopoverRef: any;
    restoreK1Instruction: (fileName: string, documentId: number) => void;
    getAllTaxingAuthorities: ITaxingAuthority[];
    businessInstructionStore: IBusinessReturnsState;
    updateDocumentInitialStatus: (updateDocumentInitialStatus: TaxDocument.ITaxReturn) => void;
    statusChangeEvent: (taxReturn: ITaxReturn, isDocStatus: boolean, resourceId?: string) => void;
    previewAttachments: (
        taxReturn: TaxDocument.ITaxReturn,
        fileName: string,
        documentId: number,
        attachmentId: number,
        isArchivedReturn: boolean,
        callback: (url: string, fileName: string) => void
    ) => void;
    onReportProblemSave: (id: number, problemDetails: IReportProblemDetails) => void;
    onReportProblemOpen: (id: number) => void;
    onReportProblemCancel: (id: number) => void;
    reportProblemState: IReportPopupState;
    saveTaxingAuthority: (taxingAuthority: ITaxingAuthority, callback?: () => void, callback2?: () => void) => void;
    requestTaxingAuthorities: (value: boolean) => void;
    updateDocumentGroupOrder: (engagementType: TaxDocument.EngagementType, order: DocumentGroups[]) => void;
    requestEroSignaturePath: (userId: number, callback?: (data?: any) => void) => void;
    getAddtionalEsignDocumentUploadLink: (url: string, callback?: (data?: IBlobFile) => void) => void;
    deleteUploadedAdditionalDocumentBlobFile: (
        documentGuid: string,
        fileName: string,
        taxYear: number,
        callback?: () => void
    ) => void;
    deleteAdditionalDocument: (documentId: number, fileName: string, callback?: () => void) => void;
    getAddtionalEsignDocumentDownLoadLink: (url: string, callback?: (data?: IBlobFile) => void) => void;
    convertDocToPdfArtifactAsync: (url: string, callback?: (data?: IBlobFile) => void) => void;
    requestAdditionalDocumentType: (reload: boolean) => void;
    additionalDocumentTypeData: AdditionalDocumentTypeStore.IDocumentTypeState;
    requestCompanyLogo: (reload?: boolean, isLogoUpdate?: boolean) => void;
    requestWatermarkList: () => void;
    watermarkList: IWatermarkModel[];
    customColumnData: ICustomColumn;
    selectedCustomColumn: IColumnValues;
    isLoading: boolean;
    groups: IGroupInfo[];
    updateGroupInfoState: (reload: boolean) => void;
    getPaperFileUploadLink: (url: string, callback?: (data?: IBlobFile) => void) => void;
    getPaperFileDownLoadLink: (url: string, callback?: (data?: IBlobFile) => void, retrycallback?: () => void) => void;
    deleteUploadedPaperFileFromBlob: (documentGuid: string, fileName: string, taxYear: number, callback?: () => void) => void;
    paperFileMessage: PaperFileMessageStore.IPaperFiledMessageDictionary;
    convertToPdfAsync: (url: string) => void;
    getk1AttachmentUploadLink: (url: string, callback?: (data?: IBlobFile) => void) => void;
    refreshAssignmentPage: () => void;
    ssrSettings: ISSRSettings;
    parentResourceIdentifier?: string;
    userroles: string[];
    requestPartnerUser: (reload: boolean, callback?: () => void) => void;
    locationDropdown: IDropdown[];
    loggedInUserLocations: number[];
    setTaxDocumentsAccessMaps: (
        accessMaps: TaxDocument.IDocumentAccessSaveModel,
        callback?: () => void,
        resourceId?: string
    ) => void;
    smsNotificationDetails: ISMSNotificationDetails;
    getSMSNotificationDetails: (
        documentId: number,
        isEditClientInfoRequest: boolean,
        callback: (smsNotificationDetails: ISMSNotificationDetails) => void
    ) => void;
    updateSMSNotificationDetails: (dataToUpdateSMSNotificationDetails: IDataToUpdateSMSNotificationDetails) => void;
    requestClientManagementSetting: () => void;
    clientManagementSetting: IClientManagementSetting;
    onGroupedReturnProcessOpen: (nextProcessingReturnId: number, groupId: number, callback?: () => void) => void;
    groupedTaxDocuments: TaxDocument.ITaxReturn[];
    requestTaxDocument: (
        id: number,
        force: boolean,
        requestTaxPayerView?: boolean,
        clientType?: ClientTypes,
        callback?: (data: any) => void,
        isEditClientInfoRequest?: boolean,
        isGroupedReturn?: boolean
    ) => void;
    saveGroupedProcessReturnInfo: (
        taxReturn: TaxDocument.ITaxReturn,
        subDocuments: ISubDocument[],
        taxDocumentsForDelivery: ISelectedDocumentForDeliveryViewModel[],
        isK1Replaced: boolean,
        isK1Restored: boolean,
        isMFJChanged: boolean,
        apiEndPoint?: string,
        callback?: (data?: any) => void,
        clientType?: ClientTypes,
        groupInfo?: IGroupInfo,
        resourceId?: string,
        isShareHolderUpdated?: boolean,
        updatedShareHolders?: IShareHolder[]
    ) => void;
    groupData: IGroupInfo;
    updateGroupedReturnDocument: (taxDocument: ITaxReturn) => void;
    requestDocumentStatus: (id: number, callback: () => void, isGroupedReturn: boolean) => void;
    onMarkedAsReadyForDelivery: (taxDocument: ITaxReturn) => void;
    markAsReadyForDelivery(groupId: number, callback?: (id: number) => void, resourceId?: string): void;
    profile: IUserProfile;
    k1SSNDetails?: IK1SSNDictionary;
    pageWiseSSNDetails: IPageWiseK1SSNDictionary;
    resetK1SSNState: (documentId: number) => void;
    getK1SSNDetailsForPage: (
        documentId: number,
        ssn: string,
        pageNum: number,
        entityType: EntityType,
        callBack: (ssnPlacements: TaxDocument.IK1SSNDetails[]) => void
    ) => void;
    resetPageWiseK1SSNState: (documentId: number, pages: number[]) => void;
    inactiveusers: User.ISSRUserModel[];
    helpTexts: IHelptextModel[];
    isDeliveryPermissionAssigned: boolean;
    clearSelectedRows: () => void;
}

const enum LoadPropsStage {
    Ready,
    Document,
    Forms,
    Settings,
    Authority,
    Pdf,
    Users
}

const msg = {
    loading: "The Tax-Return details are loading...",
    saving: "Updating the Tax-Return changes..."
};

const localStorage = container.get<ILocalStore>(TYPES.ILocalStore);

export class ProcessReturnModal extends React.Component<IProcessReturnProps, IProcessReturnstate> {
    private closePopoverRef: any;
    private deactivatedAuthority: number[] = [];

    constructor(props: IProcessReturnProps) {
        super(props);
        this.state = {
            key: undefined,
            signers: [],
            //IModalState Properties
            isBusy: false,
            message: "",
            viewIndex: undefined,
            initialMFJValue: false,
            //pdf: {} as IPdfDocumentFacade,
            k1Pages: [],
            selectedK1Bookmark: 0,
            isK1BookmarkLoaded: false,
            delegatedEROSigners: [],
            showCarousel: false,
            pdfPages: [],
            pdfCarouselActivePage: 0,
            renderTrigger: Guid.create().toString(),
            isEsign: false,
            showGroupFilesPreview: false,
            pages: [],
            markAsReadyForDelivery: false,
            markAsReadyForDeliveryChanged: false,
            destinationGroup: DocumentGroups.None,
            matchedForms: [],
            shareHolderInfo: {
                k1StatusId: 0,
                name: "",
                ssn: "",
                id: 0,
                emailAddress: "",
                k1DownloadDate: "",
                consentStatus: ConsentStatus.None,
                k1SendDate: "",
                partnerAddress: "",
                entityType: EntityType.None,
                financialYearEnd: undefined,
                countryCode: "+1",
                mobileNumber: "",
                isK1ReminderEnabled: true
            },
            verifyK1Replaced: false,
            isK1Restored: false,
            isK1Replaced: false,
            authoritiesOptions: [],
            shareHolderOptions: [],
            generateTaxPayer: false,
            attachments: [],
            renderCache: {
                voucher: {
                    bookmarks: [VoucherInfoView.getInitialVoucherBookmark()],
                    vouchers: []
                }
            },
            showClosePopover: false,
            disabledTaxpayerPreview: false,
            processingTaxReturn: undefined,
            refreshTaxReturn: false,
            disableSaveClose: false,
            signatureTypeInDocSettings: "",
            isSignatureoptionsToggledInDeliveryOption: false,
            signatureFormSelectionType: TaxDocument.SignatureType.None,
            isSaveAsDefaultOrder: true,
            uploadedAdditionalEsignDocumentData: [],
            groupInfo: Object.assign({}, initialGroupInfo),
            disableFinish: false,
            selectedCustomColumn: { id: 0, value: "", customColumn: 0, inUseCount: 0 },
            isDoNotDisplayDatesEnabled: true,
            clonedTaxDocument: undefined,
            logger: new LoggerFactory().getTelemetryLogger(),
            paperFileUploadData: [],
            isAddMultipleVoucher: 0,
            showMismatchModal: false,
            clientInfoComparisonResult: initialClientInfoComparisonResult,
            selectedOption: {
                save: false,
                finish: false
            },
            popupInProgress: false,
            clientInfo: TaxDocument.initialClientInfoViewModel,
            prefilledClientInfo: {},
            clientBasicInfoId: 0,
            showLocationMismatchActionModel: false,
            showSMSTextNotifications: true,
            isTPOpted: false,
            isSpouseOpted: false,
            smsNotificationDetails: initialSMSNotificationDetails,
            oldClientId: "",
            shareHolderDetails: [],
            tempEfileForms: [],
            deletedVouchers: [],
            isDeliverFirstToUpdatedFromDeliveryTab: false,
            isPreparerMessageUpdated: false,
            isPaperfileInstructionUpdated: false
        };
        this.undoState = [];
        this.undoTop = Helper.NO_INDEX;
        this.updateTaxDocument = this.updateTaxDocument.bind(this);
        this.unDo = this.unDo.bind(this);
        this.reDo = this.reDo.bind(this);

        this.taxReturnHeading = this.taxReturnHeading.bind(this);
        this.onSaveTaxReturn = this.onSaveTaxReturn.bind(this);
        this.onFinishProcessing = this.onFinishProcessing.bind(this);
        this.deletePages = this.deletePages.bind(this);
        this.deleteRefundOrPayment = this.deleteRefundOrPayment.bind(this);

        this.reGroup = this.reGroup.bind(this);
        this.reGroupPreview = this.reGroupPreview.bind(this);
        this.saveAttachments = this.saveAttachments.bind(this);
        this.isAssignedToLoggedinUser = this.isAssignedToLoggedinUser.bind(this);

        this.sendToERO = this.sendToERO.bind(this);
        this.sendForReview = this.sendForReview.bind(this);
        this.approveForDelivery = this.approveForDelivery.bind(this);
        this.deliverToClient = this.deliverToClient.bind(this);
        this.onPrevious = this.onPrevious.bind(this);
        this.onNext = this.onNext.bind(this);
        this.handleSignatureOptionToggle = this.handleSignatureOptionToggle.bind(this);
        this.onGroupChange = this.onGroupChange.bind(this);
        this.handleCustomColumnChange = this.handleCustomColumnChange.bind(this);
        this.onToggleEfileDateSettings = this.onToggleEfileDateSettings.bind(this);
        this.updateMultipleVouchersAdd = this.updateMultipleVouchersAdd.bind(this);

        this.sendToEROGroupedReturn = this.sendToEROGroupedReturn.bind(this);
        this.sendForReviewGroupedReturn = this.sendForReviewGroupedReturn.bind(this);
        this.approveForDeliveryGroupedReturn = this.approveForDeliveryGroupedReturn.bind(this);
        this.deliverToController = this.deliverToController.bind(this);
        this.changeControllerInfo = this.changeControllerInfo.bind(this);
        this.onPrintForPaperDeliveryGroupedReturn = this.onPrintForPaperDeliveryGroupedReturn.bind(this);
    }

    private onGroupChange(value: any) {
        let group = { ...this.state.groupInfo };
        var groupDetails = this.props.groups.find((x) => x.name === value);
        if (groupDetails) {
            group.id = groupDetails.id;
        } else {
            group.id = 0;
            group.controllerInfo.clientGuid = GRGuid.newGuid();
        }
        group.name = value;
        this.setState({ groupInfo: group });
        //TODO: get props and alter the condition if its grouped return
        if (value !== "") {
            this.setState({ disableFinish: true, showSMSTextNotifications: false });
        } else {
            this.setState({ disableFinish: false, showSMSTextNotifications: true });
        }
    }

    onPrevious(index: number | undefined) {
        let currentViewIndex: number = index == undefined ? parseInt(localStorage.get(this.props.docId.toString())) : index;
        let activeIndex: number = index == undefined ? parseInt(localStorage.get(this.props.docId.toString())) : index;
        let isPaperFileEnabled: boolean = this.props.companySettings.displaySettingsModel.isEnablePaperFiled;

        if (currentViewIndex == ProcessReturnTabs.Attachments || currentViewIndex == ProcessReturnTabs.InVoice) {
            let isK1shareholder: boolean = TaxDocument.isK1ShareHolder(this.props.taxDocuments[this.props.docId]?.taxReturn);
            let isInvoiceEnabled: boolean = TaxDocument.isInvoice(
                this.props.taxDocuments[this.props.docId]?.taxReturn.arrangement,
                this.props.companySettings.displaySettingsModel.isEnableInvoice
            );

            if (currentViewIndex == ProcessReturnTabs.Attachments) {
                if (isInvoiceEnabled) {
                    activeIndex = ProcessReturnTabs.InVoice;
                } else if (isK1shareholder) {
                    activeIndex = ProcessReturnTabs.K1;
                } else {
                    activeIndex = ProcessReturnTabs.Vouchers;
                }
            } else {
                if (isK1shareholder) {
                    activeIndex = ProcessReturnTabs.K1;
                } else {
                    activeIndex = ProcessReturnTabs.Vouchers;
                }
            }
        } else if (currentViewIndex == ProcessReturnTabs.AdditionalEsignDocuments) {
            if (isPaperFileEnabled) activeIndex = ProcessReturnTabs.PaperFile;
            else {
                activeIndex = currentViewIndex - 2;
            }
        } else {
            activeIndex = currentViewIndex - 1;
        }

        this.handleTabSelect(activeIndex);
    }

    onNext(index: number | undefined) {
        let currentViewIndex: number = index == undefined ? parseInt(localStorage.get(this.props.docId.toString())) : index;
        let activeIndex: number = index == undefined ? parseInt(localStorage.get(this.props.docId.toString())) : index;
        let isPaperFileEnabled: boolean = this.props.companySettings.displaySettingsModel.isEnablePaperFiled;

        if (currentViewIndex == ProcessReturnTabs.Vouchers || currentViewIndex == ProcessReturnTabs.K1) {
            let isK1shareholder: boolean = TaxDocument.isK1ShareHolder(this.props.taxDocuments[this.props.docId]?.taxReturn);
            let isInvoiceEnabled: boolean = TaxDocument.isInvoice(
                this.props.taxDocuments[this.props.docId]?.taxReturn.arrangement,
                this.props.companySettings.displaySettingsModel.isEnableInvoice
            );

            if (currentViewIndex == ProcessReturnTabs.Vouchers) {
                // IF Current View is Voucher Tab
                if (isK1shareholder) {
                    activeIndex = ProcessReturnTabs.K1;
                } else if (isInvoiceEnabled) {
                    activeIndex = ProcessReturnTabs.InVoice;
                } else {
                    activeIndex = ProcessReturnTabs.Attachments;
                }
            } else {
                // IF Current View is K1
                if (isInvoiceEnabled) {
                    activeIndex = ProcessReturnTabs.InVoice;
                } else {
                    activeIndex = ProcessReturnTabs.Attachments;
                }
            }
        } else if (currentViewIndex == ProcessReturnTabs.EFile) {
            if (isPaperFileEnabled) activeIndex = ProcessReturnTabs.PaperFile;
            else {
                activeIndex = currentViewIndex + 2;
            }
        } else {
            activeIndex = currentViewIndex + 1;
        }

        this.handleTabSelect(activeIndex);
    }

    deliverFirstToUpdated = (updated: boolean) => {
        this.setState({
            isDeliverFirstToUpdatedFromDeliveryTab: updated
        });
    }

    preparerMessageUpdated = (updated: boolean) => {
        this.setState({
            isPreparerMessageUpdated: updated
        });
    }

    paperfileInstructionUpdated = (updated: boolean) => {
        this.setState({
            isPaperfileInstructionUpdated: updated
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps: IProcessReturnProps) {
        if (nextProps.show) {
            let saving = true;
            if (nextProps.taxDocuments) {
                if (nextProps.taxDocuments[nextProps.docId]?.taxReturn) {
                    saving = false;
                }

                if (nextProps.taxDocuments[this.props.docId]?.isFullyLoaded) {
                    if (nextProps.taxDocuments[this.props.docId]?.taxReturn.documentStatus === TaxDocument.DocumentStatus.READY) {
                        this.updateDocumentStatus(nextProps.taxDocuments[this.props.docId]?.taxReturn);
                    }
                    if (this.state.shareHolderDetails.length == 0) {
                        this.setK1ShareHolderInfo();
                    }
                }
                if (!this.state.isK1Restored) {
                    this.setState({ isK1Restored: nextProps.isK1Restored });
                }

                this.setState({ isBusy: saving });
                this.setTempProcessingDocument(nextProps.taxDocuments[this.props.docId]?.taxReturn);
            }
            this.excerptRetentionPeriod(nextProps.taxDocuments[nextProps.docId]?.taxReturn);
        }
    }

    shouldComponentUpdate(nextProps: IProcessReturnProps, nextState: IProcessReturnstate) {
        return !isEqual(this.state, nextState) || !isEqual(this.props, nextProps);
    }

    private setTempProcessingDocument = (taxReturn: TaxDocument.ITaxReturn) => {
        if (!this.state.processingTaxReturn || this.state.processingTaxReturn.id !== this.props.docId) {
            this.setState({ processingTaxReturn: JSON.parse(JSON.stringify(taxReturn)) });
        }
    };

    checkIfEsignEnabled = (efileBookmarks: IBookmarkSection[]): boolean => {
        if (
            Helper.isIndividualEnagagementType(this.props.taxDocuments[this.props.docId]?.taxReturn.engagementType) &&
            this.props.companySettings.signatureSettingsModel.signatureFormSelectionType ==
            SignatureFormSelectionType.ESignWhenAllIncludedReturnsAllowed
        ) {
            return efileBookmarks[0].pages.some((i) => i.pageTitle == "New York");
        } else {
            return false;
        }
    };

    updateTaxReturnLockStatus = (taxDocument: TaxDocument.ITaxReturn) => {
        taxDocument.lockedBy = this.props.userBasicProfile?.userId == undefined ? 0 : this.props.userBasicProfile?.userId;
        taxDocument.isModified = true;
        this.props.updateDocumentInitialStatus(taxDocument);
    };
    resetTaxReturnLockStatus = (taxDocument: TaxDocument.ITaxReturn) => {
        taxDocument.lockedBy = 0;
        taxDocument.isModified = true;
        this.props.updateDocumentInitialStatus(taxDocument);
    };

    updateDocumentStatus = (taxDocument: TaxDocument.ITaxReturn) => {
        taxDocument.documentStatus = TaxDocument.DocumentStatus.PROCESSING;
        taxDocument.assignTo = this.props.userBasicProfile?.userId == undefined ? 0 : this.props.userBasicProfile?.userId;
        taxDocument.lockedBy = this.props.userBasicProfile?.userId == undefined ? 0 : this.props.userBasicProfile?.userId;
        taxDocument.assignedUser.userId =
            this.props.userBasicProfile?.userId == undefined ? 0 : this.props.userBasicProfile?.userId;
        taxDocument.assignedUser.firstName =
            this.props.userBasicProfile?.userId == undefined ? "" : this.props.userBasicProfile.firstName;
        taxDocument.assignedUser.lastName =
            this.props.userBasicProfile?.userId == undefined ? "" : this.props.userBasicProfile.lastName;
        taxDocument.assignedUser.email =
            this.props.userBasicProfile?.userId == undefined ? "" : this.props.userBasicProfile.email;
        taxDocument.isModified = true;
        this.props.updateDocumentInitialStatus(taxDocument);
        this.props.statusChangeEvent(
            taxDocument,
            true,
            SendTaxReturnResources.GridButtonPrefix + SendTaxReturnResources.GridProcessButton
        );
    };

    componentDidMount() {
        let taxReturn: TaxDocument.ITaxReturn = this.props.taxDocuments[this.props.docId]?.taxReturn;
        if (taxReturn.documentStatus != TaxDocument.DocumentStatus.READY) {
            this.updateTaxReturnLockStatus(taxReturn);
        }
        if (
            taxReturn.documentStatus === TaxDocument.DocumentStatus.REVIEW ||
            taxReturn.documentStatus === TaxDocument.DocumentStatus.APPROVEDFORDELIVERY
        ) {
            this.setState({ isSaveAsDefaultOrder: false });
        }
        this.props.requestVoucherDueDates(taxReturn);
        this.props.requestPaymentUrls(taxReturn);
        this.props.requestVoucherFormNames(taxReturn);
        this.props.requestWatermarkList();

        let defaultActiveTab: number = parseInt(
            localStorage.get(this.props.docId.toString()) == null ? 1 : localStorage.get(this.props.docId.toString())
        );

        this.excerptRetentionPeriod(taxReturn);

        // Need to remove Additional ESign Tab from below after releae
        if (defaultActiveTab == ProcessReturnTabs.InVoice && !this.props.companySettings.displaySettingsModel.isEnableInvoice) {
            defaultActiveTab = ProcessReturnTabs.ClientInfo;
        }
        if (
            defaultActiveTab == ProcessReturnTabs.AdditionalEsignDocuments &&
            this.props.taxDocuments[this.props.docId]?.taxReturn &&
            this.props.taxDocuments[this.props.docId].taxReturn.additionalEsignDocuments.length < 1
        ) {
            defaultActiveTab = ProcessReturnTabs.ClientInfo;
        }

        if (this.props.activeTab != undefined && defaultActiveTab == ProcessReturnTabs.GroupDelivery) {
            defaultActiveTab = this.props.activeTab;
        }

        let signatureType: any = taxReturn.documentSettings?.documentSignatureSetting.signatureFormSelectionType;
        signatureType = typeof signatureType == "number" ? TaxDocument.SignatureType[signatureType] : signatureType;
        let formIndex = taxReturn.formGroups.findIndex((x) => x.group == DocumentGroups.EFile);
        if (formIndex > -1) {
            let efileForms: IEFile[] = taxReturn.formGroups[formIndex].forms as IEFile[];
            this.setState({ tempEfileForms: efileForms });
        }
        this.setState({
            viewIndex: defaultActiveTab,
            initialMFJValue: TaxDocument.isMutual(taxReturn),
            signatureTypeInDocSettings: signatureType,
            signatureFormSelectionType: taxReturn.documentSettings?.documentSignatureSetting.signatureFormSelectionType,
            isDoNotDisplayDatesEnabled: taxReturn.documentSettings?.documentSignatureSetting
                ? taxReturn.documentSettings.documentSignatureSetting.isDoNotDisplayDatesEnabled
                : this.state.isDoNotDisplayDatesEnabled
        });
        this.updateMailingAddress();
        Helper.getClientInfoByClientId(taxReturn.clientId, "", this.setclientInfo);
        this.props.getSMSNotificationDetails(this.props.docId, false, (smsNotificationDetails: ISMSNotificationDetails) => {
            this.setState({
                smsNotificationDetails: smsNotificationDetails,
                isTPOpted: smsNotificationDetails.isTaxPayerSMSOptedIn,
                isSpouseOpted: smsNotificationDetails.isSpouseSMSOptedIn
            });
        });
        this.props.requestClientManagementSetting();
    }

    private updateTPOpted = (tpOpted: boolean) => {
        this.setState({ isTPOpted: tpOpted });
    };

    private updateSpouseOpted = (spouseOpted: boolean) => {
        this.setState({ isSpouseOpted: spouseOpted });
    };

    private setclientInfo = (clientInfo: IClientInfoViewModel) => {
        this.setState({ clientInfo: clientInfo });
        this.props.requestPartnerUser(true, this.preFillClientInfo);
    };

    private setK1ShareHolderInfo = () => {
        let shareHolders = this.props.taxDocuments[this.props.docId]?.taxReturn.formGroups
            .filter((x) => x.group == DocumentGroups.K1)
            .map((i: any) => {
                return i?.shareHolder;
            });
        this.setState({ shareHolderDetails: shareHolders });
    };

    handleSignatureOptionToggle(event: any) {
        this.setState({ isSignatureoptionsToggledInDeliveryOption: event.target.checked });
    }

    handleCustomColumnChange(customColumnVal: IColumnValues) {
        let _taxReturn = this.props.taxDocuments[this.props.docId]?.taxReturn;
        _taxReturn.documentSettings.documentDisplaySetting.customColumn = customColumnVal;
        this.props.updateTaxDocument(_taxReturn);
    }

    onToggleEfileDateSettings = (event: any) => {
        this.setState({ isDoNotDisplayDatesEnabled: event.currentTarget.checked });
    };

    private handleSaveAsDefaultOrderChange = (event: any) => {
        this.setState({ isSaveAsDefaultOrder: event.target.checked });
    };

    private checkDeceased = (isMutual: boolean, taxpayerDeceased: boolean, spouseDeceased: boolean, isUpdated: boolean) => {
        let tempTaxReturn: any = { ...this.props.taxDocuments[this.props.docId]?.taxReturn };
        let tempFormsGroup: TaxDocument.IMarriedJointTaxReturn = {
            ...(this.props.taxDocuments[this.props.docId]?.taxReturn as TaxDocument.IMarriedJointTaxReturn)
        };
        let formIndex = -1;
        formIndex = tempFormsGroup.formGroups.findIndex((x) => x.group == DocumentGroups.EFile);

        if (isMutual && taxpayerDeceased && spouseDeceased) {
            this.props.taxDocuments[
                this.props.docId
            ].taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType =
                TaxDocument.SignatureType.ManualSign;

            if (formIndex > -1) {
                let efileForms: IEFile[] = tempFormsGroup.formGroups[formIndex].forms as IEFile[];
                efileForms.map((efileForm) => {
                    efileForm.signatureControls = efileForm.signatureControls.filter(
                        (x) => x.signatureControlRole != SignatureControlRole.Spouse
                    );
                    efileForm.signatureControls = efileForm.signatureControls.filter(
                        (x) => x.signatureControlRole != SignatureControlRole.Taxpayer
                    );
                });
                tempFormsGroup.formGroups[formIndex].forms = efileForms;
                tempFormsGroup.spouse = tempTaxReturn.spouse;
                if (!this.state.isDeliverFirstToUpdatedFromDeliveryTab) {
                    tempTaxReturn.documentSettings.deliverySettings.deliverTo = TaxDocument.ClientTypes.Taxpayer;
                }
            }
        } else if (isMutual && (!taxpayerDeceased || !spouseDeceased)) {
            if (taxpayerDeceased) {
                this.props.taxDocuments[
                    this.props.docId
                ].taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType =
                    this.state.signatureFormSelectionType;
                if (formIndex > -1) {
                    let efileForms: IEFile[] = tempFormsGroup.formGroups[formIndex].forms as IEFile[];
                    efileForms.map((efileForm) => {
                        efileForm.signatureControls = efileForm.signatureControls.filter(
                            (x) => x.signatureControlRole != SignatureControlRole.Taxpayer
                        );
                    });
                    tempFormsGroup.formGroups[formIndex].forms = efileForms;
                    tempFormsGroup.spouse = tempTaxReturn.spouse;
                    tempTaxReturn.documentSettings.deliverySettings.deliverTo = TaxDocument.ClientTypes.Spouse;
                }
            }
            else if (!taxpayerDeceased && isUpdated) {
                if (formIndex > -1) {
                    let efileForms: IEFile[] = tempFormsGroup.formGroups[formIndex].forms as IEFile[];
                    const isTaxpayerControlExists = efileForms.map((efileForm) => {
                        return efileForm.signatureControls.some(
                            (x) => x.signatureControlRole == SignatureControlRole.Taxpayer
                        );
                    });

                    if (isTaxpayerControlExists.some(x => !x)) {
                        efileForms.forEach((form) => {
                            let correspondingForm = this.state.tempEfileForms.find(
                                (efileForm) => efileForm.pageNo[0] === form.pageNo[0]
                            );

                            if (correspondingForm) {
                                correspondingForm.signatureControls.forEach((control) => {
                                    if ((control.signatureControlRole === SignatureControlRole.Taxpayer &&
                                        control.type === TaxDocument.SignatureControlTypes.Signature &&
                                        form.signatureControls.find(x => x.controlGuid == control.controlGuid) == undefined)
                                        || (control.type === TaxDocument.SignatureControlTypes.Date
                                            && form.signatureControls.find(x => x.controlGuid == control.controlGuid) == undefined
                                            && !this.state.isDoNotDisplayDatesEnabled)) {
                                        form.signatureControls.push(control);
                                    }
                                });
                            }
                        });

                        tempFormsGroup.formGroups[formIndex].forms = efileForms;
                    }
                    tempFormsGroup.taxpayer = tempTaxReturn.taxpayer;
                    if (!this.state.isDeliverFirstToUpdatedFromDeliveryTab) {
                        tempTaxReturn.documentSettings.deliverySettings.deliverTo = TaxDocument.ClientTypes.Taxpayer;
                    }
                }



            }

            if (!spouseDeceased && isUpdated) {
                if (formIndex > -1 && tempFormsGroup.formGroups.length > formIndex) {
                    let efileForms: IEFile[] = [...tempFormsGroup.formGroups[formIndex].forms] as IEFile[];
                    const isSpouseControlExists = efileForms.map((efileForm) => {
                        return efileForm.signatureControls.some(
                            (x) => x.signatureControlRole === SignatureControlRole.Spouse
                        );
                    });

                    if (isSpouseControlExists.some(x => !x)) {
                        efileForms.forEach((form) => {
                            let correspondingForm = this.state.tempEfileForms.find(
                                (efileForm) => efileForm.pageNo[0] === form.pageNo[0]
                            );

                            if (correspondingForm) {
                                correspondingForm.signatureControls.forEach((control) => {
                                    if ((control.signatureControlRole === SignatureControlRole.Spouse &&
                                        control.type === TaxDocument.SignatureControlTypes.Signature &&
                                        form.signatureControls.find(x => x.controlGuid == control.controlGuid) == undefined)
                                        || (control.type === TaxDocument.SignatureControlTypes.Date &&
                                            form.signatureControls.find(x => x.controlGuid == control.controlGuid) == undefined
                                            && !this.state.isDoNotDisplayDatesEnabled)) {
                                        form.signatureControls.push(control);
                                    }
                                });
                            }
                        });

                        tempFormsGroup.formGroups[formIndex].forms = efileForms;
                    }
                }
                tempFormsGroup.spouse = tempTaxReturn.spouse;
                if (!this.state.isDeliverFirstToUpdatedFromDeliveryTab) {
                    tempTaxReturn.documentSettings.deliverySettings.deliverTo = TaxDocument.ClientTypes.Taxpayer;
                }
            }
            else if (spouseDeceased) {
                this.props.taxDocuments[
                    this.props.docId
                ].taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType =
                    this.state.signatureFormSelectionType;
                if (formIndex > -1) {
                    let efileForms: IEFile[] = tempFormsGroup.formGroups[formIndex].forms as IEFile[];
                    efileForms.map((efileForm) => {
                        efileForm.signatureControls = efileForm.signatureControls.filter(
                            (x) => x.signatureControlRole != SignatureControlRole.Spouse
                        );
                    });
                    tempFormsGroup.formGroups[formIndex].forms = efileForms;
                    tempFormsGroup.spouse = tempTaxReturn.spouse;
                    tempTaxReturn.documentSettings.deliverySettings.deliverTo = TaxDocument.ClientTypes.Taxpayer;
                }
            }

        } else if (!isMutual && taxpayerDeceased) {
            this.props.taxDocuments[
                this.props.docId
            ].taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType =
                TaxDocument.SignatureType.ManualSign;

            if (formIndex > -1) {
                let efileForms: IEFile[] = tempFormsGroup.formGroups[formIndex].forms as IEFile[];
                efileForms.map((efileForm) => {
                    efileForm.signatureControls = efileForm.signatureControls.filter(
                        (x) => x.signatureControlRole != SignatureControlRole.Taxpayer
                    );
                });
                tempFormsGroup.formGroups[formIndex].forms = efileForms;
                tempFormsGroup.spouse = tempTaxReturn.spouse;
            }
        } else if (!isMutual && !taxpayerDeceased && isUpdated) {
            this.props.taxDocuments[
                this.props.docId
            ].taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType =
                this.state.signatureFormSelectionType;
            if (formIndex > -1) {
                let efileForms: IEFile[] = tempFormsGroup.formGroups[formIndex].forms as IEFile[];
                const isTaxpayerControlExists = efileForms.map((efileForm) => {
                    return efileForm.signatureControls.some(
                        (x) => x.signatureControlRole == SignatureControlRole.Taxpayer
                    );
                });
                if (isTaxpayerControlExists.some(x => !x)) {
                    efileForms.forEach((form) => {
                        let correspondingForm = this.state.tempEfileForms.find(
                            (efileForm) => efileForm.pageNo[0] === form.pageNo[0]
                        );

                        if (correspondingForm) {
                            correspondingForm.signatureControls.forEach((control) => {
                                if ((control.signatureControlRole === SignatureControlRole.Taxpayer &&
                                    control.type === TaxDocument.SignatureControlTypes.Signature &&
                                    form.signatureControls.find(x => x.controlGuid == control.controlGuid) == undefined)
                                    || (control.type === TaxDocument.SignatureControlTypes.Date
                                        && form.signatureControls.find(x => x.controlGuid == control.controlGuid) == undefined
                                        && !this.state.isDoNotDisplayDatesEnabled)) {
                                    form.signatureControls.push(control);
                                }
                            });
                        }
                    });

                    tempFormsGroup.formGroups[formIndex].forms = efileForms;
                    tempFormsGroup.spouse = tempTaxReturn.spouse;
                    tempTaxReturn.documentSettings.deliverySettings.deliverTo = TaxDocument.ClientTypes.Taxpayer;
                }
            }
        }
        else if (!isMutual && !spouseDeceased && isUpdated) {
            this.props.taxDocuments[
                this.props.docId
            ].taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType =
                this.state.signatureFormSelectionType;
            if (formIndex > -1) {
                let efileForms: IEFile[] = tempFormsGroup.formGroups[formIndex].forms as IEFile[];
                const isSpouseControlExists = efileForms.map((efileForm) => {
                    return efileForm.signatureControls.some(
                        (x) => x.signatureControlRole == SignatureControlRole.Spouse
                    );
                });
                if (isSpouseControlExists.some(x => !x)) {
                    efileForms.forEach((form) => {
                        let correspondingForm = this.state.tempEfileForms.find(
                            (efileForm) => efileForm.pageNo[0] === form.pageNo[0]
                        );

                        if (correspondingForm) {
                            correspondingForm.signatureControls.forEach((control) => {
                                if ((control.signatureControlRole === SignatureControlRole.Spouse &&
                                    control.type === TaxDocument.SignatureControlTypes.Signature &&
                                    form.signatureControls.find(x => x.controlGuid == control.controlGuid) == undefined)
                                    || (control.type === TaxDocument.SignatureControlTypes.Date &&
                                        form.signatureControls.find(x => x.controlGuid == control.controlGuid) == undefined
                                        && !this.state.isDoNotDisplayDatesEnabled)) {
                                    form.signatureControls.push(control);
                                }
                            });
                        }
                    });

                    tempFormsGroup.formGroups[formIndex].forms = efileForms;
                    tempFormsGroup.spouse = tempTaxReturn.spouse;
                    tempTaxReturn.documentSettings.deliverySettings.deliverTo = TaxDocument.ClientTypes.Taxpayer;
                }
            }
        }
    };

    private excerptRetentionPeriod = (taxReturn: TaxDocument.ITaxReturn) => {
        if (TaxDocument.isIndividual(taxReturn) || TaxDocument.isMutual(taxReturn)) {
            this.setLocalState(taxReturn, this.props.companySettings, taxReturn.taxpayer.additionalInfo.isAttested);
        } else if (TaxDocument.isPartnership(taxReturn)) {
            this.setLocalState(taxReturn, this.props.companySettings, taxReturn.partnership.additionalInfo.isAttested);
        }
    };

    private setLocalState(taxReturn: TaxDocument.ITaxReturn, compSettings: ICompanySettings, isAttested: boolean) {
        if (taxReturn.documentSettings.documentRetentionSetting.retentionPeriod <= 0) {
            let retentionPeriod: number = 0;
            retentionPeriod = isAttested
                ? compSettings.retentionSettingsModel.attestRetentionPeriod
                : compSettings.retentionSettingsModel.retentionPeriod;
            taxReturn.documentSettings.documentRetentionSetting.retentionPeriod = retentionPeriod;
        }
    }

    public updateMultipleVouchersAdd(count: number) {
        this.setState({ isAddMultipleVoucher: count });
    }

    public updateMailingAddress = () => {
        let selectedMailingAddress = 0;

        if (
            !this.props.taxDocuments[this.props.docId]?.taxReturn.groupId ||
            this.props.taxDocuments[this.props.docId]?.taxReturn.groupId === 0
        ) {
            let primaryOfficeLocationId = this.props.mailingReturnAddressList.filter((x) => x.isPrimaryOfficeLocation)[0].id;

            selectedMailingAddress =
                this.props.taxDocuments[this.props.docId]?.taxReturn.locationId === undefined ||
                    this.props.taxDocuments[this.props.docId]?.taxReturn.locationId === null
                    ? primaryOfficeLocationId
                    : this.props.taxDocuments[this.props.docId]?.taxReturn.locationId;
        } else {
            let defaultMailingAddress = this.props.userSettings.settings.defaultSettings.manualAddressId;
            let documentManualAddressId =
                this.props.taxDocuments[this.props.docId]?.taxReturn.documentSettings.deliverySettings.manualAddressId;

            selectedMailingAddress =
                documentManualAddressId === null || documentManualAddressId === 0
                    ? defaultMailingAddress
                    : documentManualAddressId;
        }

        this.props.taxDocuments[this.props.docId].taxReturn.documentSettings.deliverySettings.manualAddressId =
            selectedMailingAddress;
    };

    preFillClientInfo = () => {
        let taxreturn: ITaxReturn = this.props.taxDocuments[this.props.docId].taxReturn;
        let prefilledClientInfoDetails = {};
        if (this.state.clientInfo) {
            let clientInfo: TaxDocument.IClientInfoViewModel = this.state.clientInfo;
            if (TaxDocument.isMutual(taxreturn) || TaxDocument.isIndividual(taxreturn)) {
                this.prefillTaxpayerInfo(taxreturn.taxpayer, clientInfo, prefilledClientInfoDetails);
            } else {
                if (TaxDocument.isPartnership(taxreturn)) {
                    this.prefillPartnerInfo(taxreturn.partnership, clientInfo, prefilledClientInfoDetails);
                }
            }

            if (TaxDocument.isMutual(taxreturn)) {
                this.prefillSpouseInfo(taxreturn.spouse, clientInfo, taxreturn, prefilledClientInfoDetails);
            }

            this.prefillEROInfo(taxreturn, clientInfo, prefilledClientInfoDetails);

            if (taxreturn.locationId === clientInfo.locationId) {
                this.setPrefillInfo(TaxDocument.ClientReturn.OfficeLocation);
                prefilledClientInfoDetails[TaxDocument.ClientReturn.OfficeLocation] = taxreturn.locationId;
            }
            prefilledClientInfoDetails["ProductType"] = "Returns";

            if (Object.keys(prefilledClientInfoDetails).length > 1) {
                logger.trackTrace(`Prefilled from CM for ClientId: ${taxreturn.clientId.toString()}`, prefilledClientInfoDetails);
            }
            this.props.updateTaxDocument(taxreturn);
        }
    };

    setPrefillInfo = (item: ClientInfoProperty | TaxDocument.ClientReturn) => {
        this.setState((state) => ({ prefilledClientInfo: { ...state.prefilledClientInfo, [item]: true } }));
    };

    resetPrefillInfo = (item: ClientInfoProperty | TaxDocument.ClientReturn) => {
        this.setState((state) => ({ prefilledClientInfo: { ...state.prefilledClientInfo, [item]: false } }));
    };

    prefillEROInfo = (taxreturn: ITaxReturn, clientInfo: TaxDocument.IClientInfoViewModel, prefilledClientInfoDetails) => {
        var allSignerList: IParterUserModel[] = UserHelper.getPartnerUsers();

        if (!clientInfo.ero) {
            clientInfo.ero = this.props.userSettings.settings.defaultSettings.eroUser.toString();
        } else if (taxreturn.partnerId === null) {
            this.setPrefillInfo(TaxDocument.ClientReturn.Signer);
        }

        if (clientInfo.ero) {
            if (!allSignerList.some((p) => p.id == Number(taxreturn.partnerId))) {
                var signerDetailsFromCM = allSignerList.find((p) => p.id == Number(clientInfo.ero));
                if (signerDetailsFromCM != null && signerDetailsFromCM != undefined) {
                    taxreturn.isModified = true;
                    taxreturn.partnerId = signerDetailsFromCM.id;
                    taxreturn.partner.userId = signerDetailsFromCM.id;
                    taxreturn.partner.firstName = signerDetailsFromCM.firstName;
                    taxreturn.partner.lastName = signerDetailsFromCM.lastName;
                    taxreturn.partner.email = signerDetailsFromCM.emailAddress;
                    taxreturn.partner.inactive = signerDetailsFromCM.inactive;
                    if (Number(signerDetailsFromCM.id) > 0) {
                        this.props.requestEroSignaturePath(signerDetailsFromCM.id, (data) => {
                            let imageUri: string = data;
                            if (imageUri && imageUri.trim() != "" && signerDetailsFromCM != undefined) {
                                taxreturn.documentSettings.documentSignatureSetting.signatureStampUser.userId =
                                    signerDetailsFromCM.id;
                            } else {
                                taxreturn.documentSettings.documentSignatureSetting.signatureStampUser.userId = 0; // default to company if no signature uploaded for an ero
                                imageUri = this.props.company.signatureUploadLink;
                            }
                            let formIndex = taxreturn.formGroups.findIndex((x) => x.group == DocumentGroups.EFile);
                            if (formIndex > -1) {
                                let efileForms: IEFile[] = taxreturn.formGroups[formIndex].forms as IEFile[];
                                efileForms.map((efileForm) => {
                                    efileForm.signatureControls.map((x) => {
                                        if (x.signatureControlRole == SignatureControlRole.ERO) x.selectedEroImage = imageUri;
                                    });
                                });
                                taxreturn.formGroups[formIndex].forms = efileForms;
                            }
                            this.props.updateTaxDocument(taxreturn);
                        });
                    }
                    this.props.updateTaxDocument(taxreturn);
                    prefilledClientInfoDetails[TaxDocument.ClientReturn.Signer] = taxreturn.partnerId;
                }
            }
        }
    };
    prefillTaxpayerInfo = (taxpayer: ITaxpayer, clientInfo: TaxDocument.IClientInfoViewModel, prefilledClientInfoDetails) => {
        if (!taxpayer.name && clientInfo.name) {
            taxpayer.name = clientInfo.name;
            this.setPrefillInfo(ClientInfoProperty.taxpayerName);
            prefilledClientInfoDetails[ClientInfoProperty[ClientInfoProperty.taxpayerName]] = taxpayer.name;
        }
        if (!taxpayer.email && clientInfo.emailAddress) {
            taxpayer.email = clientInfo.emailAddress;
            this.setPrefillInfo(ClientInfoProperty.taxpayerEmail);
            prefilledClientInfoDetails[ClientInfoProperty[ClientInfoProperty.taxpayerEmail]] = taxpayer.email;
        }
        if (!taxpayer.ssn && clientInfo.uid) {
            taxpayer.ssn = Helper.getUnFormatedUID(clientInfo.uid);
            this.setPrefillInfo(ClientInfoProperty.taxpayerSSN);
            prefilledClientInfoDetails[ClientInfoProperty[ClientInfoProperty.taxpayerSSN]] = taxpayer.ssn;
        }
        if (!taxpayer.dob && clientInfo.dob) {
            taxpayer.dob = clientInfo.dob;
            this.setPrefillInfo(ClientInfoProperty.taxpayerDateOfBirth);
            prefilledClientInfoDetails[ClientInfoProperty[ClientInfoProperty.taxpayerDateOfBirth]] = taxpayer.dob;
        }
        if (!taxpayer.mobileNumber && clientInfo.mobileNumber) {
            taxpayer.mobileNumber = clientInfo.mobileNumber;
            this.setPrefillInfo(ClientInfoProperty.taxpayerMobileNumber);
            prefilledClientInfoDetails[ClientInfoProperty[ClientInfoProperty.taxpayerMobileNumber]] = taxpayer.mobileNumber;
        }
        if (!taxpayer.countryCode && clientInfo.countryCode) {
            taxpayer.countryCode = clientInfo.countryCode;
            this.setPrefillInfo(ClientInfoProperty.taxpayerCountryCode);
            prefilledClientInfoDetails[ClientInfoProperty[ClientInfoProperty.taxpayerCountryCode]] = taxpayer.countryCode;
        }

        if (this.state.prefilledClientInfo && Object.keys(this.state.prefilledClientInfo).length > 0) {
            taxpayer.isModified = true;
        }
    };

    prefillSpouseInfo = (
        spouse: ITaxpayer,
        clientInfo: TaxDocument.IClientInfoViewModel,
        taxreturn: TaxDocument.IMarriedJointTaxReturn,
        prefilledClientInfoDetails
    ) => {
        if (!spouse.name && clientInfo.spouseName) {
            spouse.name = clientInfo.spouseName;
            this.setPrefillInfo(ClientInfoProperty.spouseName);
            prefilledClientInfoDetails[ClientInfoProperty[ClientInfoProperty.spouseName]] = spouse.name;
        }
        if (!spouse.email && clientInfo.spouseEmailAddress) {
            taxreturn.spouse.email = clientInfo.spouseEmailAddress;
            this.setPrefillInfo(ClientInfoProperty.spouseEmail);
            prefilledClientInfoDetails[ClientInfoProperty[ClientInfoProperty.spouseEmail]] = taxreturn.spouse.email;
        }
        if (!spouse.ssn && clientInfo.spouseSsn) {
            spouse.ssn = Helper.getUnFormatedUID(clientInfo.spouseSsn);
            this.setPrefillInfo(ClientInfoProperty.spouseSSN);
            prefilledClientInfoDetails[ClientInfoProperty[ClientInfoProperty.spouseSSN]] = spouse.ssn;
        }
        if (!spouse.dob && clientInfo.spouseDob) {
            spouse.dob = clientInfo.spouseDob;
            this.setPrefillInfo(ClientInfoProperty.spouseDateOfBirth);
            prefilledClientInfoDetails[ClientInfoProperty[ClientInfoProperty.spouseDateOfBirth]] = spouse.dob;
        }
        if (!spouse.mobileNumber && clientInfo.spouseMobileNumber) {
            spouse.mobileNumber = clientInfo.spouseMobileNumber;
            this.setPrefillInfo(ClientInfoProperty.spouseMobileNumber);
            prefilledClientInfoDetails[ClientInfoProperty[ClientInfoProperty.spouseMobileNumber]] = spouse.mobileNumber;
        }
        if (!spouse.countryCode && clientInfo.spouseCountryCode) {
            spouse.countryCode = clientInfo.spouseCountryCode;
            this.setPrefillInfo(ClientInfoProperty.spouseCountryCode);
            prefilledClientInfoDetails[ClientInfoProperty[ClientInfoProperty.spouseCountryCode]] = spouse.countryCode;
        }

        if (this.state.prefilledClientInfo && Object.keys(this.state.prefilledClientInfo).length > 0) {
            spouse.isModified = true;
        }
    };

    prefillPartnerInfo = (partnership: ITaxClient, clientInfo: TaxDocument.IClientInfoViewModel, prefilledClientInfoDetails) => {
        if (!partnership.name && clientInfo.name) {
            partnership.name = clientInfo.name;
            this.setPrefillInfo(ClientInfoProperty.partnerName);
            prefilledClientInfoDetails[ClientInfoProperty[ClientInfoProperty.partnerName]] = partnership.name;
        }
        if (!partnership.email && clientInfo.emailAddress) {
            partnership.email = clientInfo.emailAddress;
            this.setPrefillInfo(ClientInfoProperty.partnerEmail);
            prefilledClientInfoDetails[ClientInfoProperty[ClientInfoProperty.partnerEmail]] = partnership.email;
        }
        if (!partnership.ssn && clientInfo.uid) {
            partnership.ssn = Helper.getUnFormatedUID(clientInfo.uid);
            this.setPrefillInfo(ClientInfoProperty.partnerEin);
            prefilledClientInfoDetails[ClientInfoProperty[ClientInfoProperty.partnerEin]] = partnership.ssn;
        }
        if (!partnership.mobileNumber && clientInfo.mobileNumber) {
            partnership.mobileNumber = clientInfo.mobileNumber;
            this.setPrefillInfo(ClientInfoProperty.partnerMobileNumber);
            prefilledClientInfoDetails[ClientInfoProperty[ClientInfoProperty.partnerMobileNumber]] = partnership.mobileNumber;
        }
        if (!partnership.countryCode && clientInfo.countryCode) {
            partnership.countryCode = clientInfo.countryCode;
            this.setPrefillInfo(ClientInfoProperty.partnerCountryCode);
            prefilledClientInfoDetails[ClientInfoProperty[ClientInfoProperty.partnerCountryCode]] = partnership.countryCode;
        }
        if (this.state.prefilledClientInfo && Object.keys(this.state.prefilledClientInfo).length > 0) {
            partnership.isModified = true;
        }
    };

    //===========================Grouped Return Delivery Start Here===========================

    deliverToController() {
        const { docId, taxDocuments } = this.props;

        let model = taxDocuments[docId]?.taxReturn;

        if (!model.partner || model.partner?.userId <= 0) {
            VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectPartner, null);
            return;
        }

        if (!this.props.companySettings.userPrivilegeSettingsModel.allowAnyUsertoSendDelivary) {
            if (!this.props.isDeliveryPermissionAssigned) {
                VenusNotifier.Warning("You don't have enough permission to send return for delivery.", null);
                return;
            }
        }

        if (this.deactivatedAuthority.length !== 0) {
            VenusNotifier.Error(Constants.FinishProcessReturn.StatusMessage.SelectDeactivatedDeletedAuthority, "Error");
            return;
        }

        if (this.props.companySettings.displaySettingsModel.isEnableInvoice && !this.validateInvoice(model)) {
            VenusNotifier.Error(Constants.FinishProcessReturn.StatusMessage.DeliverToClientWithoutInvoiceError, null);
            return;
        }

        if (!Helper.validateMailingAddress(model)) {
            VenusNotifier.Warning(Constants.FinishProcessReturn.StatusMessage.MailingAddressMissingError, null);
            return;
        }

        if (!this.props.companySettings.displaySettingsModel.isWatermarkEnabled) {
            model.documentSettings.deliverySettings.watermarkId = 0;
        }

        if (!this.state.isBusy && this.validateAttachmentStatus(model.attachments)) {
            ShowLoader();
            this.props.requestDocumentStatus(
                this.props.docId,
                () => {
                    HideLoader();
                    let taxReturn: TaxDocument.ITaxReturn = Object.assign(
                        {},
                        this.props.taxDocuments[this.props.docId]?.taxReturn
                    );
                    if (!Helper.isProcessable(taxReturn)) {
                        VenusNotifier.Error(Constants.ProcessReturnConstants.ReturnAlreadyProcessed, "");
                        return;
                    }
                    model.lockedBy = 0;
                    this.setState({ isBusy: true, generateTaxPayer: false }, () => {
                        document.body.click();
                        model.documentSettings.deliverySettings.isDirectDeliveryToTaxCaddy = false;
                        model.documentSettings.deliverySettings.deliveryMode = DeliveryMode.ElectronicFiled;
                        logger.trackTrace(
                            `ProcessReturnModal --> deliverToController executes for DocumentGuid: ${taxReturn.documentGuid}`,
                            {
                                DocumentId: taxReturn.id?.toString(),
                                DocumentGuid: taxReturn.documentGuid,
                                GroupId: this.props.groupData.id?.toString()
                            }
                        );
                        this.props.resetK1SSNState(model.id);
                        model.locationId = undefined;
                        this.props.saveGroupedProcessReturnInfo(
                            model,
                            this.getSubDocuments(),
                            this.getSelectedReturnsForDelivery(),
                            this.state.isK1Replaced,
                            this.state.isK1Restored,
                            this.state.initialMFJValue !== TaxDocument.isMutual(model),
                            processReturnActionEndPoints.deliverAsync,
                            this.callback,
                            undefined,
                            this.props.groupData,
                            this.props.parentResourceIdentifier + SendTaxReturnResources.DeliverToClientMenu
                        );
                    });
                },
                true
            );
        }
    }

    changeControllerInfo = (item: ControllerInfoProperty, value: any) => {
        switch (item) {
            case ControllerInfoProperty.name:
                this.props.groupData.controllerInfo.name = value;
                break;
            case ControllerInfoProperty.emailAddress:
                this.props.groupData.controllerInfo.emailAddress = value;
                break;
            case ControllerInfoProperty.ssn:
                this.props.groupData.controllerInfo.ssn = value;
                break;
            case ControllerInfoProperty.countryCode:
                this.props.groupData.controllerInfo.countryCode = value;
                break;
            case ControllerInfoProperty.mobileNumber:
                this.props.groupData.controllerInfo.mobileNumber = value;
                break;
        }
    };

    sendForReviewGroupedReturn(selectedUser: number) {
        if (selectedUser === 0) {
            VenusNotifier.Warning(Constants.FinishProcessReturn.ValidationMessage.SelectReviewer, "Error");
        } else {
            let taxReturn: TaxDocument.ITaxReturn = Object.assign({}, this.props.taxDocuments[this.props.docId]?.taxReturn);
            let userIndex: number = this.props.users.findIndex((user) => user.id === selectedUser);
            if (userIndex == -1) {
                VenusNotifier.Warning(Constants.FinishProcessReturn.ValidationMessage.SelectReviewer, null);
                return;
            }
            taxReturn.documentStatus = TaxDocument.DocumentStatus.REVIEW;
            taxReturn.assignTo = selectedUser;
            taxReturn.assignedUser.userId = this.props.users[userIndex].id;
            taxReturn.assignedUser.firstName = this.props.users[userIndex].firstName;
            taxReturn.assignedUser.lastName = this.props.users[userIndex].lastName;
            taxReturn.assignedUser.email = this.props.users[userIndex].emailAddress;
            taxReturn.isModified = true;
            taxReturn.lockedBy = 0;
            taxReturn.locationId = undefined;
            if (!this.props.companySettings.displaySettingsModel.isWatermarkEnabled) {
                taxReturn.documentSettings.deliverySettings.watermarkId = 0;
            }
            if (!this.state.isBusy && this.validateAttachmentStatus(taxReturn.attachments)) {
                this.setState({ isBusy: true, generateTaxPayer: false }, () => {
                    document.body.click();
                    if (this.state.isSaveAsDefaultOrder === true) {
                        this.props.updateDocumentGroupOrder(
                            taxReturn.engagementType,
                            taxReturn.documentSettings.deliverySettings.paperReturnSettings.documentOrder
                        );
                    }
                    logger.trackTrace(
                        `ProcessReturnModal --> sendForReview executes for DocumentGuid: ${taxReturn.documentGuid}`,
                        {
                            DocumentId: taxReturn.id?.toString(),
                            DocumentGuid: taxReturn.documentGuid,
                            GroupId: this.props.groupData.id?.toString()
                        }
                    );
                    const updatedShareHolders: IShareHolder[] = this.checkForUpdatedShareHolderDetails(taxReturn);
                    this.props.saveGroupedProcessReturnInfo(
                        taxReturn,
                        this.getSubDocuments(),
                        this.getSelectedReturnsForDelivery(),
                        this.state.isK1Replaced,
                        this.state.isK1Restored,
                        this.state.initialMFJValue !== TaxDocument.isMutual(taxReturn),
                        processReturnActionEndPoints.sendForReviewAsync,
                        this.callback,
                        undefined,
                        this.props.groupData,
                        this.props.parentResourceIdentifier + SendTaxReturnResources.SendForReviewMenu,
                        updatedShareHolders.length > 0,
                        updatedShareHolders
                    );
                });
            }
        }
    }

    approveForDeliveryGroupedReturn(selectedUser: number) {
        if (selectedUser === 0) {
            VenusNotifier.Warning(Constants.FinishProcessReturn.StatusMessage.SelectApprover, "Error");
        } else {
            let taxReturn: TaxDocument.ITaxReturn = Object.assign({}, this.props.taxDocuments[this.props.docId]?.taxReturn);
            let userIndex: number = this.props.users.findIndex((user) => user.id === selectedUser);
            taxReturn.documentStatus = TaxDocument.DocumentStatus.APPROVEDFORDELIVERY;
            taxReturn.assignTo = selectedUser;
            taxReturn.assignedUser.userId = this.props.users[userIndex].id;
            taxReturn.assignedUser.firstName = this.props.users[userIndex].firstName;
            taxReturn.assignedUser.lastName = this.props.users[userIndex].lastName;
            taxReturn.assignedUser.email = this.props.users[userIndex].emailAddress;
            taxReturn.lockedBy = 0;
            taxReturn.locationId = undefined;
            if (!this.props.companySettings.displaySettingsModel.isWatermarkEnabled) {
                taxReturn.documentSettings.deliverySettings.watermarkId = 0;
            }
            if (!this.state.isBusy && this.validateAttachmentStatus(taxReturn.attachments)) {
                this.setState({ isBusy: true, generateTaxPayer: false }, () => {
                    document.body.click();
                    if (this.state.isSaveAsDefaultOrder === true) {
                        this.props.updateDocumentGroupOrder(
                            taxReturn.engagementType,
                            taxReturn.documentSettings.deliverySettings.paperReturnSettings.documentOrder
                        );
                    }
                    logger.trackTrace(
                        `ProcessReturnModal --> approveForDelivery executes for DocumentGuid: ${taxReturn.documentGuid}`,
                        {
                            DocumentId: taxReturn.id?.toString(),
                            DocumentGuid: taxReturn.documentGuid,
                            GroupId: this.props.groupData.id?.toString()
                        }
                    );
                    const updatedShareHolders: IShareHolder[] = this.checkForUpdatedShareHolderDetails(taxReturn);
                    if (taxReturn.formGroups && taxReturn.formGroups.length > 0) {
                        this.props.saveGroupedProcessReturnInfo(
                            taxReturn,
                            this.getSubDocuments(),
                            this.getSelectedReturnsForDelivery(),
                            this.state.isK1Replaced,
                            this.state.isK1Restored,
                            this.state.initialMFJValue !== TaxDocument.isMutual(taxReturn),
                            processReturnActionEndPoints.approveForDeliveryAsync,
                            this.callback,
                            undefined,
                            this.props.groupData,
                            this.props.parentResourceIdentifier + SendTaxReturnResources.ApproveForDeliveryMenu,
                            updatedShareHolders.length > 0,
                            updatedShareHolders
                        );
                    } else VenusNotifier.Error(Constants.FinishProcessReturn.StatusMessage.DataIssueMessage, "Error");
                });
            }
        }
    }

    sendToEROGroupedReturn() {
        let taxReturn: TaxDocument.ITaxReturn = Object.assign({}, this.props.taxDocuments[this.props.docId]?.taxReturn);
        let userIndex: number = this.props.users.findIndex((user) => user.id === taxReturn.partnerId);
        taxReturn.documentStatus = TaxDocument.DocumentStatus.REVIEW;
        taxReturn.assignTo = taxReturn.partnerId;
        taxReturn.assignedUser.userId = this.props.users[userIndex].id;
        taxReturn.assignedUser.firstName = this.props.users[userIndex].firstName;
        taxReturn.assignedUser.lastName = this.props.users[userIndex].lastName;
        taxReturn.assignedUser.email = this.props.users[userIndex].emailAddress;
        taxReturn.isModified = true;
        taxReturn.lockedBy = 0;
        taxReturn.locationId = undefined;
        if (!this.props.companySettings.displaySettingsModel.isWatermarkEnabled) {
            taxReturn.documentSettings.deliverySettings.watermarkId = 0;
        }
        if (!this.state.isBusy && this.validateAttachmentStatus(taxReturn.attachments)) {
            this.setState({ isBusy: true, generateTaxPayer: false }, () => {
                document.body.click();
                if (this.state.isSaveAsDefaultOrder === true) {
                    this.props.updateDocumentGroupOrder(
                        taxReturn.engagementType,
                        taxReturn.documentSettings.deliverySettings.paperReturnSettings.documentOrder
                    );
                }
                logger.trackTrace(`ProcessReturnModal --> sendToERO executes for DocumentGuid: ${taxReturn.documentGuid}`, {
                    DocumentId: taxReturn.id?.toString(),
                    DocumentGuid: taxReturn.documentGuid,
                    GroupId: this.props.groupData.id?.toString()
                });
                const updatedShareHolders: IShareHolder[] = this.checkForUpdatedShareHolderDetails(taxReturn);
                this.props.saveGroupedProcessReturnInfo(
                    taxReturn,
                    this.getSubDocuments(),
                    this.getSelectedReturnsForDelivery(),
                    this.state.isK1Replaced,
                    this.state.isK1Restored,
                    this.state.initialMFJValue !== TaxDocument.isMutual(taxReturn),
                    processReturnActionEndPoints.sendToEROAsync,
                    this.callback,
                    undefined,
                    this.props.groupData,
                    this.props.parentResourceIdentifier + SendTaxReturnResources.SendToEROSignerMenu,
                    updatedShareHolders.length > 0,
                    updatedShareHolders
                );
            });
        }
    }

    onPrintForPaperDeliveryGroupedReturn = () => {
        let model = this.props.taxDocuments[this.props.docId]?.taxReturn;

        if (this.deactivatedAuthority.length !== 0) {
            VenusNotifier.Error(Constants.FinishProcessReturn.StatusMessage.SelectDeactivatedDeletedAuthority, "Error");
            return;
        }

        if (this.props.companySettings.displaySettingsModel.isEnableInvoice && !this.validateInvoice(model)) {
            VenusNotifier.Error(Constants.FinishProcessReturn.StatusMessage.DeliverToClientWithoutInvoiceError, null);
            return;
        }
        if (!this.props.companySettings.displaySettingsModel.isWatermarkEnabled) {
            model.documentSettings.deliverySettings.watermarkId = 0;
        }
        if (!this.state.isBusy && this.validateAttachmentStatus(model.attachments)) {
            model.lockedBy = 0;
            this.setState({ isBusy: true, generateTaxPayer: false }, () => {
                document.body.click();
                if (this.state.isSaveAsDefaultOrder === true) {
                    this.props.updateDocumentGroupOrder(
                        model.engagementType,
                        model.documentSettings.deliverySettings.paperReturnSettings.documentOrder
                    );
                }
                model.documentSettings.deliverySettings.isDirectDeliveryToTaxCaddy = false;
                model.documentSettings.deliverySettings.deliveryMode = DeliveryMode.PaperFiled;
                let taxDocumentsForDelivery: ISelectedDocumentForDeliveryViewModel[] = this.getSelectedReturnsForDelivery();
                for (let i = 0; i < taxDocumentsForDelivery.length; i++) {
                    if (taxDocumentsForDelivery[i].documentSettings != null) {
                        if (taxDocumentsForDelivery[i].documentSettings.deliverySettings != null) {
                            taxDocumentsForDelivery[i].documentSettings.deliverySettings.deliveryMode = DeliveryMode.PaperFiled;
                        } else {
                            taxDocumentsForDelivery[i].documentSettings.deliverySettings = {} as IDeliverySettings;
                            taxDocumentsForDelivery[i].documentSettings.deliverySettings.deliveryMode = DeliveryMode.PaperFiled;
                        }
                    } else {
                        taxDocumentsForDelivery[i].documentSettings = {} as IDocumentSettings;
                        taxDocumentsForDelivery[i].documentSettings.deliverySettings = {} as IDeliverySettings;
                        taxDocumentsForDelivery[i].documentSettings.deliverySettings.deliveryMode = DeliveryMode.PaperFiled;
                    }
                }
                logger.trackTrace(
                    `ProcessReturnModal --> onPrintForPaperDelivery executes for DocumentGuid: ${model.documentGuid}`,
                    {
                        DocumentId: model.id?.toString(),
                        DocumentGuid: model.documentGuid,
                        GroupId: this.props.groupData.id?.toString()
                    }
                );
                model.locationId = undefined;
                this.props.saveGroupedProcessReturnInfo(
                    model,
                    this.getSubDocuments(),
                    taxDocumentsForDelivery,
                    this.state.isK1Replaced,
                    this.state.isK1Restored,
                    this.state.initialMFJValue !== TaxDocument.isMutual(model),
                    processReturnActionEndPoints.deliverAsync,
                    this.callback,
                    undefined,
                    this.props.groupData,
                    this.props.parentResourceIdentifier + SendTaxReturnResources.DownloadPDFMenu
                );
            });
        }
    };

    //===========================Grouped Return Delivery Ends Here===========================

    getFinishPopover = (taxDocument: ITaxReturn) => {
        let finishPopover = <></>;

        if (taxDocument.groupId > 0) {
            finishPopover = (
                <Popover
                    id="finish-popover"
                    className="finishPopover"
                    style={{ width: "750px", height: "530px", top: "219px" }}
                    title="Finish Return Processing"
                >
                    <Popover.Title as="h3">Finish Return Processing</Popover.Title>
                    <Popover.Content>
                        <GroupFinishPopover
                            groupedTaxDocuments={this.props.groupedTaxDocuments}
                            taxreturn={taxDocument}
                            users={this.props.users}
                            userProfile={this.props.userBasicProfile}
                            companySettings={this.props.companySettings}
                            sendToERO={this.sendToEROGroupedReturn}
                            sendForReview={this.sendForReviewGroupedReturn}
                            approveForDelivery={this.approveForDeliveryGroupedReturn}
                            deliverToController={this.deliverToController}
                            updateTaxDocument={this.updateTaxDocument}
                            onPrintForPaperDelivery={this.onPrintForPaperDeliveryGroupedReturn}
                            updateDocumentGroupOrder={this.props.updateDocumentGroupOrder}
                            handleSaveAsDefaultOrderChange={this.handleSaveAsDefaultOrderChange}
                            isDefaultOrder={this.state.isSaveAsDefaultOrder}
                            companyData={this.props.company}
                            requestCompanyLogo={this.props.requestCompanyLogo}
                            groupInfo={this.props.groupData}
                            changeControllerInfo={this.changeControllerInfo}
                            parentResourceIdentifier={this.props.parentResourceIdentifier}
                            sendForReviewResourceId={SendTaxReturnResources.SendForReviewMenu}
                            sendToEroSignerResourceId={SendTaxReturnResources.SendToEROSignerMenu}
                            approveForDeliveryResourceId={SendTaxReturnResources.ApproveForDeliveryMenu}
                            deliverToControllerResourceId={SendTaxReturnResources.DeliverToClientMenu}
                            paperDeliveryResourceId={SendTaxReturnResources.DownloadPDFMenu}
                        />
                    </Popover.Content>
                </Popover>
            );
        } else {
            finishPopover = (
                <Popover id="finish-popover" className="finishPopover" style={{ width: "750px", height: "530px", top: "219px" }}>
                    <Popover.Title as="h3">Finish Return Processing</Popover.Title>
                    <Popover.Content>
                        <FinishPopover
                            taxreturn={taxDocument}
                            users={this.props.users}
                            userProfile={this.props.userBasicProfile}
                            companySettings={this.props.companySettings}
                            sendToERO={this.sendToERO}
                            sendForReview={this.sendForReview}
                            approveForDelivery={this.approveForDelivery}
                            deliverToClient={this.deliverToClient}
                            getTaxCaddyLookupDetails={this.props.getTaxCaddyLookupDetails}
                            deliverWithTaxcaddy={this.deliverWithTaxcaddy}
                            updateTaxDocument={this.updateTaxDocument}
                            onPrintForPaperDelivery={this.onPrintForPaperDelivery}
                            updateDocumentGroupOrder={this.props.updateDocumentGroupOrder}
                            handleSaveAsDefaultOrderChange={this.handleSaveAsDefaultOrderChange}
                            isDefaultOrder={this.state.isSaveAsDefaultOrder}
                            companyData={this.props.company}
                            requestCompanyLogo={this.props.requestCompanyLogo}
                            parentResourceIdentifier={this.props.parentResourceIdentifier}
                            partnerUsers={UserHelper.getPartnerUsers()}
                            deliverFirstToUpdated={this.deliverFirstToUpdated}
                        />
                    </Popover.Content>
                </Popover>
            );
        }

        return finishPopover;
    };

    public render() {
        let defaultActiveTab = this.state.viewIndex || 1;

        const { authorities, pdfDocuments, order, docId, taxDocuments } = this.props;
        const taxDocument = taxDocuments[docId]?.taxReturn;
        const isBusy = this.state.isBusy || !this.props.taxDocuments[docId]?.isFullyLoaded;
        const p = pdfDocuments[docId].document;

        const clientInfo = new ClientInfoView(taxDocument);
        const clientInfoBookmarks = clientInfo.getBookmarks(authorities, this.props.getAllTaxingAuthorities);
        const clientInfoBookmarksWithZeroRefund = clientInfo.getBookmarksWithZeroRefund(
            authorities,
            this.props.getAllTaxingAuthorities
        );
        const clientInfoPages = clientInfo.getPages(clientInfoBookmarksWithZeroRefund);

        const transmittals = new Transmittalsview(taxDocument);
        const transmittalsbookmarks = transmittals.getBookmarks();
        const transmittalspages = transmittals.getPages(transmittalsbookmarks);
        const transmittalsSignatureControls = transmittals.getSignatureControls();

        const k1 = new K1sView(taxDocument);
        const k1Bookmarks = k1.getBookmarks();
        if (k1Bookmarks[0].pages.length > 0 && this.state.isK1BookmarkLoaded == false) {
            this.setState({ isK1BookmarkLoaded: true });
        }
        const k1Pages =
            k1Bookmarks[0].pages.length > 0
                ? k1.getK1Pages(
                    this.state.selectedK1Bookmark === 0 ||
                        !k1Bookmarks[0].pages.find((m) => m.id == this.state.selectedK1Bookmark)
                        ? k1Bookmarks[0].pages[0].id
                        : this.state.selectedK1Bookmark
                )
                : [];

        let shareHolderInfo: IShareHolder =
            k1Bookmarks[0].pages.length > 0
                ? this.getShareHolderInfo(
                    this.state.selectedK1Bookmark === 0 ||
                        !k1Bookmarks[0].pages.find((m) => m.id == this.state.selectedK1Bookmark)
                        ? k1Bookmarks[0].pages[0].id
                        : this.state.selectedK1Bookmark
                )
                : {
                    name: "",
                    ssn: "",
                    id: 0,
                    emailAddress: "",
                    k1DownloadDate: "",
                    consentStatus: ConsentStatus.None,
                    k1SendDate: "",
                    countryCode: "",
                    mobileNumber: "",
                    entityType: EntityType.None
                };

        const taxReturnInfo = new TaxReturnInfoView(taxDocument);
        const taxReturnBookmarks = taxReturnInfo.getTaxReturnInfoBookmarks(taxDocument);
        const taxReturnPages = taxReturnInfo.getPages(taxReturnBookmarks);
        const taxReturnSignatureControls = taxReturnInfo.getSignatureControls();

        const eFileInfo = new EfileInfoView(taxDocument);
        const efileBookmarks = eFileInfo.getBookmarks(
            authorities,
            taxDocument.documentSettings?.documentSignatureSetting.signatureFormSelectionType
        );
        const efilePages = eFileInfo.getPages(efileBookmarks);
        let isEsign = this.checkIfEsignEnabled(efileBookmarks);
        let signatureControls = eFileInfo.getSignatureControls();

        let voucherInfoView = new VoucherInfoView(taxDocument);
        let renderCache = this.state.renderCache;
        let voucherSort = new VoucherSort(taxDocument, authorities);
        if (!voucherSort.isVouchersEqual(renderCache.voucher.vouchers)) {
            renderCache.voucher.bookmarks = voucherSort.getSortedBookmarks();
            renderCache.voucher.vouchers = voucherSort.getVouchers();
            this.setState({ renderCache: renderCache });
        }
        const voucherPages = voucherInfoView.getPages(renderCache.voucher.bookmarks);

        const voucherAuthorityId: number[] = voucherInfoView.getVoucherAuthorities(renderCache.voucher.vouchers);
        const taxingAuthorities = voucherInfoView.getAllAuthorities(this.props.getAllTaxingAuthorities);

        this.deactivatedAuthority = voucherAuthorityId.filter((item) => {
            return taxingAuthorities.indexOf(item) === -1;
        });

        let invoiceInfo = new InvoiceInfoView(taxDocument);
        let invoiceBookmarks = invoiceInfo.getInvoiceBookmarks(authorities);
        let invoicePages = invoiceInfo.getInvoicePages(invoiceBookmarks);
        const invoiceSignatureControls = invoiceInfo.getSignatureControls();

        const pop = { "max-width": "276px !important" };

        return (
            <CustomWindow
                key={"custome-window" + taxDocument?.id}
                onClose={() => {
                    this.onModalClose(taxDocument?.id);
                }}
                title={Helper.taxReturnTitle(taxDocument)}
                order={order}
                disableMaximize={true}
                disableMinimize={true}
                disableFullscreen={false}
                alwaysFitToParent={true}
                taxReturn={taxDocument}
                onReportProblemSave={this.props.onReportProblemSave}
                onReportProblemOpen={this.props.onReportProblemOpen}
                onReportProblemCancel={this.props.onReportProblemCancel}
                reportProblemState={this.props.reportProblemState}
                onResize={() => {
                    this.setState({ renderTrigger: Guid.create().toString() });
                }}
                parentResourceIdentifier={SendTaxReturnResources.GridButtonPrefix}
            >
                {
                    <CustomWindowBody isBusy={isBusy}>
                        {this.createPageCarousel()}
                        <Tabs
                            activeKey={this.state.viewIndex ? this.state.viewIndex : defaultActiveTab}
                            onSelect={this.handleTabSelect}
                            id="process-return-groups"
                            fill
                        >
                            <Tab
                                eventKey={ProcessReturnTabs.ClientInfo}
                                title={<div className="overflowText">Client Info</div>}
                                tabClassName="zIndex10"
                            >
                                {this.state.viewIndex == ProcessReturnTabs.ClientInfo && (
                                    <TabClientInfo
                                        bookmarks={clientInfoBookmarks}
                                        pages={clientInfoPages}
                                        docId={docId}
                                        pdfDocuments={this.props.pdfDocuments}
                                        taxReturn={taxDocuments[docId]?.taxReturn}
                                        prefilledClientInfo={this.state.prefilledClientInfo}
                                        resetPrefillInfo={this.resetPrefillInfo}
                                        updateTaxDocument={this.props.updateTaxDocument}
                                        isAssignedToLoggedinUser={this.isAssignedToLoggedinUser()}
                                        reGroupPreview={this.reGroupPreview}
                                        deletePages={this.deleteRefundOrPayment}
                                        renderTrigger={this.state.renderTrigger}
                                        tabType={ProcessReturnTabs.ClientInfo}
                                        allSignerList={UserHelper.getPartnerUsers()}
                                        getAllTaxingAuthorities={this.props.getAllTaxingAuthorities}
                                        authorities={this.props.authorities}
                                        states={this.props.company.companyProfile.countryStates}
                                        checkDeceased={this.checkDeceased}
                                        getVoucherUploadLink={this.props.getVoucherUploadLink}
                                        requestEroSignaturePath={this.props.requestEroSignaturePath}
                                        company={this.props.company}
                                        locationDropdown={this.props.locationDropdown}
                                        isGroupedReturn={taxDocuments[docId]?.taxReturn.groupId > 0}
                                        deletedVouchers={this.state.deletedVouchers}
                                        saveDeletedVouchers={this.saveDeletedVouchers}
                                        deliverFirstToUpdated={this.deliverFirstToUpdated}
                                    />
                                )}
                            </Tab>

                            <Tab
                                eventKey={ProcessReturnTabs.Grouping}
                                title={<div className="overflowText">Group</div>}
                                className="zIndex10"
                            >
                                {this.state.viewIndex == ProcessReturnTabs.Grouping && (
                                    <GroupsView
                                        engagementType={taxDocuments[docId]?.taxReturn.engagementType}
                                        arrangement={taxDocuments[docId]?.taxReturn.arrangement}
                                        pdfDocuments={this.props.pdfDocuments}
                                        docId={docId}
                                        formGroups={taxDocument.formGroups}
                                        deletePages={this.deletePages}
                                        onPreviewPage={this.openCarouselView}
                                        renderTrigger={this.state.renderTrigger}
                                        reGroupPreview={this.reGroupPreview}
                                        isAssignedToLoggedinUser={this.isAssignedToLoggedinUser()}
                                        isEnableInvoice={
                                            this.props.companySettings.displaySettingsModel.isEnableInvoice &&
                                            TaxDocument.isInvoiceAllowed(taxDocument)
                                        }
                                        efileBookmarks={efileBookmarks}
                                    />
                                )}
                            </Tab>

                            <Tab
                                eventKey={ProcessReturnTabs.Transmittals}
                                title={<div className="overflowText">Transmittals</div>}
                                className="zIndex10"
                            >
                                {this.state.viewIndex == ProcessReturnTabs.Transmittals && (
                                    <TabTransmittals
                                        auth={this.props.auth}
                                        bookmarks={transmittalsbookmarks}
                                        pages={transmittalspages}
                                        docId={docId}
                                        pdfDocuments={pdfDocuments}
                                        taxReturn={taxDocuments[docId]?.taxReturn}
                                        formGroup={
                                            taxDocument.formGroups.filter(
                                                (x) => x.group === TaxDocument.DocumentGroups.Transmittals
                                            )[0]
                                        }
                                        reGroupPreview={this.reGroupPreview}
                                        deletePages={this.deletePages}
                                        renderTrigger={this.state.renderTrigger}
                                        getAllTaxingAuthorities={this.props.getAllTaxingAuthorities}
                                        isAssignedToLoggedinUser={this.isAssignedToLoggedinUser()}
                                        isEnableInvoice={this.props.companySettings.displaySettingsModel.isEnableInvoice}
                                        getVoucherUploadLink={this.props.getVoucherUploadLink}
                                        voucherStore={this.props.voucherStore}
                                        updateTaxDocument={this.props.updateTaxDocument}
                                        getMultiVoucherUploadLink={this.props.getMultiVoucherUploadLink}
                                        saveTaxingAuthority={this.props.saveTaxingAuthority}
                                        requestTaxingAuthorities={this.props.requestTaxingAuthorities}
                                        userSettings={this.props.userSettings}
                                        signatureControls={transmittalsSignatureControls}
                                        removeSignatureControl={this.removeSignatureControlTransmittalForm}
                                        addSignatureControl={this.addSignatureControlTransmittalForm}
                                        updateEROSignatureStamp={this.updateEROSignatureStamp}
                                        replaceSignatureControl={this.replaceSignatureControlTransmittalForm}
                                        company={this.props.company}
                                        userProfile={this.props.userBasicProfile}
                                        userSignatures={this.props.userSignatures}
                                    />
                                )}
                            </Tab>

                            <Tab
                                eventKey={ProcessReturnTabs.TaxReturns}
                                title={<div className="overflowText">Tax Returns</div>}
                                className="zIndex10"
                            >
                                {this.state.viewIndex == ProcessReturnTabs.TaxReturns && (
                                    <TabTaxReturn
                                        auth={this.props.auth}
                                        bookmarks={taxReturnBookmarks}
                                        pdfDocuments={this.props.pdfDocuments}
                                        docId={docId}
                                        reGroupPreview={this.reGroupPreview}
                                        formGroup={
                                            taxDocument.formGroups.filter(
                                                (x) => x.group === TaxDocument.DocumentGroups.TaxReturns
                                            )[0]
                                        }
                                        taxReturn={taxDocuments[docId]?.taxReturn}
                                        deletePages={this.deletePages}
                                        renderTrigger={this.state.renderTrigger}
                                        getAllTaxingAuthorities={this.props.getAllTaxingAuthorities}
                                        isAssignedToLoggedinUser={this.isAssignedToLoggedinUser()}
                                        isEnableInvoice={this.props.companySettings.displaySettingsModel.isEnableInvoice}
                                        userSettings={this.props.userSettings}
                                        signatureControls={taxReturnSignatureControls}
                                        removeSignatureControl={this.removeSignatureControlTaxReturnForm}
                                        addSignatureControl={this.addSignatureControlTaxReturnForm}
                                        updateEROSignatureStamp={this.updateEROSignatureStamp}
                                        replaceSignatureControl={this.replaceSignatureControlTaxReturnForm}
                                        company={this.props.company}
                                        userProfile={this.props.userBasicProfile}
                                        userSignatures={this.props.userSignatures}
                                        pages={taxReturnPages}
                                        updateTaxDocument={this.props.updateTaxDocument}
                                    />
                                )}
                            </Tab>

                            <Tab
                                eventKey={ProcessReturnTabs.EFile}
                                title={<div className="overflowText">E-File</div>}
                                className="zIndex10"
                            >
                                {this.state.viewIndex == ProcessReturnTabs.EFile && (
                                    <TabEFile
                                        auth={this.props.auth}
                                        isLoading={this.props.isLoading}
                                        bookmarks={efileBookmarks}
                                        pages={efilePages}
                                        docId={docId}
                                        pdfDocuments={this.props.pdfDocuments}
                                        taxReturn={taxDocuments[docId]?.taxReturn}
                                        updateTaxDocument={this.props.updateTaxDocument}
                                        isAssignedToLoggedinUser={this.isAssignedToLoggedinUser()}
                                        formGroup={
                                            taxDocument.formGroups.filter((x) => x.group === TaxDocument.DocumentGroups.EFile)[0]
                                        }
                                        reGroupPreview={this.reGroupPreview}
                                        deletePages={this.deletePages}
                                        renderTrigger={this.state.renderTrigger}
                                        userSettings={this.props.userSettings}
                                        isEsign={isEsign}
                                        enableEsignatureForBusinessReturns={
                                            this.props.companySettings.signatureSettingsModel.enableEsignatureForBusinessReturns
                                        }
                                        company={this.props.company}
                                        userProfile={this.props.userBasicProfile}
                                        signatureControls={signatureControls}
                                        userSignatures={this.props.userSignatures}
                                        removeSignatureControl={this.removeSignatureControlEFieForm}
                                        addSignatureControl={this.addSignatureControlEFieForm}
                                        updateEROSignatureStamp={this.updateEROSignatureStamp}
                                        replaceSignatureControl={this.replaceSignatureControlEFieForm}
                                        signatureFormSelectionType={
                                            this.props.companySettings.signatureSettingsModel.signatureFormSelectionType
                                        }
                                        getAllTaxingAuthorities={this.props.getAllTaxingAuthorities}
                                        isEnableInvoice={this.props.companySettings.displaySettingsModel.isEnableInvoice}
                                        isDoNotDisplayDatesEnabled={this.state.isDoNotDisplayDatesEnabled}
                                        onToggleEfileDateSettings={this.onToggleEfileDateSettings}
                                    />
                                )}
                            </Tab>

                            {this.props.companySettings.displaySettingsModel.isEnablePaperFiled && (
                                <Tab
                                    eventKey={ProcessReturnTabs.PaperFile}
                                    title={<div className="overflowText">Paper File</div>}
                                    className="zIndex10"
                                >
                                    {this.state.viewIndex == ProcessReturnTabs.PaperFile && (
                                        <TabPaperFile
                                            auth={this.props.auth}
                                            taxReturn={taxDocuments[docId]?.taxReturn}
                                            deleteUploadedPaperFileFromBlob={this.props.deleteUploadedPaperFileFromBlob}
                                            updateTaxDocument={this.props.updateTaxDocument}
                                            isAssignedToLoggedinUser={this.isAssignedToLoggedinUser()}
                                            reGroupPreview={this.reGroupPreview}
                                            renderTrigger={this.state.renderTrigger}
                                            userSettings={this.props.userSettings}
                                            company={this.props.company}
                                            userProfile={this.props.userBasicProfile}
                                            userSignatures={this.props.userSignatures}
                                            updateEROSignatureStamp={this.updateEROSignatureStamp}
                                            signatureFormSelectionType={
                                                this.props.companySettings.signatureSettingsModel.signatureFormSelectionType
                                            }
                                            getPaperFileUploadLink={this.props.getPaperFileUploadLink}
                                            getPaperFileDownLoadLink={this.props.getPaperFileDownLoadLink}
                                            taxingAuthorities={this.props.getAllTaxingAuthorities}
                                            addPaperFileData={this.addPaperFileData}
                                            updatePaperFile={this.updatePaperFile}
                                            paperFileUploadData={this.state.paperFileUploadData}
                                            convertToPdfAsync={this.props.convertToPdfAsync}
                                        />
                                    )}
                                </Tab>
                            )}

                            {
                                <Tab
                                    eventKey={ProcessReturnTabs.AdditionalEsignDocuments}
                                    title={<div className="overflowText">Additional E-Sign Documents</div>}
                                    className="zIndex10"
                                >
                                    {this.state.viewIndex == ProcessReturnTabs.AdditionalEsignDocuments && (
                                        <TabAdditionalEsignDocuments
                                            auth={this.props.auth}
                                            taxReturn={taxDocuments[docId]?.taxReturn}
                                            getAddtionalEsignDocumentUploadLink={this.props.getAddtionalEsignDocumentUploadLink}
                                            getAddtionalEsignDocumentDownLoadLink={
                                                this.props.getAddtionalEsignDocumentDownLoadLink
                                            }
                                            convertDocToPdfArtifactAsync={this.props.convertDocToPdfArtifactAsync}
                                            requestAdditionalDocumentType={this.props.requestAdditionalDocumentType}
                                            additionalDocumentTypeData={this.props.additionalDocumentTypeData}
                                            deleteUploadedAdditionalDocumentBlobFile={
                                                this.props.deleteUploadedAdditionalDocumentBlobFile
                                            }
                                            deleteAdditionalDocument={this.props.deleteAdditionalDocument}
                                            updateTaxDocument={this.props.updateTaxDocument}
                                            isAssignedToLoggedinUser={this.isAssignedToLoggedinUser()}
                                            reGroupPreview={this.reGroupPreview}
                                            renderTrigger={this.state.renderTrigger}
                                            userSettings={this.props.userSettings}
                                            isEsign={isEsign}
                                            enableEsignatureForBusinessReturns={
                                                this.props.companySettings.signatureSettingsModel
                                                    .enableEsignatureForBusinessReturns
                                            }
                                            company={this.props.company}
                                            userProfile={this.props.userBasicProfile}
                                            userSignatures={this.props.userSignatures}
                                            updateEROSignatureStamp={this.updateEROSignatureStamp}
                                            signatureFormSelectionType={
                                                this.props.companySettings.signatureSettingsModel.signatureFormSelectionType
                                            }
                                            isEnableInvoice={this.props.companySettings.displaySettingsModel.isEnableInvoice}
                                            addAdditionalEsignDocumentData={this.addAdditionalEsignDocumentData}
                                            uploadedAdditionalEsignDocumentData={this.state.uploadedAdditionalEsignDocumentData}
                                            updateAdditionalEsignDocumentData={this.updateAdditionalEsignDocumentData}
                                        />
                                    )}
                                </Tab>
                            }

                            <Tab
                                eventKey={ProcessReturnTabs.Vouchers}
                                title={<div className="overflowText">Vouchers</div>}
                                className="zIndex10"
                            >
                                {this.state.viewIndex == ProcessReturnTabs.Vouchers && (
                                    <TabVoucher
                                        bookmarks={this.state.renderCache.voucher.bookmarks}
                                        pages={voucherPages}
                                        docId={docId}
                                        pdfDocuments={this.props.pdfDocuments}
                                        taxReturn={taxDocuments[docId]?.taxReturn}
                                        updateTaxDocument={this.props.updateTaxDocument}
                                        isAssignedToLoggedinUser={this.isAssignedToLoggedinUser()}
                                        reGroupPreview={this.reGroupPreview}
                                        deletePages={this.deletePages}
                                        renderTrigger={this.state.renderTrigger}
                                        getVoucherUploadLink={this.props.getVoucherUploadLink}
                                        getMultiVoucherUploadLink={this.props.getMultiVoucherUploadLink}
                                        voucherStore={this.props.voucherStore}
                                        getAllTaxingAuthorities={this.props.getAllTaxingAuthorities}
                                        isEnableInvoice={this.props.companySettings.displaySettingsModel.isEnableInvoice}
                                        saveTaxingAuthority={this.props.saveTaxingAuthority}
                                        requestTaxingAuthorities={this.props.requestTaxingAuthorities}
                                        formGroup={
                                            taxDocument.formGroups.filter(
                                                (x) => x.group === TaxDocument.DocumentGroups.Vouchers
                                            )[0]
                                        }
                                        showVoucherLabel={false}
                                        updateMultipleVouchersAdd={this.updateMultipleVouchersAdd}
                                    />
                                )}
                            </Tab>

                            {TaxDocument.isK1ShareHolder(taxDocument) && (
                                <Tab
                                    eventKey={ProcessReturnTabs.K1}
                                    title={<div className="overflowText">K-1's</div>}
                                    className="zIndex10"
                                >
                                    {this.state.viewIndex == ProcessReturnTabs.K1 && (
                                        <TabK1s
                                            disableSaveClose={this.disableSaveClose}
                                            bookmarks={k1Bookmarks}
                                            document={this.props.pdfDocuments}
                                            pages={k1Pages}
                                            reGroupPreview={this.reGroupPreview}
                                            taxReturn={taxDocuments[docId]?.taxReturn}
                                            getTitle={this.onK1BookmarkClick}
                                            renderTrigger={this.state.renderTrigger}
                                            shareHolderInfo={shareHolderInfo}
                                            updateTaxDocument={this.props.updateTaxDocument}
                                            getK1InstructionFileName={this.props.getK1InstructionFileName}
                                            fileName={this.props.taxDocuments[this.props.docId].fileName!}
                                            uploadK1Instruction={this.props.uploadK1Instruction}
                                            downloadK1DocumentLink={this.props.downloadK1DocumentLink}
                                            isAssignedToLoggedinUser={this.isAssignedToLoggedinUser()}
                                            verifyK1Replaced={this.state.verifyK1Replaced}
                                            isK1Replaced={this.state.isK1Replaced}
                                            isK1Restored={this.state.isK1Restored}
                                            K1InstructionModified={this.K1InstructionModified}
                                            restoreK1Instruction={this.props.restoreK1Instruction}
                                            getAllTaxingAuthorities={this.props.getAllTaxingAuthorities}
                                            businessInstructionStore={this.props.businessInstructionStore}
                                            isEnableInvoice={this.props.companySettings.displaySettingsModel.isEnableInvoice}
                                            getk1AttachmentUploadLink={this.props.getk1AttachmentUploadLink}
                                            userProfile={this.props.userBasicProfile}
                                            isDefaultK1Instruction={this.props.taxDocuments[this.props.docId].isDefaultK1}
                                            isCompanyK1SSNMaskingEnabled={
                                                this.props.companySettings.k1Settings.isSSNMaskingEnabled
                                            }
                                            k1SSNDetails={this.props.k1SSNDetails && this.props.k1SSNDetails[this.props.docId]}
                                            getK1SSNDetailsForPage={this.props.getK1SSNDetailsForPage}
                                            pageWiseSSNDetails={this.props.pageWiseSSNDetails}
                                        />
                                    )}
                                </Tab>
                            )}

                            {this.props.companySettings.displaySettingsModel.isEnableInvoice &&
                                TaxDocument.isInvoiceAllowed(taxDocument) ? (
                                <Tab
                                    eventKey={ProcessReturnTabs.InVoice}
                                    title={<div className="overflowText">Invoices</div>}
                                    className="zIndex10"
                                >
                                    {this.state.viewIndex == ProcessReturnTabs.InVoice && (
                                        <TabInvoice
                                            auth={this.props.auth}
                                            bookmarks={invoiceBookmarks}
                                            pages={invoicePages}
                                            docId={docId}
                                            pdfDocuments={this.props.pdfDocuments}
                                            taxReturn={taxDocuments[docId]?.taxReturn}
                                            reGroupPreview={this.reGroupPreview}
                                            deletePages={this.deletePages}
                                            renderTrigger={this.state.renderTrigger}
                                            updateTaxDocument={this.props.updateTaxDocument}
                                            isAssignedToLoggedinUser={this.isAssignedToLoggedinUser()}
                                            companySettings={this.props.companySettings}
                                            getAllTaxingAuthorities={this.props.getAllTaxingAuthorities}
                                            userSettings={this.props.userSettings}
                                            signatureControls={invoiceSignatureControls}
                                            removeSignatureControl={this.removeSignatureControlInvoiceForm}
                                            addSignatureControl={this.addSignatureControlInvoiceForm}
                                            updateEROSignatureStamp={this.updateEROSignatureStamp}
                                            replaceSignatureControl={this.replaceSignatureControlInvoiceForm}
                                            company={this.props.company}
                                            userProfile={this.props.userBasicProfile}
                                            userSignatures={this.props.userSignatures}
                                            formGroup={
                                                taxDocument.formGroups.filter(
                                                    (x) => x.group === TaxDocument.DocumentGroups.Invoice
                                                )[0]
                                            }
                                        />
                                    )}
                                </Tab>
                            ) : undefined}
                            <Tab
                                eventKey={ProcessReturnTabs.Attachments}
                                selected={this.state.viewIndex == ProcessReturnTabs.Attachments}
                                title={<div className="overflowText">Attachments</div>}
                                className="zIndex10"
                            >
                                {this.state.viewIndex == ProcessReturnTabs.Attachments && (
                                    <TabAttachment
                                        Attachment={this.getAttachmentsprops(
                                            docId,
                                            true,
                                            taxDocuments[docId]?.taxReturn,
                                            this.props.userBasicProfile
                                        )}
                                        requestAttachments={this.props.requestAttachments}
                                        downloadAttachments={this.props.downloadAttachments}
                                        previewAttachments={this.props.previewAttachments}
                                        setPageBusy={this.setPageBusy}
                                        defaultInstructionId={this.props.ssrSettings.defaultMessageSettings.attachmentInstruction}
                                        attachmentInstructions={this.props.attachmentInstructions}
                                    />
                                )}
                            </Tab>

                            <Tab
                                eventKey={ProcessReturnTabs.DeliveryOptions}
                                title={<div className="overflowText">Delivery Options</div>}
                            >
                                {this.state.viewIndex == ProcessReturnTabs.DeliveryOptions && (
                                    <TabDeliveryOptions
                                        taxReturn={taxDocuments[docId]?.taxReturn}
                                        company={this.props.company}
                                        companySettings={this.props.companySettings}
                                        isAssignedToLoggedinUser={this.isAssignedToLoggedinUser()}
                                        isEsign={isEsign}
                                        generateTaxPayerView={this.generateTaxPayerView}
                                        users={this.props.users}
                                        savedMessages={this.props.savedMessages}
                                        getAllSavedMessages={this.props.getAllSavedMessages}
                                        requestMailingReturnAddressList={this.props.requestMailingReturnAddressList}
                                        mailingReturnAddressList={this.props.mailingReturnAddressList!}
                                        disabledTaxpayerPreview={this.state.disabledTaxpayerPreview}
                                        signatureTypeInDocSettings={this.state.signatureTypeInDocSettings}
                                        isSignatureoptionsToggledInDeliveryOption={
                                            this.state.isSignatureoptionsToggledInDeliveryOption
                                        }
                                        handleSignatureOptionToggle={this.handleSignatureOptionToggle}
                                        watermarkList={this.props.watermarkList}
                                        customColumnData={this.props.customColumnData}
                                        userSettings={this.props.userSettings}
                                        handleCustomColumnChange={this.handleCustomColumnChange}
                                        isLoading={this.props.isLoading}
                                        groups={this.props.groups}
                                        onGroupChange={this.onGroupChange}
                                        selectedGroup={this.state.groupInfo}
                                        paperFileMessage={this.props.paperFileMessage}
                                        ssrSettings={this.props.ssrSettings}
                                        updateTaxDocument={this.props.updateTaxDocument}
                                        showSMSTextNotifications={this.state.showSMSTextNotifications}
                                        isTPOpted={this.state.isTPOpted}
                                        isSpouseOpted={this.state.isSpouseOpted}
                                        smsNotificationDetails={this.state.smsNotificationDetails}
                                        updateTPOpted={this.updateTPOpted}
                                        updateSpouseOpted={this.updateSpouseOpted}
                                        inactiveUsers={this.props.inactiveusers}
                                        helptexts={this.props.helpTexts}
                                        deliverFirstToUpdated={this.deliverFirstToUpdated}
                                        preparerMessageUpdated={this.preparerMessageUpdated}
                                        isPreparerMessageUpdated={this.state.isPreparerMessageUpdated}
                                        paperfileInstructionUpdated={this.paperfileInstructionUpdated}
                                        isPaperfileInstructionUpdated={this.state.isPaperfileInstructionUpdated}
                                    />
                                )}
                            </Tab>
                            {taxDocument.groupId > 0 && (
                                <Tab
                                    eventKey={ProcessReturnTabs.GroupDelivery}
                                    title={<div className="overflowText">Group Delivery</div>}
                                >
                                    {this.state.viewIndex == ProcessReturnTabs.GroupDelivery && (
                                        <TabGroupDelivery
                                            markAsReadyForDelivery={this.getMarkAsDeliveryStatus(taxDocuments[docId]?.taxReturn)}
                                            onMarkedAsReadyForDeliveryChecked={(checked: boolean) => {
                                                this.handleMarkAsReadyForDelivery(checked, taxDocuments[docId]?.taxReturn);
                                            }}
                                            groupedTaxDocuments={this.props.groupedTaxDocuments}
                                            taxReturn={taxDocuments[docId]?.taxReturn}
                                            onMarkedAsReadyForDeliveryClicked={this.markReadyForDelivery}
                                            onProcessReturn={this.onNextReturnProcess}
                                            groupId={taxDocuments[docId]?.taxReturn.groupId}
                                            userBasicProfile={this.props.userBasicProfile}
                                        />
                                    )}
                                </Tab>
                            )}
                        </Tabs>
                    </CustomWindowBody>
                }

                <CustomWindowFooter>
                    <Button
                        variant="default"
                        data-test-auto="27A54F41-0CFF-4F81-B684-2052B7424A9B"
                        className="btn-white"
                        disabled={(this.state.viewIndex ? this.state.viewIndex : defaultActiveTab) === viewMin || isBusy}
                        onClick={() => {
                            this.onPrevious(this.state.viewIndex ? this.state.viewIndex : defaultActiveTab);
                        }}
                    >
                        <i className="fa fa-chevron-left"></i>Previous
                    </Button>
                    <Button
                        variant="default"
                        data-test-auto="B1C60A60-C306-4A4D-B914-AC034DCB71E6"
                        className="btn-white"
                        disabled={(this.state.viewIndex ? this.state.viewIndex : defaultActiveTab) === viewMax || isBusy}
                        onClick={() => {
                            this.onNext(this.state.viewIndex ? this.state.viewIndex : defaultActiveTab);
                        }}
                    >
                        <i className="fa fa-chevron-right"></i>Next
                    </Button>
                    <Button
                        disabled={isBusy || this.state.disableSaveClose}
                        data-test-auto="BC827AB3-8EBD-4A3B-A7F9-72D632DCFB0D"
                        variant="info"
                        onClick={this.onSaveTaxReturn}
                    >
                        <i className="fas fa-save"></i>Save & Close
                    </Button>
                    <Button
                        disabled={isBusy || this.state.disableFinish}
                        data-test-auto="B88BCB03-B5BB-4EA6-AE43-D80336A1A927"
                        variant="success"
                        onClick={this.onFinishProcessing}
                        ref={(reference: any) => {
                            this.closePopoverRef = reference;
                        }}
                    >
                        <i className="fa fa-check"></i>Finish
                    </Button>

                    <Overlay
                        rootClose={true}
                        placement="top"
                        onHide={() => {
                            this.setState({ showClosePopover: false });
                        }}
                        target={this.closePopoverRef}
                        show={this.state.showClosePopover}
                    >
                        {this.getFinishPopover(taxDocument)}
                    </Overlay>

                    {this.state.showMismatchModal && (
                        <MismatchModal
                            showMismatchModal={this.state.showMismatchModal}
                            clientInfoComparisonResult={this.state.clientInfoComparisonResult}
                            onPrimaryButtonClick={this.onPrimaryButtonClick}
                            onSecondaryButtonClick={this.onSecondaryButtonClick}
                            closeMismatchModal={this.closeMismatchModal}
                            taxReturnType={Helper.taxReturnType(this.props.taxDocuments[this.props.docId]?.taxReturn)}
                            popupInProgress={this.state.popupInProgress}
                        />
                    )}

                    {this.state.showLocationMismatchActionModel && (
                        <LocationMismatchActionModal
                            onCancel={this.onCloseLocationMismatchActionModal}
                            onContinue={this.onContinueLocationMismatchActionModal}
                            show={this.state.showLocationMismatchActionModel}
                            locationName={this.getLocationName()}
                        />
                    )}
                </CustomWindowFooter>
                <GroupFilesPreview
                    showGroupFilesPreview={this.state.showGroupFilesPreview}
                    onHideGroupFilesPreview={this.onHideGroupFilesPreview}
                    pages={this.state.pages}
                    destinationGroup={this.state.destinationGroup}
                    matchedForms={this.state.matchedForms}
                    pdfDocuments={this.props.pdfDocuments}
                    documentId={docId}
                    renderTrigger={this.state.renderTrigger}
                    removePage={this.removePage}
                    onMove={this.reGroup}
                    authoritiesOptions={this.state.authoritiesOptions}
                    shareHolderOptions={this.state.shareHolderOptions}
                    onVoucherDataUpdate={this.onVoucherDataUpdate}
                    onEFileDataUpdate={this.onEFileDataUpdate}
                    onKOneDataUpdate={this.onK1DataDataUpdate}
                    taxReturn={taxDocuments[docId]?.taxReturn}
                    voucherStore={this.props.voucherStore}
                    allTaxingAuthorities={this.props.getAllTaxingAuthorities}
                />
                <input type="hidden" id="documentsGuid" value={taxDocument.documentGuid} />
            </CustomWindow>
        );
    }

    private taxReturnHeading() {
        {
            const { docId, taxDocuments } = this.props;
            const taxDocument = taxDocuments[docId]?.taxReturn;
            return TaxDocument.isPartnership(taxDocument)
                ? taxDocument.partnership.name + " - " + taxDocument.clientId
                : TaxDocument.isMutual(taxDocument)
                    ? taxDocument.taxpayer.name + " - " + taxDocument.clientId
                    : TaxDocument.isIndividual(taxDocument)
                        ? taxDocument.taxpayer.name + " - " + taxDocument.clientId
                        : "";
        }
    }

    private handleTabSelect = (index: any) => {
        this.setState({ viewIndex: parseInt(index.toString()) }, () => {
            localStorage.set(this.props.docId.toString(), index);
        });
    };

    private isAssignedToLoggedinUser(): boolean {
        return true; //TODO: implement the logic
    }

    private getAttachmentsprops(
        docId: number,
        isAssignedToLoggedinUser: boolean,
        taxReturn: any,
        userBasicProfile: any
    ): AttachmentsState {
        let attachment: AttachmentsState = {
            documentId: docId,
            saveAttachments: this.saveAttachments,
            isAssignedToLoggedinUser: isAssignedToLoggedinUser,
            taxReturn: taxReturn,
            userProfile: userBasicProfile,
            attachments: taxReturn.attachments
        };
        return attachment;
    }

    ///////////////////////////////////////Grouping Start//////////////////////////////////////////////////////////
    private reGroupPreview(
        pages: number[],
        destinationGroup: TaxDocument.DocumentGroups,
        sourceGroup?: TaxDocument.DocumentGroups,
        callback?: () => void
    ) {
        let taxDocument = this.props.taxDocuments[this.props.docId]?.taxReturn;
        let matchedForms: TaxDocument.IFormBase[] = [];
        let isValid: boolean = true;

        taxDocument.formGroups.map((form) => {
            if (form.group == DocumentGroups.EFile) {
                let efileAuthrity: IEFile = form.forms.find((x) => x.pageNo[0] === pages[0]) as IEFile;
                if (efileAuthrity != undefined && efileAuthrity.authorityID == 0) {
                    VenusNotifier.Warning(TabEFileConstants.EfileForm.Authority, "");
                    isValid = false;
                }
            }
        });

        if (isValid) {
            taxDocument.formGroups.map((formGroups) => {
                //No need to do anything for forms that are already in destinationGroup
                if (formGroups.group != destinationGroup) {
                    formGroups.forms.map((forms) => {
                        if (forms.pageNo.some((x) => pages.some((y) => y == x))) matchedForms.push(forms);
                    });
                } else {
                    formGroups.forms.map((forms) => {
                        pages = pages.filter(function (page) {
                            return forms.pageNo.indexOf(page) < 0;
                        });
                    });
                }
            });

            if (pages.length > 0) {
                switch (destinationGroup) {
                    case TaxDocument.DocumentGroups.Transmittals:
                    case TaxDocument.DocumentGroups.TaxReturns:
                    case TaxDocument.DocumentGroups.Invoice:
                        this.reGroup(pages, matchedForms, destinationGroup);
                        VenusNotifier.Success(Constants.ReGroupConstants.PageMoveSuccess, null);
                        break;
                    case TaxDocument.DocumentGroups.Deleted:
                        this.reGroup(pages, matchedForms, destinationGroup);
                        VenusNotifier.Success(Constants.ReGroupConstants.DeletedPageMoveSuccess, null);
                        break;
                    case TaxDocument.DocumentGroups.Vouchers:
                        let tmpMatchedVoucherForm: TaxDocument.IVoucher[] = [];
                        matchedForms.map((i) => {
                            tmpMatchedVoucherForm.push({
                                bookmark: i.bookmark,
                                formName: i.formName,
                                pageNo: i.pageNo,
                                authorityID: 0,
                                dueDate: undefined,
                                amount: 0,
                                voucherNo: TaxDocument.VoucherNo.None,
                                paymentType: TaxDocument.VoucherTypes.None,
                                customType: TaxDocument.CustomType.None,
                                onlinePaymentUri: "",
                                customText: "",
                                authorityImage: "",
                                authorityName: "",
                                groupId: undefined,
                                isMasterPaymentUrl: false,
                                vocherMode: TaxDocument.VoucherMode.Recognized,
                                isFormNameUpdated: false,
                                formType: TaxDocument.TaxFormType.Voucher,
                                printedDueDate: undefined,
                                voucherDueDateType: DueDateType.SSRDefault
                            });
                        });
                        this.setAuthorityOptions();
                        this.setState({
                            showGroupFilesPreview: true,
                            pages: pages,
                            destinationGroup: destinationGroup,
                            matchedForms: tmpMatchedVoucherForm as TaxDocument.IVoucher[]
                        });
                        break;
                    case TaxDocument.DocumentGroups.EFile:
                        let tmpMatchedForm: TaxDocument.IEFile[] = [];
                        matchedForms.map((i) => {
                            tmpMatchedForm.push({
                                bookmark: i.bookmark,
                                formName: i.formName,
                                pageNo: i.pageNo,
                                signatureControls: [],
                                authorityID: 0,
                                formType: TaxDocument.TaxFormType.EFile
                            });
                        });
                        this.setAuthorityOptions();
                        this.setState({
                            showGroupFilesPreview: true,
                            pages: pages,
                            destinationGroup: destinationGroup,
                            matchedForms: tmpMatchedForm as TaxDocument.IEFile[]
                        });
                        break;
                    case TaxDocument.DocumentGroups.K1:
                        this.shareHolderOption();
                        this.setState({
                            showGroupFilesPreview: true,
                            pages: pages,
                            destinationGroup: destinationGroup,
                            matchedForms: matchedForms as TaxDocument.IK1ShareHolder[],
                            selectedK1Bookmark: 0
                        });
                        break;
                }
            } else {
                VenusNotifier.Warning(Constants.ReGroupConstants.SameSourceAndDestinationError, null);
            }

            callback && callback();
        }
    }

    private updateTaxFormType = (forms: TaxDocument.IFormBase[], destinationGroup: TaxDocument.DocumentGroups) => {
        forms.map((form, index) => {
            switch (destinationGroup) {
                case TaxDocument.DocumentGroups.Transmittals:
                    form.formType = TaxDocument.TaxFormType.Transmittal;
                    break;
                case TaxDocument.DocumentGroups.TaxReturns:
                    form.formType = TaxDocument.TaxFormType.TaxReturn;
                    break;
                case TaxDocument.DocumentGroups.Invoice:
                    form.formType = TaxDocument.TaxFormType.Invoice;
                    break;
                case TaxDocument.DocumentGroups.EFile:
                    form.formType = TaxDocument.TaxFormType.EFile;
                    break;
                case TaxDocument.DocumentGroups.Vouchers:
                    form.formType = TaxDocument.TaxFormType.Voucher;
                    break;
                default:
                    form.formType = TaxDocument.TaxFormType.FormBase;
                    break;
            }
        });
    };

    private reGroup = (pages: number[], forms: TaxDocument.IFormBase[], destinationGroup: TaxDocument.DocumentGroups) => {
        if (this.validateReGropuData(destinationGroup, forms)) {
            if (destinationGroup === TaxDocument.DocumentGroups.K1) {
                this.reGroupTok1(pages, forms, destinationGroup);
            } else {
                this.updateTaxFormType(forms, destinationGroup);

                let clone = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId]?.taxReturn);
                Helper.removeForms(clone, pages);
                let formGroup = clone.formGroups.find((x) => x.group == destinationGroup);
                if (formGroup) {
                    if (formGroup.forms) {
                        formGroup.forms = formGroup.forms.concat(forms);
                    } else {
                        formGroup.forms = forms;
                    }
                } else {
                    clone.formGroups.push({ group: destinationGroup, forms: forms });
                }

                this.sortFormGroup(clone, destinationGroup);
                this.cleanUpFormGroup(clone, TaxDocument.DocumentGroups.K1);
                this.updateTaxDocument(clone);
                if (forms[0] && forms[0].formName === "K1") {
                    this.setState({ isK1BookmarkLoaded: false });
                }
                this.onHideGroupFilesPreview();
            }
        }
    };

    onHideGroupFilesPreview = () => {
        this.setState({ showGroupFilesPreview: false });
    };

    saveAttachments(attachments: TaxDocument.IAttachment[]) {
        if (attachments) {
            let _taxReturn = this.props.taxDocuments[this.props.docId]?.taxReturn;
            _taxReturn.attachments = attachments.slice();
            this.setState({ attachments: attachments }, () => {
                this.updateTaxDocument(_taxReturn);
            });
        }
    }
    removePage = (event: any, pageNo: number) => {
        var pages: number[] = cloneDeep(this.state.pages);
        pages.splice(this.state.pages.indexOf(pageNo), 1);
        var matchedForm = cloneDeep(this.state.matchedForms);
        matchedForm.splice(
            this.state.matchedForms.findIndex((x) => x.pageNo[0] == pageNo),
            1
        );
        this.setState({ showGroupFilesPreview: pages.length == 0 ? false : true, pages: pages, matchedForms: matchedForm });
    };

    sortFormGroup = (taxReturn: ITaxReturn, group: TaxDocument.DocumentGroups) => {
        switch (group) {
            case DocumentGroups.EFile:
                const eFileInfo = new EfileInfoView(taxReturn);
                const efileBookmarks = eFileInfo.getBookmarks(
                    this.props.authorities,
                    taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType
                );
                const efilePages = eFileInfo.getPages(efileBookmarks);
                return eFileInfo.sortGroup(efilePages, DocumentGroups.EFile);
        }
    };
    ///////////////////////////////////////Grouping End//////////////////////////////////////////////////////////

    private getK1Pages = (bookmarkId: number, clientInfo: any) => {
        this.setState({ k1Pages: clientInfo.getK1Pages(bookmarkId) });
    };

    getShareHolderInfo(id: number) {
        return this.props.taxDocuments[this.props.docId]?.taxReturn.formGroups
            .filter((x) => x.group == DocumentGroups.K1)
            .map((i: any) => {
                return i?.shareHolder;
            })
            .filter((y) => y.id == id)[0];
    }

    private K1InstructionModified = (verifyK1Replaced: boolean, isK1Restored: boolean, isK1Replaced?: boolean) => {
        isK1Replaced != undefined
            ? this.setState({ verifyK1Replaced: verifyK1Replaced, isK1Restored: isK1Restored, isK1Replaced: isK1Replaced })
            : this.setState({ verifyK1Replaced: verifyK1Replaced, isK1Restored: isK1Restored });
    };

    private onK1BookmarkClick = (pageTitle: string, id: number) => {
        this.setState({ selectedK1Bookmark: id });
        this.getShareHolderInfo(id);
    };

    private disableSaveClose = (option: boolean): void => {
        this.setState({
            disableSaveClose: option
        });
    };

    private openCarouselView = (group: TaxDocument.DocumentGroups, activePage: number, sortedPageOrder: number[] = []) => {
        this.setCarouselImages(group, activePage, sortedPageOrder);
    };

    private createPageCarousel = () => {
        return (
            <PageCarousel
                currentImage={this.state.pdfCarouselActivePage}
                onClose={() => {
                    this.setState({ showCarousel: false, pdfPages: [] });
                }}
                images={this.state.pdfPages}
                open={this.state.showCarousel}
            />
        );
    };

    private setCarouselImages = (group: TaxDocument.DocumentGroups, activePage: number, sortedPageOrder: number[] = []) => {
        if (this.props.taxDocuments[this.props.docId]) {
            let forms: IFormBase[] = [];

            if (group === TaxDocument.DocumentGroups.K1) {
                this.props.taxDocuments[this.props.docId]?.taxReturn.formGroups
                    .filter((x) => x.group == group)
                    .map((formGroup, i) => {
                        forms = forms.concat(formGroup.forms);
                    });
            } else if (
                group === TaxDocument.DocumentGroups.Deleted &&
                !this.props.companySettings.displaySettingsModel.isEnableInvoice
            ) {
                const invoiceFormGroup = this.props.taxDocuments[this.props.docId]?.taxReturn.formGroups.find(
                    (x) => x.group == TaxDocument.DocumentGroups.Invoice
                );
                if (invoiceFormGroup && invoiceFormGroup.forms) {
                    forms = invoiceFormGroup.forms.sort();
                }

                if (this.props.taxDocuments[this.props.docId]?.taxReturn.formGroups.filter((m) => m.group === group).length > 0) {
                    forms = forms.concat(
                        this.props.taxDocuments[this.props.docId]?.taxReturn.formGroups
                            .find((x) => x.group == group)!
                            .forms.sort()
                    );
                }
            } else {
                forms = this.props.taxDocuments[this.props.docId]?.taxReturn.formGroups
                    .find((x) => x.group == group)!
                    .forms.sort();
            }

            let pages: number[] =
                group === TaxDocument.DocumentGroups.EFile && sortedPageOrder ? sortedPageOrder : forms.map((x) => x.pageNo[0]);
            let documentFacade: IPdfDocumentFacade = this.props.pdfDocuments[this.props.docId].document as IPdfDocumentFacade;

            pages.map((value: number, index: number) => {
                let tmpPdfPage: Promise<PDFPageProxy> = documentFacade.getPage(value);

                tmpPdfPage
                    .then((page: PDFPageProxy) => {
                        let tmpPdfPageSource: Promise<string> = documentFacade.getPageSourceBase64(page);

                        tmpPdfPageSource
                            .then((pageURL: string) => {
                                let title: string =
                                    forms.find((x) => x.pageNo[0] == value)!.formName == undefined
                                        ? " [No Name] ( " + TaxDocument.DocumentGroups[group] + " ) "
                                        : forms.find((x) => x.pageNo[0] == value)!.formName +
                                        " ( " +
                                        TaxDocument.DocumentGroups[group] +
                                        " ) ";

                                this.state.pdfPages.push({
                                    src: pageURL,
                                    pageNo: value,
                                    title: title
                                } as CarouselImage);

                                if (this.state.pdfPages.length == pages.length) {
                                    this.state.pdfPages.sort((a, b) => {
                                        return pages.indexOf(a.pageNo) - pages.indexOf(b.pageNo);
                                    });
                                    this.setState({
                                        pdfPages: this.state.pdfPages,
                                        showCarousel: true,
                                        pdfCarouselActivePage: this.state.pdfPages.findIndex((x) => x.pageNo == activePage)
                                    });
                                }
                            })
                            .catch((error: any) => {
                                console.log(error);
                            });
                    })
                    .catch((error: any) => {
                        console.log(error);
                    });
            });
        }
    };

    /////////////////////////////////////////Finish Modal Popup Start/////////////////////////////////////////////////
    sendToERO() {
        let taxReturn: TaxDocument.ITaxReturn = Object.assign({}, this.props.taxDocuments[this.props.docId]?.taxReturn);
        if (!Helper.validateDocumentLocation(taxReturn)) {
            return;
        }
        let userIndex: number = this.props.users.findIndex((user) => user.id === taxReturn.partnerId);
        taxReturn.documentStatus = TaxDocument.DocumentStatus.REVIEW;
        taxReturn.assignTo = taxReturn.partnerId;
        let ssrUser = this.props.users[userIndex];
        taxReturn.assignedUser.userId = ssrUser.id;
        taxReturn.assignedUser.firstName = ssrUser.firstName;
        taxReturn.assignedUser.lastName = ssrUser.lastName;
        taxReturn.assignedUser.email = ssrUser.emailAddress;
        taxReturn.isModified = true;
        taxReturn.lockedBy = 0;
        taxReturn.documentSettings.documentDisplaySetting.isEnableInvoice =
            this.props.companySettings.displaySettingsModel.isEnableInvoice;
        if (!this.props.companySettings.displaySettingsModel.isWatermarkEnabled) {
            taxReturn.documentSettings.deliverySettings.watermarkId = 0;
        }
        if (!this.state.isBusy && this.validateAttachmentStatus(taxReturn.attachments)) {
            this.setState({ isBusy: true, generateTaxPayer: false }, () => {
                document.body.click();
                if (this.state.isSaveAsDefaultOrder === true) {
                    this.props.updateDocumentGroupOrder(
                        taxReturn.engagementType,
                        taxReturn.documentSettings.deliverySettings.paperReturnSettings.documentOrder
                    );
                }
                logger.trackTrace(`ProcessReturnModal --> sendToERO executes for DocumentGuid: ${taxReturn.documentGuid}`, {
                    DocumentId: taxReturn.id?.toString(),
                    DocumentGuid: taxReturn.documentGuid
                });
                this.updateSMSNotificationDetails(taxReturn);
                const updatedShareHolders: IShareHolder[] = this.checkForUpdatedShareHolderDetails(taxReturn);
                this.props.saveProcessReturnInfo(
                    taxReturn,
                    this.getSubDocuments(),
                    this.state.isK1Replaced,
                    this.state.isK1Restored,
                    this.state.initialMFJValue !== TaxDocument.isMutual(taxReturn),
                    processReturnActionEndPoints.sendToEROAsync,
                    this.callback,
                    undefined,
                    undefined,
                    undefined,
                    SendTaxReturnResources.Prefix + SendTaxReturnResources.SendToEROSignerMenu,
                    updatedShareHolders.length > 0,
                    updatedShareHolders
                );
            });
        }
    }
    sendForReview(selectedUser: number) {
        if (selectedUser === 0) {
            VenusNotifier.Warning(Constants.FinishProcessReturn.ValidationMessage.SelectReviewer, "Error");
        } else {
            let taxReturn: TaxDocument.ITaxReturn = Object.assign({}, this.props.taxDocuments[this.props.docId]?.taxReturn);
            if (!Helper.validateDocumentLocation(taxReturn)) {
                return;
            }
            let userIndex: number = this.props.users.findIndex((user) => user.id === selectedUser);
            taxReturn.documentStatus = TaxDocument.DocumentStatus.REVIEW;
            taxReturn.assignTo = selectedUser;
            let ssrUser = this.props.users[userIndex];
            taxReturn.assignedUser.userId = ssrUser.id;
            taxReturn.assignedUser.firstName = ssrUser.firstName;
            taxReturn.assignedUser.lastName = ssrUser.lastName;
            taxReturn.assignedUser.email = ssrUser.emailAddress;
            taxReturn.isModified = true;
            taxReturn.lockedBy = 0;
            taxReturn.documentSettings.documentDisplaySetting.isEnableInvoice =
                this.props.companySettings.displaySettingsModel.isEnableInvoice;
            if (!this.props.companySettings.displaySettingsModel.isWatermarkEnabled) {
                taxReturn.documentSettings.deliverySettings.watermarkId = 0;
            }
            if (!this.state.isBusy && this.validateAttachmentStatus(taxReturn.attachments)) {
                this.setState({ isBusy: true, generateTaxPayer: false }, () => {
                    document.body.click();
                    if (this.state.isSaveAsDefaultOrder === true) {
                        this.props.updateDocumentGroupOrder(
                            taxReturn.engagementType,
                            taxReturn.documentSettings.deliverySettings.paperReturnSettings.documentOrder
                        );
                    }
                    logger.trackTrace(
                        `ProcessReturnModal --> sendForReview executes for DocumentGuid: ${taxReturn.documentGuid}`,
                        { DocumentId: taxReturn.id?.toString(), DocumentGuid: taxReturn.documentGuid }
                    );
                    this.updateSMSNotificationDetails(taxReturn);
                    const updatedShareHolders: IShareHolder[] = this.checkForUpdatedShareHolderDetails(taxReturn);
                    this.props.saveProcessReturnInfo(
                        taxReturn,
                        this.getSubDocuments(),
                        this.state.isK1Replaced,
                        this.state.isK1Restored,
                        this.state.initialMFJValue !== TaxDocument.isMutual(taxReturn),
                        processReturnActionEndPoints.sendForReviewAsync,
                        this.callback,
                        undefined,
                        undefined,
                        undefined,
                        SendTaxReturnResources.Prefix + SendTaxReturnResources.SendForReviewMenu,
                        updatedShareHolders.length > 0,
                        updatedShareHolders
                    );
                });
            }
        }
    }
    approveForDelivery(selectedUser: number) {
        if (selectedUser === 0) {
            VenusNotifier.Warning(Constants.FinishProcessReturn.StatusMessage.SelectApprover, "Error");
        } else {
            let taxReturn: TaxDocument.ITaxReturn = Object.assign({}, this.props.taxDocuments[this.props.docId]?.taxReturn);
            if (!Helper.validateDocumentLocation(taxReturn)) {
                return;
            }
            let userIndex: number = this.props.users.findIndex((user) => user.id === selectedUser);
            taxReturn.documentStatus = TaxDocument.DocumentStatus.APPROVEDFORDELIVERY;
            taxReturn.assignTo = selectedUser;
            let ssrUser = this.props.users[userIndex];
            taxReturn.assignedUser.userId = ssrUser.id;
            taxReturn.assignedUser.firstName = ssrUser.firstName;
            taxReturn.assignedUser.lastName = ssrUser.lastName;
            taxReturn.assignedUser.email = ssrUser.emailAddress;
            taxReturn.isModified = true;
            taxReturn.lockedBy = 0;
            taxReturn.documentSettings.documentDisplaySetting.isEnableInvoice =
                this.props.companySettings.displaySettingsModel.isEnableInvoice;
            if (!this.props.companySettings.displaySettingsModel.isWatermarkEnabled) {
                taxReturn.documentSettings.deliverySettings.watermarkId = 0;
            }
            if (!this.state.isBusy && this.validateAttachmentStatus(taxReturn.attachments)) {
                this.setState({ isBusy: true, generateTaxPayer: false }, () => {
                    document.body.click();
                    if (this.state.isSaveAsDefaultOrder === true) {
                        this.props.updateDocumentGroupOrder(
                            taxReturn.engagementType,
                            taxReturn.documentSettings.deliverySettings.paperReturnSettings.documentOrder
                        );
                    }
                    logger.trackTrace(
                        `ProcessReturnModal --> approveForDelivery executes for DocumentGuid: ${taxReturn.documentGuid}`,
                        { DocumentId: taxReturn.id?.toString(), DocumentGuid: taxReturn.documentGuid }
                    );
                    this.updateSMSNotificationDetails(taxReturn);
                    const updatedShareHolders: IShareHolder[] = this.checkForUpdatedShareHolderDetails(taxReturn);
                    this.props.saveProcessReturnInfo(
                        taxReturn,
                        this.getSubDocuments(),
                        this.state.isK1Replaced,
                        this.state.isK1Restored,
                        this.state.initialMFJValue !== TaxDocument.isMutual(taxReturn),
                        processReturnActionEndPoints.approveForDeliveryAsync,
                        this.callback,
                        undefined,
                        undefined,
                        undefined,
                        SendTaxReturnResources.Prefix + SendTaxReturnResources.ApproveForDeliveryMenu,
                        updatedShareHolders.length > 0,
                        updatedShareHolders
                    );
                });
            }
        }
    }

    deliverToClient = (resourceID: string = "") => {
        const taxReturn: TaxDocument.ITaxReturn = Object.assign({}, this.props.taxDocuments[this.props.docId]?.taxReturn);
        this.setDefaultOfficeLocation(taxReturn);
        let isValid =
            validateTaxReturn(
                taxReturn,
                this.props.userSettings.settings.useSignatureStamp,
                this.props.company.taxingAuthorities,
                this.props.companySettings
            ) &&
            Helper.validateDocumentLocation(taxReturn) &&
            Helper.validateStateForVoucher(taxReturn);

        if (!isValid) return;

        let model = this.props.taxDocuments[this.props.docId]?.taxReturn;
        if (!this.props.companySettings.userPrivilegeSettingsModel.allowAnyUsertoSendDelivary) {
            if (!this.props.isDeliveryPermissionAssigned) {
                VenusNotifier.Warning("You don't have enough permission to send return for delivery.", null);
                return;
            }
        }

        if (this.deactivatedAuthority.length !== 0) {
            VenusNotifier.Error(Constants.FinishProcessReturn.StatusMessage.SelectDeactivatedDeletedAuthority, "Error");
            return;
        }

        if (this.props.companySettings.displaySettingsModel.isEnableInvoice && !this.validateInvoice(model)) {
            VenusNotifier.Error(Constants.FinishProcessReturn.StatusMessage.DeliverToClientWithoutInvoiceError, null);
            return;
        }

        if (!Helper.validateMailingAddress(model)) {
            VenusNotifier.Warning(Constants.FinishProcessReturn.StatusMessage.MailingAddressMissingError, null);
            return;
        }

        if (!this.state.isBusy && this.validateAttachmentStatus(model.attachments)) {
            model.lockedBy = 0;
            this.setState({ isBusy: true, generateTaxPayer: false }, () => {
                document.body.click();
                model.documentSettings.documentDisplaySetting.isEnableInvoice =
                    this.props.companySettings.displaySettingsModel.isEnableInvoice;
                model.documentSettings.deliverySettings.isDirectDeliveryToTaxCaddy = false;
                model.documentSettings.deliverySettings.deliveryMode = DeliveryMode.ElectronicFiled;
                if (!this.props.companySettings.displaySettingsModel.isWatermarkEnabled) {
                    model.documentSettings.deliverySettings.watermarkId = 0;
                }
                logger.trackTrace(`ProcessReturnModal --> deliverToClient executes for DocumentGuid: ${model.documentGuid}`, {
                    DocumentId: model.id?.toString(),
                    DocumentGuid: model.documentGuid
                });
                this.updateSMSNotificationDetails(taxReturn);
                this.props.resetK1SSNState(taxReturn.id);
                this.props.saveProcessReturnInfo(
                    model,
                    this.getSubDocuments(),
                    this.state.isK1Replaced,
                    this.state.isK1Restored,
                    this.state.initialMFJValue !== TaxDocument.isMutual(model),
                    processReturnActionEndPoints.deliverAsync,
                    this.callback,
                    undefined,
                    undefined,
                    this.state.groupInfo,
                    resourceID
                );
                this.props.clearSelectedRows();
            });
        }
    };

    deliverWithTaxcaddy = (taxCaddyLookupResult: TaxDocument.TaxCaddyLookupResult) => {
        let model = this.props.taxDocuments[this.props.docId]?.taxReturn;
        if (!this.props.companySettings.userPrivilegeSettingsModel.allowAnyUsertoSendDelivary) {
            if (!this.props.isDeliveryPermissionAssigned) {
                VenusNotifier.Warning("You don't have enough permission to send return for delivery.", null);
                return;
            }
        }

        if (this.deactivatedAuthority.length !== 0) {
            VenusNotifier.Error(Constants.FinishProcessReturn.StatusMessage.SelectDeactivatedDeletedAuthority, "Error");
            return;
        }

        if (this.props.companySettings.displaySettingsModel.isEnableInvoice && !this.validateInvoice(model)) {
            VenusNotifier.Error(Constants.FinishProcessReturn.StatusMessage.DeliverToClientWithoutInvoiceError, null);
            return;
        }
        if (!this.props.companySettings.displaySettingsModel.isWatermarkEnabled) {
            model.documentSettings.deliverySettings.watermarkId = 0;
        }
        if (taxCaddyLookupResult.isTaxCaddyLookupAccepted) {
            taxCaddyLookupResult.isTaxCaddyDelivery = true;
            taxCaddyLookupResult.isTaxCaddyLookupAccepted = true;
            taxCaddyLookupResult.taxpayerUniqueId =
                this.props.taxDocuments[
                    this.props.docId
                ]?.taxReturn.taxCaddyLookupResultModel.resultObjectModel?.taxPayerUniqueId;
            taxCaddyLookupResult.taxCaddyTransactionEventdata =
                this.props.taxDocuments[this.props.docId]?.taxReturn.taxCaddyLookupResultModel.resultObjectModel;
            if (!this.state.isBusy && this.validateAttachmentStatus(model.attachments)) {
                model.lockedBy = 0;
                this.setState({ isBusy: true, generateTaxPayer: false }, () => {
                    document.body.click();
                    if (this.props.companySettings.deliverySettingsModel.isDirectDeliveryToTaxCaddy) {
                        this.props.taxDocuments[
                            this.props.docId
                        ].taxReturn.documentSettings.deliverySettings.isDirectDeliveryToTaxCaddy = true;
                    } else {
                        this.props.taxDocuments[
                            this.props.docId
                        ].taxReturn.documentSettings.deliverySettings.isDirectDeliveryToTaxCaddy = false;
                    }
                    this.props.taxDocuments[this.props.docId].taxReturn.documentSettings.deliverySettings.deliveryMode =
                        DeliveryMode.ElectronicFiled;
                    this.props.taxDocuments[this.props.docId].taxReturn.documentSettings.documentDisplaySetting.isEnableInvoice =
                        this.props.companySettings.displaySettingsModel.isEnableInvoice;
                    logger.trackTrace(
                        `ProcessReturnModal --> deliverWithTaxcaddy executes for DocumentGuid: ${model.documentGuid}`,
                        {
                            DocumentId: model.id?.toString(),
                            DocumentGuid: model.documentGuid,
                            IsDirectDeliveryToTaxCaddy: this.props.companySettings.deliverySettingsModel
                                .isDirectDeliveryToTaxCaddy
                                ? "True"
                                : "False"
                        }
                    );
                    this.updateSMSNotificationDetails(model);
                    this.props.saveProcessReturnInfo(
                        model,
                        this.getSubDocuments(),
                        this.state.isK1Replaced,
                        this.state.isK1Restored,
                        this.state.initialMFJValue !== TaxDocument.isMutual(model),
                        processReturnActionEndPoints.deliverAsync,
                        this.callback,
                        taxCaddyLookupResult,
                        undefined,
                        undefined,
                        SendTaxReturnResources.Prefix + SendTaxReturnResources.DeliverWithTaxCaddyMenu
                    );
                    this.props.clearSelectedRows();
                });
            }
        } else {
            if (!this.state.isBusy && this.validateAttachmentStatus(model.attachments)) {
                this.setState({ isBusy: true, generateTaxPayer: false }, () => {
                    document.body.click();
                    this.props.taxDocuments[
                        this.props.docId
                    ].taxReturn.documentSettings.deliverySettings.isDirectDeliveryToTaxCaddy = false;
                    this.props.taxDocuments[this.props.docId].taxReturn.documentSettings.documentDisplaySetting.isEnableInvoice =
                        this.props.companySettings.displaySettingsModel.isEnableInvoice;
                    logger.trackTrace(
                        `ProcessReturnModal --> deliverWithTaxcaddy executes for DocumentGuid: ${model.documentGuid}`,
                        {
                            DocumentId: model.id?.toString(),
                            DocumentGuid: model.documentGuid,
                            IsDirectDeliveryToTaxCaddy: "False"
                        }
                    );
                    this.updateSMSNotificationDetails(this.props.taxDocuments[this.props.docId]?.taxReturn);
                    this.props.saveProcessReturnInfo(
                        this.props.taxDocuments[this.props.docId]?.taxReturn,
                        this.getSubDocuments(),
                        this.state.isK1Replaced,
                        this.state.isK1Restored,
                        this.state.initialMFJValue !== TaxDocument.isMutual(model),
                        processReturnActionEndPoints.deliverAsync,
                        this.callback,
                        undefined,
                        undefined,
                        undefined,
                        SendTaxReturnResources.Prefix + SendTaxReturnResources.DeliverWithTaxCaddyMenu
                    );
                });
            }
        }
    };
    generateTaxPayerView = (clientType: ClientTypes) => {
        this.setState({ disabledTaxpayerPreview: true });

        let taxReturn: TaxDocument.ITaxReturn = Object.assign({}, this.props.taxDocuments[this.props.docId]?.taxReturn);

        const isGroupedReturn = taxReturn.groupId > 0 || this.state.groupInfo?.name.trim().length > 0;

        if (!isGroupedReturn) {
            this.setDefaultOfficeLocation(taxReturn);
            if (!Helper.validateDocumentLocation(taxReturn)) {
                this.setState({ disabledTaxpayerPreview: false });
                return;
            }
        }

        if (!Helper.validateMailingAddress(taxReturn)) {
            this.setState({ disabledTaxpayerPreview: false });
            VenusNotifier.Warning(Constants.FinishProcessReturn.StatusMessage.MailingAddressMissingError, null);
            return;
        }

        let isValid =
            Helper.validateStateForVoucher(taxReturn) &&
            validateTaxReturn(
                taxReturn,
                this.props.userSettings.settings.useSignatureStamp,
                this.props.company.taxingAuthorities,
                this.props.companySettings
            );
        if (isValid) {
            taxReturn.isModified = true;
            if (!taxReturn.documentSettings.deliverySettings.contactPerson) {
                taxReturn.documentSettings.deliverySettings.contactPerson = 0;
            }
            taxReturn.documentSettings.documentDisplaySetting.isEnableInvoice =
                this.props.companySettings.displaySettingsModel.isEnableInvoice;
            if (!this.props.companySettings.displaySettingsModel.isWatermarkEnabled) {
                taxReturn.documentSettings.deliverySettings.watermarkId = 0;
            }
            this.setState({ generateTaxPayer: true }, () => {
                logger.trackTrace(
                    `ProcessReturnModal --> generateTaxPayerView executes for DocumentGuid: ${taxReturn.documentGuid}`,
                    { DocumentId: taxReturn.id?.toString(), DocumentGuid: taxReturn.documentGuid }
                );
                this.updateSMSNotificationDetails(taxReturn);

                taxReturn.locationId = isGroupedReturn ? undefined : taxReturn.locationId;

                this.props.saveProcessReturnInfo(
                    taxReturn,
                    this.getSubDocuments(),
                    this.state.isK1Replaced,
                    this.state.isK1Restored,
                    this.state.initialMFJValue !== TaxDocument.isMutual(taxReturn),
                    processReturnActionEndPoints.generateTaxpayerViewAsync,
                    this.callback,
                    undefined,
                    clientType,
                    undefined,
                    this.props.parentResourceIdentifier + SendTaxReturnResources.ClientView
                );
            });
        } else {
            this.setState({ disabledTaxpayerPreview: false });
        }
    };
    setPageBusy = (isBusy: boolean, processReturnTabs: ProcessReturnTabs) => {
        switch (processReturnTabs) {
            case ProcessReturnTabs.Attachments: {
                if (isBusy) {
                    this.setState({ disabledTaxpayerPreview: true });
                } else {
                    this.setState({ disabledTaxpayerPreview: false });
                }
            }
        }
    };

    onPrintForPaperDelivery = () => {
        let model = this.props.taxDocuments[this.props.docId]?.taxReturn;

        if (this.deactivatedAuthority.length !== 0) {
            VenusNotifier.Error(Constants.FinishProcessReturn.StatusMessage.SelectDeactivatedDeletedAuthority, "Error");
            return;
        }

        if (this.props.companySettings.displaySettingsModel.isEnableInvoice && !this.validateInvoice(model)) {
            VenusNotifier.Error(Constants.FinishProcessReturn.StatusMessage.DeliverToClientWithoutInvoiceError, null);
            return;
        }

        if (!this.state.isBusy && this.validateAttachmentStatus(model.attachments)) {
            model.lockedBy = 0;
            this.setState({ isBusy: true, generateTaxPayer: false }, () => {
                document.body.click();
                if (this.state.isSaveAsDefaultOrder === true) {
                    this.props.updateDocumentGroupOrder(
                        model.engagementType,
                        model.documentSettings.deliverySettings.paperReturnSettings.documentOrder
                    );
                }
                model.documentSettings.deliverySettings.isDirectDeliveryToTaxCaddy = false;
                model.documentSettings.deliverySettings.deliveryMode = DeliveryMode.PaperFiled;
                model.documentSettings.documentDisplaySetting.isEnableInvoice =
                    this.props.companySettings.displaySettingsModel.isEnableInvoice;
                if (!this.props.companySettings.displaySettingsModel.isWatermarkEnabled) {
                    model.documentSettings.deliverySettings.watermarkId = 0;
                }
                logger.trackTrace(
                    `ProcessReturnModal --> onPrintForPaperDelivery executes for DocumentGuid: ${model.documentGuid}`,
                    { DocumentId: model.id?.toString(), DocumentGuid: model.documentGuid }
                );
                this.updateSMSNotificationDetails(model);
                this.props.saveProcessReturnInfo(
                    model,
                    this.getSubDocuments(),
                    this.state.isK1Replaced,
                    this.state.isK1Restored,
                    this.state.initialMFJValue !== TaxDocument.isMutual(model),
                    processReturnActionEndPoints.deliverAsync,
                    this.callback,
                    undefined,
                    undefined,
                    undefined,
                    SendTaxReturnResources.Prefix + SendTaxReturnResources.DownloadPDFMenu
                );
                this.props.clearSelectedRows();
            });
        }
    };
    /////////////////////////////////////////Finish Modal Popup End/////////////////////////////////////////////////

    private getSubDocuments() {
        let documentFacade: IPdfDocumentFacade = this.props.pdfDocuments[this.props.docId].document as IPdfDocumentFacade;
        let subDocuments: ISubDocument[] = [];
        if (typeof documentFacade !== "undefined") {
            subDocuments = documentFacade.getSubDocuments();
        }
        return subDocuments;
    }

    private callback = (data?: any) => {
        if (this.state.generateTaxPayer) {
            Helper.openWindowWithPostRequest(
                data.url,
                getRequestVerificationToken(),
                "CPAToken",
                Constants.PreviewConstants.Scope.ClientPreview
            );
            //Set generateTaxPayer false, otherwise it will open empty tab in other scenario's callback
            this.setState({
                generateTaxPayer: false,
                disabledTaxpayerPreview: false,
                isK1Replaced: false,
                verifyK1Replaced: false
            });
        } else {
            const taxReturn = this.props.taxDocuments[this.props.docId]?.taxReturn;
            this.onClose(taxReturn.id, true);
            if (this.state.groupInfo.id > 0 || this.state.groupInfo.name || taxReturn.groupId > 0) {
                this.props.refreshAssignmentPage();
            }
        }

        const isGroupedReturn = this.props.taxDocuments[this.props.docId]?.taxReturn.groupId > 0;

        if (!isGroupedReturn && !this.isUserHasAccessToDocumentLocation()) {
            let taxDocumentsInfo: ITaxDocumentViewModel[] = [];
            taxDocumentsInfo.push({
                documentId: this.props.docId,
                isGroupedReturn: this.props.taxDocuments[this.props.docId].taxReturn.groupId > 0,
                groupId: this.props.taxDocuments[this.props.docId].taxReturn.groupId
            } as ITaxDocumentViewModel);

            let accessMaps: ITaxDocumentAccessViewModel = {
                taxDocuments: taxDocumentsInfo,
                userGroups: [],
                users: [],
                documents: []
            };

            this.props.setTaxDocumentsAccessMaps(
                accessMaps,
                () => {
                    this.props.refreshAssignmentPage();
                },
                SendTaxReturnResources.GridButtonPrefix + SendTaxReturnResources.HeaderSetAccessButton
            );
        }
        //Update store on any save operation in process return window
        const taxReturn = this.props.taxDocuments[this.props.docId]?.taxReturn;
        this.props.requestTaxDocument(taxReturn?.id, true, false, undefined, undefined, undefined, taxReturn?.groupId > 0);
        this.props.loadPdf(taxReturn, true);
        this.props.updateGroupInfoState(true);
    };

    private onClose = (taxReturnID: number, fromCallback: boolean = false) => {
        if (!fromCallback) {
            this.resetTaxReturnLockStatus(this.props.taxDocuments[taxReturnID]?.taxReturn);
        }
        this.props.onCancel(taxReturnID);
    };

    private onModalClose = (taxReturnID: number) => {
        this.resetTaxReturnLockStatus(this.state.processingTaxReturn || this.props.taxDocuments[taxReturnID]?.taxReturn);
        this.props.onCancel(taxReturnID);
    };

    private setDefaultOfficeLocation = (taxReturn: TaxDocument.ITaxReturn) => {
        if (this.props.locationDropdown && this.props.locationDropdown.length === 1) {
            taxReturn.locationId = this.props.locationDropdown[0].value;
            this.updateTaxDocument(taxReturn);
        }
    };

    private onSaveTaxReturn = () => {
        this.setState({
            refreshTaxReturn: true,
            selectedOption: { save: true, finish: false }
        });

        let taxReturn: TaxDocument.ITaxReturn = this.props.taxDocuments[this.props.docId]?.taxReturn;

        const groupName = this.state.groupInfo?.name.trim();

        this.props.requestDocumentStatus(
            this.props.docId,
            () => {
                groupName.length > 0 || taxReturn.groupId > 0 || this.setDefaultOfficeLocation(taxReturn);
                if (!Helper.isProcessable(taxReturn)) {
                    VenusNotifier.Error(Constants.ProcessReturnConstants.ReturnAlreadyProcessed, "");
                    return;
                }

                if (!Helper.validateMailingAddress(taxReturn)) {
                    VenusNotifier.Warning(Constants.FinishProcessReturn.StatusMessage.MailingAddressMissingError, null);
                    return;
                }

                if (
                    Helper.validateClientInfo(taxReturn) &&
                    validateRefudInfo(taxReturn, this.props.authorities) &&
                    validateRetentionPeriod(taxReturn) &&
                    Helper.validateDeliveryOption(taxReturn, this.props.companySettings) &&
                    Helper.validatePaperFile(taxReturn.paperFiles, this.props.getAllTaxingAuthorities) &&
                    Helper.validateK1(taxReturn) &&
                    Helper.validateStateForVoucher(taxReturn) &&
                    (groupName.length > 0 || taxReturn.groupId > 0 || Helper.validateDocumentLocation(taxReturn))
                ) {
                    this.assignReturnOnOpen(taxReturn);
                    if (
                        taxReturn.documentStatus !== TaxDocument.DocumentStatus.REVIEW &&
                        taxReturn.documentStatus !== TaxDocument.DocumentStatus.READYFORDELIVERY
                    ) {
                        taxReturn.documentStatus = TaxDocument.DocumentStatus.PROCESSING;
                    }
                    taxReturn.lockedBy = 0;
                    taxReturn.isModified = true;
                    taxReturn.locationId = groupName.length > 0 || taxReturn.groupId > 0 ? undefined : taxReturn.locationId;
                    taxReturn.documentSettings.documentDisplaySetting.isEnableInvoice =
                        this.props.companySettings.displaySettingsModel.isEnableInvoice;
                    if (!this.props.companySettings.displaySettingsModel.isWatermarkEnabled) {
                        taxReturn.documentSettings.deliverySettings.watermarkId = 0;
                    }
                    if (
                        groupName &&
                        groupName.length > 0 &&
                        taxReturn.documentSettings.deliverySettings.deliverTo == ClientTypes.Spouse
                    ) {
                        taxReturn.documentSettings.deliverySettings.deliverTo = ClientTypes.Taxpayer;
                    }

                    if (
                        groupName &&
                        groupName.length > 0 &&
                        taxReturn.documentSettings.deliverySettings.deliverTo == ClientTypes.Spouse
                    ) {
                        taxReturn.documentSettings.deliverySettings.deliverTo = ClientTypes.Taxpayer;
                    } else if (
                        taxReturn.groupId > 0 &&
                        taxReturn.documentSettings.deliverySettings.deliverTo == ClientTypes.Spouse
                    ) {
                        taxReturn.documentSettings.deliverySettings.deliverTo = ClientTypes.Taxpayer;
                    }

                    let efileInfo = taxReturn.formGroups.filter((x) => x.group === DocumentGroups.EFile)[0];
                    if (
                        efileInfo?.forms.length === 0 &&
                        taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType !==
                        SignatureType.ManualSign &&
                        (this.state.markAsReadyForDelivery || this.getMarkAsDeliveryStatus(taxReturn))
                    ) {
                        VenusNotifier.Error(Constants.FinishProcessReturn.StatusMessage.MarkAsDeliverWithoutEfileError, null);
                        this.setState({ markAsReadyForDelivery: false, markAsReadyForDeliveryChanged: false });
                        return;
                    }
                    if (
                        (this.state.markAsReadyForDelivery || this.getMarkAsDeliveryStatus(taxReturn)) &&
                        !validateTaxReturn(
                            taxReturn,
                            this.props.userSettings.settings.useSignatureStamp,
                            this.props.authorities,
                            this.props.companySettings,
                            true,
                            true
                        )
                    ) {
                        return;
                    }

                    if (this.state.markAsReadyForDeliveryChanged) {
                        if (
                            this.state.markAsReadyForDelivery &&
                            this.props.companySettings.displaySettingsModel.isEnableInvoice &&
                            !this.validateInvoice(taxReturn)
                        ) {
                            VenusNotifier.Error(
                                Constants.FinishProcessReturn.StatusMessage.DeliverToClientWithoutInvoiceError,
                                null
                            );
                            taxReturn.documentStatus = TaxDocument.DocumentStatus.PROCESSING;
                            this.setState({
                                markAsReadyForDelivery: false
                            });
                            return;
                        } else {
                            taxReturn.documentStatus = this.state.markAsReadyForDelivery
                                ? TaxDocument.DocumentStatus.READYFORDELIVERY
                                : TaxDocument.DocumentStatus.PROCESSING;
                        }
                    }

                    if (!this.state.isBusy && this.validateAttachmentStatus(taxReturn.attachments)) {
                        if (taxReturn.groupId > 0 || groupName.length > 0 ) {
                            this.handleProcessReturnSave();
                        }
                        else {
                            if (this.isUserHasAccessToDocumentLocation()) {
                                this.setState({ isBusy: true, generateTaxPayer: false }, () => {
                                    compareClientInfo(
                                        getNewClientInfo(
                                            this.props.taxDocuments[this.props.docId]?.taxReturn,
                                            this.state.clientBasicInfoId,
                                            this.state.clientInfo,
                                            this.getLocationName()
                                        ),
                                        this.setClientInfoComparisonResult,
                                        this.onSecondaryButtonClick,
                                        this.newClientCallback,
                                        this.onAutoCMUpdate,
                                        this.setClientBasicInfoId,
                                        (oldClientId: string) => {
                                            this.setState({ oldClientId: oldClientId });
                                        }
                                    );
                                });
                            } else {
                                this.setState({ showLocationMismatchActionModel: true });
                            }
                        }
                    }
                }
            },
            taxReturn.groupId > 0
        );
    };

    private setClientInfoComparisonResult = (clientInfoComparisonResult: IClientInfoComparisonResult) => {
        this.setState({ clientInfoComparisonResult }, () => {
            this.setState({ showMismatchModal: true, popupInProgress: false });
        });
    };

    private setClientBasicInfoId = (id: number) => {
        this.setState({ clientBasicInfoId: id });
    };

    private onPrimaryButtonClick = () => {
        if (this.state.clientInfoComparisonResult.comparisonStatus === IClientInfoMismatchType.NewClient) {
            this.addNewClientInCM();
        } else {
            this.editClientInfoInCM();
        }

        this.setState({ showMismatchModal: false, popupInProgress: true, isBusy: true });
    };

    private onSecondaryButtonClick = () => {
        this.handleProcessReturnSave();
    };

    private handleProcessReturnSave = () => {
        this.setState({
            showMismatchModal: false,
            popupInProgress: false,
            isBusy: true
        });
        if (this.state.selectedOption.save) {
            this.saveProcessReturnInfo();
        } else if (this.state.selectedOption.finish) {
            this.finishProcessReturn();
        }
    };

    private onAutoCMUpdate = () => {
        this.setState({
            showMismatchModal: false,
            popupInProgress: false,
            isBusy: true
        });
        this.editClientInfoInCM();
    };

    private editClientInfoInCM = () => {
        const clientInfo = getNewClientInfo(
            this.props.taxDocuments[this.props.docId]?.taxReturn,
            this.state.clientBasicInfoId,
            this.state.clientInfo,
            this.getLocationName()
        );
        if (this.state.selectedOption.save) {
            editClientInfo(clientInfo, this.saveProcessReturnInfo);
        } else if (this.state.selectedOption.finish) {
            editClientInfo(clientInfo, this.finishProcessReturn);
        }

        if (
            this.state.clientInfoComparisonResult.comparisonStatus === IClientInfoMismatchType.DataMismatch ||
            this.state.clientInfoComparisonResult.comparisonStatus === IClientInfoMismatchType.SSNMismatch ||
            this.state.clientInfoComparisonResult.comparisonStatus === IClientInfoMismatchType.ClientIdMismatch
        ) {
            const clientInfoResultData = this.state.clientInfoComparisonResult.clientInfoResultData;
            const propertiesWithDifference = Object.keys(clientInfoResultData).reduce((result, property) => {
                if (clientInfoResultData[property] && clientInfoResultData[property].hadDifference === true) {
                    result[property] = clientInfoResultData[property].taxReturnValue;
                }
                return result;
            }, {});

            propertiesWithDifference["ProductType"] = "Returns";
            logger.trackTrace("Client-Info Updated", propertiesWithDifference);
        }
    };

    private addNewClientInCM = () => {
        const clientInfo = getNewClientInfo(
            this.props.taxDocuments[this.props.docId]?.taxReturn,
            this.state.clientBasicInfoId,
            this.state.clientInfo,
            this.getLocationName()
        );
        if (this.state.selectedOption.save) {
            addNewClientInfo(clientInfo, this.saveProcessReturnInfo);
        } else if (this.state.selectedOption.finish) {
            addNewClientInfo(clientInfo, this.finishProcessReturn);
        }
    };

    private closeMismatchModal = () => {
        this.setState({ isBusy: false, showMismatchModal: false, popupInProgress: false });
    };

    private onCloseLocationMismatchActionModal = () => {
        this.setState({ isBusy: false, showLocationMismatchActionModel: false, popupInProgress: false });
    };

    private onContinueLocationMismatchActionModal = () => {
        this.setState({ showLocationMismatchActionModel: false }, () => {
            compareClientInfo(
                getNewClientInfo(
                    this.props.taxDocuments[this.props.docId]?.taxReturn,
                    this.state.clientBasicInfoId,
                    this.state.clientInfo,
                    this.getLocationName()
                ),
                this.setClientInfoComparisonResult,
                this.onSecondaryButtonClick,
                this.newClientCallback,
                this.onAutoCMUpdate,
                this.setClientBasicInfoId
            );
        });
    };

    private newClientCallback = (clientInfo: TaxDocument.IClientInfoViewModel) => {
        switch (this.props.clientManagementSetting.reconcileType) {
            case ReconcileType.Ignore: {
                this.onSecondaryButtonClick();
                break;
            }
            case ReconcileType.Add: {
                this.setState(
                    {
                        showMismatchModal: false,
                        popupInProgress: false,
                        isBusy: true
                    },
                    () => this.addNewClientInCM()
                );
                break;
            }
            default: {
                this.setClientInfoComparisonResult(setNewClientInfoComparisonResult(clientInfo));
                break;
            }
        }
    };

    private updateSMSNotificationDetails = (taxReturn: TaxDocument.ITaxReturn) => {
        const dataToUpdateSMSNotificationDetails = {
            oldClientId: this.state.oldClientId.length > 0 ? this.state.oldClientId : null,
            documentId: this.props.docId,
            clientId: taxReturn.clientId,
            tpDetails: {
                countryCode: TaxDocument.isPartnership(taxReturn)
                    ? taxReturn.partnership?.countryCode && taxReturn.partnership?.countryCode.length > 0
                        ? taxReturn.partnership?.countryCode
                        : null
                    : taxReturn.taxpayer?.countryCode && taxReturn.taxpayer?.countryCode.length > 0
                        ? taxReturn.taxpayer?.countryCode
                        : null,
                mobileNumber: TaxDocument.isPartnership(taxReturn)
                    ? taxReturn.partnership.mobileNumber && taxReturn.partnership.mobileNumber.length > 0
                        ? taxReturn.partnership.mobileNumber
                        : null
                    : taxReturn.taxpayer?.mobileNumber && taxReturn.taxpayer?.mobileNumber.length > 0
                        ? taxReturn.taxpayer?.mobileNumber
                        : null,
                isOpted: this.state.isTPOpted
            },
            spouseDetails: TaxDocument.isPartnership(taxReturn)
                ? null
                : !TaxDocument.isMutual(taxReturn)
                    ? null
                    : {
                        countryCode:
                            taxReturn.spouse?.countryCode && taxReturn.spouse?.countryCode.length > 0
                                ? taxReturn.spouse?.countryCode
                                : null,
                        mobileNumber:
                            taxReturn.spouse?.mobileNumber && taxReturn.spouse?.mobileNumber.length > 0
                                ? taxReturn.spouse?.mobileNumber
                                : null,
                        isOpted: this.state.isSpouseOpted
                    }
        };
        this.state.showSMSTextNotifications && this.props.updateSMSNotificationDetails(dataToUpdateSMSNotificationDetails);
    };

    private saveProcessReturnInfo = () => {
        let taxReturn: TaxDocument.ITaxReturn = Object.assign({}, this.props.taxDocuments[this.props.docId]?.taxReturn);
        logger.trackTrace(
            `Saving process return info for document ${taxReturn.documentGuid} ,DocumentStatus: ${taxReturn.documentStatus}`,
            {
                DocumentId: taxReturn.id?.toString(),
                DocumentGuid: taxReturn.documentGuid,
                DocumentStatus: taxReturn.documentStatus
            }
        );

        taxReturn.groupName =
            this.state.groupInfo.name.trim().length > 0 ? this.state.groupInfo.name.trim() : taxReturn.groupName;

        const isGroupedReturn = taxReturn.groupId > 0;

        if (!isGroupedReturn && !this.isUserHasAccessToDocumentLocation()) {
            logger.trackTrace(
                `Changing the Document status from ${taxReturn.documentStatus} to Ready state as user doesnot have access to document location`,
                {
                    DocumentId: taxReturn.id?.toString(),
                    DocumentGuid: taxReturn.documentGuid
                }
            );
            taxReturn.assignTo = undefined;
            taxReturn.documentStatus = TaxDocument.DocumentStatus.READY;
        }

        const updatedShareHolders: IShareHolder[] = this.checkForUpdatedShareHolderDetails(taxReturn);
        this.updateSMSNotificationDetails(taxReturn);
        taxReturn.locationId =
            this.state.groupInfo?.name.trim().length > 0 || taxReturn.groupId > 0 ? undefined : taxReturn.locationId;
        if (taxReturn.groupId > 0) {
            this.props.saveGroupedProcessReturnInfo(
                taxReturn,
                this.getSubDocuments(),
                this.getSelectedReturnsForDelivery(),
                this.state.isK1Replaced,
                this.state.isK1Restored,
                this.state.initialMFJValue !== TaxDocument.isMutual(taxReturn),
                undefined,
                this.callback,
                undefined,
                this.props.groupData,
                undefined,
                updatedShareHolders.length > 0,
                updatedShareHolders
            );
        } else {
            this.props.saveProcessReturnInfo(
                taxReturn,
                this.getSubDocuments(),
                this.state.isK1Replaced,
                this.state.isK1Restored,
                this.state.initialMFJValue !== TaxDocument.isMutual(taxReturn),
                undefined,
                this.callback,
                undefined,
                undefined,
                taxReturn.groupId > 0 ? this.props.groupData : this.state.groupInfo,
                "",
                updatedShareHolders.length > 0,
                updatedShareHolders
            );
        }
        this.setState({ isBusy: false });
    };

    private checkForUpdatedShareHolderDetails = (taxReturn: TaxDocument.ITaxReturn) => {
        const isShareHolderUpdated = verifyIfShareHolderInfoUpdated(this.state.shareHolderDetails, taxReturn);
        let updatedShareHolders: IShareHolder[] = [];
        if (isShareHolderUpdated) {
            updatedShareHolders = getUpdatedShareHolders(this.state.shareHolderDetails, taxReturn);
            this.props.resetK1SSNState(taxReturn.id);
            if (this.props.pageWiseSSNDetails[taxReturn.id] != undefined) {
                let k1Forms: IFormBase[] = [];
                forEach(updatedShareHolders, (k1) => {
                    taxReturn.formGroups
                        .filter((x) => x.group == DocumentGroups.K1)
                        .map((i: any) => {
                            if (i?.shareHolder.id === k1.id) {
                                k1Forms.push(i?.forms);
                            }
                        });
                });
                k1Forms = k1Forms.flat();
                const pages = k1Forms.map((forms) => forms.pageNo[0]);
                this.props.resetPageWiseK1SSNState(taxReturn.id, pages);
            }
        }
        return updatedShareHolders;
    };

    private isUserHasAccessToDocumentLocation = () => {
        const taxReturn: TaxDocument.ITaxReturn = Object.assign({}, this.props.taxDocuments[this.props.docId]?.taxReturn);
        const index = this.props.loggedInUserLocations.findIndex((x) => x == taxReturn.locationId);
        return index != NO_INDEX;
    };

    private finishProcessReturn = () => {
        let taxReturn: TaxDocument.ITaxReturn = Object.assign({}, this.props.taxDocuments[this.props.docId]?.taxReturn);
        
        let isValid = validateTaxReturn(
            taxReturn,
            this.props.userSettings.settings.useSignatureStamp,
            this.props.company.taxingAuthorities,
            this.props.companySettings,
            taxReturn.groupId > 0
        ) && Helper.validatePartner(taxReturn);

        isValid &&
            this.setState((prevState) => {
                return { showClosePopover: !prevState.showClosePopover };
            });
        if (
            Helper.isIndividualEnagagementType(taxReturn.engagementType) &&
            this.props.companySettings.reportingChannelSettings.filter(
                (r) => r.reportingEngine === ReportingEngineType.TaxCaddy && r.enabled == true
            ).length > 0
        ) {
            if (
                TaxDocument.isIndividual(this.props.taxDocuments[this.props.docId]?.taxReturn) ||
                TaxDocument.isMutual(this.props.taxDocuments[this.props.docId]?.taxReturn)
            ) {
                let taxReturn = this.props.taxDocuments[this.props.docId]?.taxReturn as TaxDocument.IIndividualTaxReturn;
                this.props.getTaxCaddyLookupDetails(
                    taxReturn.taxpayer.name,
                    taxReturn.taxpayer.email,
                    taxReturn.taxpayer.ssn ? taxReturn.taxpayer.ssn.replace(/[^0-9]/g, "").trim() : "",
                    taxReturn.taxYear,
                    taxReturn.clientId,
                    taxReturn.id
                );
            }
        }
        this.setState({ isBusy: false });
    };

    private onFinishProcessing = () => {
        this.setState({
            refreshTaxReturn: true,
            selectedOption: { save: false, finish: true }
        });

        const taxReturn: TaxDocument.ITaxReturn = Object.assign({}, this.props.taxDocuments[this.props.docId]?.taxReturn);

        const isGroupedReturn = taxReturn.groupId > 0 || this.state.groupInfo?.name.trim().length > 0;
        if (!isGroupedReturn) {
            this.setDefaultOfficeLocation(taxReturn);
            if (!Helper.validateDocumentLocation(taxReturn)) {
                return;
            }
        }

        let isValid =
            validateTaxReturn(
                taxReturn,
                this.props.userSettings.settings.useSignatureStamp,
                this.props.company.taxingAuthorities,
                this.props.companySettings,
                taxReturn.groupId > 0
            ) && Helper.validateStateForVoucher(taxReturn);

        if (isValid) {
            if (taxReturn.groupId > 0) {
                this.setState(
                    {
                        refreshTaxReturn: true,
                        selectedOption: { save: false, finish: true }
                    },
                    () => this.handleProcessReturnSave()
                );
            } else if (this.isUserHasAccessToDocumentLocation()) {
                compareClientInfo(
                    getNewClientInfo(
                        this.props.taxDocuments[this.props.docId]?.taxReturn,
                        this.state.clientBasicInfoId,
                        this.state.clientInfo,
                        this.getLocationName()
                    ),
                    this.setClientInfoComparisonResult,
                    this.onSecondaryButtonClick,
                    this.newClientCallback,
                    this.onAutoCMUpdate,
                    this.setClientBasicInfoId,
                    (oldClientId: string) => {
                        this.setState({ oldClientId: oldClientId });
                    }
                );
            } else {
                this.setState({ showLocationMismatchActionModel: true });
            }
        }
    };

    private getLocationName = () => {
        const index = this.props.locationDropdown.findIndex(
            (x) => x.value == this.props.taxDocuments[this.props.docId]?.taxReturn.locationId
        );
        if (index != NO_INDEX) {
            return this.props.locationDropdown[index].name;
        }
        return "";
    };

    private assignReturnOnOpen = (taxReturn: TaxDocument.ITaxReturn) => {
        if (taxReturn.assignTo == 0 || taxReturn.assignTo == undefined) {
            let userIndex: number = this.props.users.findIndex((user) => user.id === this.props.userBasicProfile?.userId!);
            taxReturn.assignTo = this.props.userBasicProfile?.userId!;
            let ssrUser = this.props.users[userIndex];
            taxReturn.assignedUser.userId = ssrUser.id;
            taxReturn.assignedUser.firstName = ssrUser.firstName;
            taxReturn.assignedUser.lastName = ssrUser.lastName;
            taxReturn.assignedUser.email = ssrUser.emailAddress;
        }
    };

    private deletePages = (pages: number[]) => {
        let clone = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId]?.taxReturn);
        let deleted = Helper.removeForms(clone, pages);

        let deletedFormGroupIndex = new ClientInfoView(clone).getFormIndex(TaxDocument.DocumentGroups.Deleted);

        if (clone.formGroups[deletedFormGroupIndex]) {
            clone.formGroups[deletedFormGroupIndex].forms = clone.formGroups[deletedFormGroupIndex].forms.concat(deleted);
        } else {
            clone.formGroups.push({ group: TaxDocument.DocumentGroups.Deleted, forms: deleted });
        }

        this.updateTaxDocument(clone);
    };

    private deleteRefundOrPayment = (pages: number[]) => {
        let clone = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId]?.taxReturn);
        let deletedForms = Helper.removeForms(clone, pages);

        let tmpForms: TaxDocument.IFormBase[] = [];
        deletedForms.map((i) => {
            tmpForms.push({
                bookmark: i.bookmark,
                formName: i.formName,
                pageNo: i.pageNo,
                formType: TaxDocument.TaxFormType.TaxReturn
            });
        });

        let formGroupIndex = new ClientInfoView(clone).getFormIndex(TaxDocument.DocumentGroups.TaxReturns);
        if (clone.formGroups[formGroupIndex]) {
            clone.formGroups[formGroupIndex].forms = clone.formGroups[formGroupIndex].forms.concat(tmpForms);
        } else {
            clone.formGroups.push({ group: TaxDocument.DocumentGroups.TaxReturns, forms: tmpForms });
        }

        this.updateTaxDocument(clone);
    };

    //Undo/Redo section
    //-------------------------------
    private undoState: TaxDocument.ITaxReturn[];
    private undoTop: number;

    private updateTaxDocument(model: TaxDocument.ITaxReturn) {
        this.undoTop++;
        this.undoState[this.undoTop] = model;
        //Trim off the tail if any
        this.undoState = this.undoState.splice(0, this.undoTop + 1);
        this.props.updateTaxDocument(this.undoState[this.undoTop]);
    }

    private unDo() {
        if (this.undoTop > 0) {
            this.undoTop--;

            this.props.updateTaxDocument(this.undoState[this.undoTop]);
        }
    }

    private reDo() {
        if (this.undoTop + 1 < this.undoState.length) {
            this.undoTop++;

            this.props.updateTaxDocument(this.undoState[this.undoTop]);
        }
    }

    private removeSignatureControlEFieForm = (control: TaxDocument.IEfileDocumentControl, pageNo: number): void => {
        let taxReturn = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId]?.taxReturn);
        const eFileInfo = new EfileInfoView(taxReturn);
        eFileInfo.removeSignatureControl(control, pageNo);
        this.updateTaxDocument(taxReturn);
    };

    private removeSignatureControlTaxReturnForm = (control: TaxDocument.ISignatureControl, pageNo: number): void => {
        let taxReturn = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId]?.taxReturn);
        const taxReturnInfo = new TaxReturnInfoView(taxReturn);
        taxReturnInfo.removeSignatureControl(control, pageNo);
        this.updateTaxDocument(taxReturn);
    };

    private removeSignatureControlInvoiceForm = (control: TaxDocument.ISignatureControl, pageNo: number): void => {
        let taxReturn = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId]?.taxReturn);
        const invoiceInfo = new InvoiceInfoView(taxReturn);
        invoiceInfo.removeSignatureControl(control, pageNo);
        this.updateTaxDocument(taxReturn);
    };

    private removeSignatureControlTransmittalForm = (control: TaxDocument.ISignatureControl, pageNo: number): void => {
        let taxReturn = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId]?.taxReturn);
        const transmittalInfo = new Transmittalsview(taxReturn);
        transmittalInfo.removeSignatureControl(control, pageNo);
        this.updateTaxDocument(taxReturn);
    };

    private addSignatureControlEFieForm = (control: TaxDocument.IEfileDocumentControl, pageNo: number): void => {
        let taxReturn = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId]?.taxReturn);
        const eFileInfo = new EfileInfoView(taxReturn);
        eFileInfo.addSignatureControl(control, pageNo);
        this.updateTaxDocument(taxReturn);
    };

    private addSignatureControlTaxReturnForm = (control: TaxDocument.ISignatureControl, pageNo: number): void => {
        let taxReturn = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId]?.taxReturn);
        const taxReturnInfo = new TaxReturnInfoView(taxReturn);
        taxReturnInfo.addSignatureControl(control, pageNo);
        this.updateTaxDocument(taxReturn);
    };

    private addSignatureControlInvoiceForm = (control: TaxDocument.ISignatureControl, pageNo: number): void => {
        let taxReturn = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId]?.taxReturn);
        const invoiceInfo = new InvoiceInfoView(taxReturn);
        invoiceInfo.addSignatureControl(control, pageNo);
        this.updateTaxDocument(taxReturn);
    };

    private addSignatureControlTransmittalForm = (control: TaxDocument.ISignatureControl, pageNo: number): void => {
        let taxReturn = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId]?.taxReturn);
        const transmittalInfo = new Transmittalsview(taxReturn);
        transmittalInfo.addSignatureControl(control, pageNo);
        this.updateTaxDocument(taxReturn);
    };

    private updateEROSignatureStamp = (userId: number): void => {
        const taxDocument = this.props.taxDocuments[this.props.docId]?.taxReturn;
        if (taxDocument && taxDocument.documentSettings) {
            let taxReturn = Helper.cloneTaxReturn(taxDocument);

            if (taxReturn.documentSettings.documentSignatureSetting.signatureStampUser) {
                taxReturn.documentSettings.documentSignatureSetting.signatureStampUser.userId = userId;
            } else {
                taxReturn.documentSettings.documentSignatureSetting.signatureStampUser = {
                    userId: userId
                } as User.IUserModel;
            }
            taxReturn.documentSettings.isModified = true;

            this.updateTaxDocument(taxReturn);
        }
    };

    private replaceSignatureControlEFieForm = (
        oldControl: TaxDocument.ISignatureControl,
        newControl: TaxDocument.ISignatureControl,
        pageNo: number
    ) => {
        let taxReturn = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId]?.taxReturn);
        const eFileInfo = new EfileInfoView(taxReturn);
        eFileInfo.replaceSignatureControl(oldControl, newControl, pageNo);
        this.updateTaxDocument(taxReturn);
    };

    private replaceSignatureControlTaxReturnForm = (
        oldControl: TaxDocument.ISignatureControl,
        newControl: TaxDocument.ISignatureControl,
        pageNo: number
    ) => {
        let taxReturn = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId]?.taxReturn);
        const taxReturnInfo = new TaxReturnInfoView(taxReturn);
        taxReturnInfo.replaceSignatureControl(oldControl, newControl, pageNo);
        this.updateTaxDocument(taxReturn);
    };

    private replaceSignatureControlInvoiceForm = (
        oldControl: TaxDocument.ISignatureControl,
        newControl: TaxDocument.ISignatureControl,
        pageNo: number
    ) => {
        let taxReturn = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId]?.taxReturn);
        const invoiceInfo = new InvoiceInfoView(taxReturn);
        invoiceInfo.replaceSignatureControl(oldControl, newControl, pageNo);
        this.updateTaxDocument(taxReturn);
    };

    private replaceSignatureControlTransmittalForm = (
        oldControl: TaxDocument.ISignatureControl,
        newControl: TaxDocument.ISignatureControl,
        pageNo: number
    ) => {
        let taxReturn = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId]?.taxReturn);
        const transmittalInfo = new Transmittalsview(taxReturn);
        transmittalInfo.replaceSignatureControl(oldControl, newControl, pageNo);
        this.updateTaxDocument(taxReturn);
    };

    private setAuthorityOptions() {
        let authoritiesOptions: TaxDocument.SelectOptions[] = [];
        this.props.authorities.map((x, i) => {
            authoritiesOptions.push({ value: x.Id.toString(), label: x.AuthorityName.toString() });
        });
        this.setState({ authoritiesOptions: authoritiesOptions });
    }

    private shareHolderOption() {
        let shareHolderOptions: TaxDocument.SelectOptions[] = [];
        let taxDocument =
            this.state.clonedTaxDocument == undefined
                ? this.props.taxDocuments[this.props.docId]?.taxReturn
                : this.state.clonedTaxDocument;
        taxDocument.formGroups
            .filter((x) => x.group === Number(TaxDocument.DocumentGroups.K1))
            .map((x: any) => {
                shareHolderOptions.push({ value: x.shareHolder.id, label: x.shareHolder.name });
            });
        this.setState({ shareHolderOptions: shareHolderOptions });
    }

    private setVoucherDueDate = (voucher: IVoucher) => {
        voucher.dueDate = undefined;

        if (this.props.voucherStore != undefined) {
            const voucherDueDate: any = this.props.voucherStore.voucherDefaultDates.find(
                (x) => x.authorityId == voucher.authorityID
            );
            if (voucherDueDate) {
                switch (Number(voucher.voucherNo)) {
                    case TaxDocument.VoucherNo.PaymentVoucher:
                        voucher.dueDate = voucherDueDate.taxFilingDate;
                        break;
                    case TaxDocument.VoucherNo.Q1:
                        voucher.dueDate = voucherDueDate.q1;
                        break;
                    case TaxDocument.VoucherNo.Q2:
                        voucher.dueDate = voucherDueDate.q2;
                        break;
                    case TaxDocument.VoucherNo.Q3:
                        voucher.dueDate = voucherDueDate.q3;
                        break;
                    case TaxDocument.VoucherNo.Q4:
                        voucher.dueDate = voucherDueDate.q4;
                        break;
                }
            } else {
                voucher.dueDate = moment().format("MM/DD/YYYY");
            }
        }
    };

    private validateInvoice = (model: TaxDocument.ITaxReturn): boolean => {
        const llc: any = TaxDocument.Arrangement[TaxDocument.Arrangement.SingleMemberLLC];
        if (this.props.companySettings.userPrivilegeSettingsModel.allowUserToSendwithoutInvoice || model.arrangement == llc)
            return true;
        else {
            let invoice = model.formGroups.find((x) => x.group == DocumentGroups.Invoice);
            return invoice != undefined && invoice != null && invoice.forms != null && invoice.forms.length > 0;
        }
    };

    onVoucherDataUpdate = (propertyType: TaxDocument.VoucherProperty, value: any, pageNumber: number) => {
        let vouchers = this.state.matchedForms.slice() as TaxDocument.IVoucher[];
        vouchers.forEach((tempVoucher) => {
            if (tempVoucher.pageNo[0] == pageNumber) {
                let voucher = tempVoucher;
                switch (propertyType) {
                    case TaxDocument.VoucherProperty.authorityID:
                        {
                            voucher.authorityID = value;
                        }
                        break;
                    case TaxDocument.VoucherProperty.voucherTypes:
                        {
                            voucher.voucherNo = value;
                            voucher.paymentType = TaxDocument.getVoucherTypes(value);
                        }
                        break;
                    case TaxDocument.VoucherProperty.dueDate:
                        {
                            if (value === "") {
                                voucher.dueDate = undefined;
                            } else {
                                voucher.dueDate = value;
                            }
                        }
                        break;
                    case TaxDocument.VoucherProperty.amount:
                        {
                            voucher.amount = isNaN(value) ? 0 : value;
                        }
                        break;
                    case TaxDocument.VoucherProperty.formName:
                        {
                            voucher.formName = value;
                        }
                        break;
                    case TaxDocument.VoucherProperty.customType:
                        {
                            voucher.customType = value;
                        }
                        break;
                    case TaxDocument.VoucherProperty.customText:
                        {
                            voucher.customText = value;
                        }
                        break;
                    case TaxDocument.VoucherProperty.onlinePaymentUri:
                        {
                            voucher.onlinePaymentUri = value;
                        }
                        break;
                    case TaxDocument.VoucherProperty.isMasterPaymentUrl:
                        {
                            voucher.isMasterPaymentUrl = value;
                        }
                        break;
                    case TaxDocument.VoucherProperty.isFormNameUpdated:
                        {
                            voucher.isFormNameUpdated = value;
                        }
                        break;
                }
            }
        });
        this.setState({ matchedForms: vouchers }, () => {
            this.forceUpdate();
        });
    };

    onEFileDataUpdate = (propertyType: TaxDocument.IEFileProperty, value: any, pageNumber: number) => {
        let eFiles = this.state.matchedForms.slice() as TaxDocument.IEFile[];
        eFiles.forEach(function (tempEFile) {
            if (tempEFile.pageNo[0] == pageNumber) {
                let eFile = tempEFile;
                switch (propertyType) {
                    case TaxDocument.IEFileProperty.authorityID:
                        {
                            eFile.authorityID = +value;
                        }
                        break;
                    case TaxDocument.IEFileProperty.formName:
                        {
                            eFile.formName = value;
                        }
                        break;
                }
            }
        });
        this.setState({ matchedForms: eFiles }, () => {
            this.forceUpdate();
        });
    };

    onK1DataDataUpdate = (propertyType: TaxDocument.K1Property, value: any, pageNumber: number) => {
        let k1shareHolders = this.state.matchedForms.slice() as TaxDocument.IK1ShareHolder[];
        let taxDocument = this.props.taxDocuments[this.props.docId]?.taxReturn;

        k1shareHolders.forEach(function (tempK1Share) {
            if (tempK1Share.pageNo[0] == pageNumber) {
                let k1Shareholder = tempK1Share;
                switch (propertyType) {
                    case TaxDocument.K1Property.shareHolderID:
                        {
                            k1Shareholder.shareHolderId = +value;
                            taxDocument.formGroups
                                .filter((x) => x.group == TaxDocument.DocumentGroups.K1)
                                .map((forms: any) => {
                                    if (forms.shareHolder.id == +value) {
                                        k1Shareholder.ssn = forms.shareHolder.ssn;
                                    }
                                });
                        }
                        break;
                    case TaxDocument.K1Property.formName:
                        {
                            k1Shareholder.formName = value;
                        }
                        break;
                }
            }
        });
        this.setState({ matchedForms: k1shareHolders }, () => {
            this.forceUpdate();
        });
    };
    validateReGropuData = (destinationGroup: TaxDocument.DocumentGroups, forms: TaxDocument.IFormBase[]) => {
        let isValid = true;
        switch (destinationGroup) {
            case TaxDocument.DocumentGroups.Vouchers:
                isValid = this.validateVoucherData(forms as TaxDocument.IVoucher[]);
                break;
            case TaxDocument.DocumentGroups.EFile:
                isValid = this.validateEFileData(forms as TaxDocument.IEFile[]);
                break;
            case TaxDocument.DocumentGroups.K1:
                isValid = this.validateKOneForms(forms as TaxDocument.IK1ShareHolder[]);
                break;
        }
        return isValid;
    };

    private reGroupTok1 = (pages: number[], forms: TaxDocument.IFormBase[], destinationGroup: TaxDocument.DocumentGroups) => {
        let k1formsData: TaxDocument.IK1ShareHolder[] = forms as TaxDocument.IK1ShareHolder[];
        let clone: TaxDocument.ITaxReturn =
            this.state.clonedTaxDocument == undefined
                ? { ...this.props.taxDocuments[this.props.docId]?.taxReturn }
                : this.state.clonedTaxDocument;
        Helper.removeForms(clone, pages);

        k1formsData.forEach(function (form) {
            let formsData = clone.formGroups.filter((x) => x.group == TaxDocument.DocumentGroups.K1);
            formsData.map(function (formGroup: any) {
                if (formGroup.shareHolder.id == form.shareHolderId) {
                    formGroup.forms = formGroup.forms.concat(form as TaxDocument.IFormBase);
                }
            });
        });
        this.updateTaxDocument(clone);
        this.onHideGroupFilesPreview();
        this.setState({ clonedTaxDocument: undefined });
    };
    private validateVoucherData(forms: TaxDocument.IVoucher[]) {
        let isValid = true;
        let voucherForms = forms as TaxDocument.IVoucher[];
        for (let i = 0; i < voucherForms.length; i++) {
            let vouchr = voucherForms[i];
            if (vouchr !== undefined) {
                const authorities: ITaxingAuthority[] = this.props.getAllTaxingAuthorities;
                let taxingAuthority: ITaxingAuthority = authorities[authorities.findIndex((x) => x.Id == vouchr.authorityID)];
                if (!vouchr.authorityID || vouchr.authorityID === 0) {
                    VenusNotifier.Warning(Constants.VoucherTabConstants.AddedVoucher.AuthorityWarning, "");
                    isValid = false;
                    break;
                }
                if (!vouchr.voucherNo) {
                    VenusNotifier.Warning(Constants.VoucherTabConstants.AddedVoucher.PaymentTypeWarning, "");
                    isValid = false;
                    break;
                } else {
                    switch (Number(vouchr.voucherNo)) {
                        case TaxDocument.VoucherNo.PaymentVoucher:
                        case TaxDocument.VoucherNo.Q1:
                        case TaxDocument.VoucherNo.Q2:
                        case TaxDocument.VoucherNo.Q3:
                        case TaxDocument.VoucherNo.Q4: {
                            break;
                        }
                        default: {
                            VenusNotifier.Warning(Constants.VoucherTabConstants.AddedVoucher.PaymentTypeWarning, "");
                            isValid = false;
                            break;
                        }
                    }
                }

                if (!vouchr.dueDate || !moment(vouchr.dueDate).isValid()) {
                    VenusNotifier.Warning(Constants.VoucherTabConstants.AddedVoucher.DueDateWarning, "");
                    isValid = false;
                    break;
                }
                if (Number.isNaN(vouchr.amount) || vouchr.amount === null) {
                    VenusNotifier.Warning(Constants.VoucherTabConstants.AddedVoucher.AmountEmptyWarning, "");
                    isValid = false;
                    break;
                }
                if (!vouchr.formName) {
                    VenusNotifier.Warning(Constants.VoucherTabConstants.AddedVoucher.FormNameWarning, "");
                    isValid = false;
                    break;
                }

                if (
                    (vouchr.customType === undefined ||
                        vouchr.customType === TaxDocument.CustomType.None ||
                        vouchr.customType === TaxDocument.CustomType.Select) &&
                    vouchr.vocherMode !== TaxDocument.VoucherMode.Recognized
                ) {
                    VenusNotifier.Warning(Constants.VoucherTabConstants.AddedVoucher.CustomTypeWarning, "");
                    isValid = false;
                    break;
                }
                if (vouchr.customType && vouchr.customType == TaxDocument.CustomType.OnlinePaymentRequired) {
                    if (!vouchr.onlinePaymentUri || vouchr.onlinePaymentUri === "") {
                        VenusNotifier.Warning(Constants.VoucherTabConstants.AddedVoucher.OnlinePaymentUrlWarning, "");
                        isValid = false;
                        break;
                    } else if (!ValidateWebsite(vouchr.onlinePaymentUri.trim())) {
                        VenusNotifier.Warning(Constants.VoucherTabConstants.AddedVoucher.OnlinePaymentValidUrlWarning, "");
                        isValid = false;
                        break;
                    }
                }
                if (vouchr.customType && vouchr.customType === TaxDocument.CustomType.AddCustom) {
                    if (!vouchr.customText || vouchr.customText.trim() === "") {
                        VenusNotifier.Warning(Constants.VoucherTabConstants.AddedVoucher.CustomTextWarning, "");
                        isValid = false;
                        break;
                    }
                }
                if (
                    taxingAuthority &&
                    taxingAuthority.CustomAuthority &&
                    vouchr.customType === TaxDocument.CustomType.OnlinePaymentRequired
                ) {
                    taxingAuthority.PrefixStateCode = false;
                    taxingAuthority.PaymentURL = vouchr.onlinePaymentUri;
                    this.props.saveTaxingAuthority(taxingAuthority, this.fetchCustomAuthority);
                }
            }
            if (isValid) {
                vouchr.vocherMode = TaxDocument.VoucherMode.Recognized;
            }
        }

        return isValid;
    }

    fetchCustomAuthority = () => {
        this.props.requestTaxingAuthorities(true);
    };
    private validateEFileData(eFileForms: TaxDocument.IEFile[]) {
        let isValid = true;
        for (let i = 0; i < eFileForms.length; i++) {
            var eFile = eFileForms[i];
            if (eFile !== undefined) {
                if (!eFile.authorityID || eFile.authorityID === 0) {
                    VenusNotifier.Warning("Please select Taxing Authority.", "");
                    isValid = false;
                    break;
                }
                if (!eFile.formName) {
                    VenusNotifier.Warning("Please enter Formname.", "");
                    isValid = false;
                    break;
                }
            }
        }
        return isValid;
    }
    private validateKOneForms(k1Forms: TaxDocument.IK1ShareHolder[]) {
        let isValid = true;
        for (let i = 0; i < k1Forms.length; i++) {
            var k1 = k1Forms[i];
            if (k1 !== undefined) {
                if (!k1.shareHolderId || k1.shareHolderId === 0) {
                    VenusNotifier.Warning("Please select Shareholder.", "");
                    isValid = false;
                    break;
                }
                if (!k1.formName) {
                    VenusNotifier.Warning("Please enter Formname.", "");
                    isValid = false;
                    break;
                }
            }
        }
        return isValid;
    }

    private cleanUpFormGroup = (taxReturn: ITaxReturn, ...formGroup: TaxDocument.DocumentGroups[]) => {
        this.setState({ clonedTaxDocument: Helper.cloneTaxReturn(taxReturn) });
        const formGroups = taxReturn.formGroups.filter(
            (x) => formGroup.some((g) => g == x.group) && (!x.forms || x.forms.length == 0)
        );
        taxReturn.formGroups = taxReturn.formGroups.filter((x) => formGroups.indexOf(x) < 0);
    };

    private addAdditionalEsignDocumentData = (uploadedAdditionalEsignDocumentData: DocumentUploadData[]) => {
        this.setState({ uploadedAdditionalEsignDocumentData: uploadedAdditionalEsignDocumentData });
    };

    private updateAdditionalEsignDocumentData = (updatedAdditionalEsignDocumentData: DocumentUploadData) => {
        let tempUploadedData: DocumentUploadData[] = this.state.uploadedAdditionalEsignDocumentData;

        tempUploadedData.map((item, index) => {
            if (item.name == updatedAdditionalEsignDocumentData.name) {
                item.documentType = updatedAdditionalEsignDocumentData.documentType;
                item.pdf = updatedAdditionalEsignDocumentData.pdf;
                item.isPDFloaded = updatedAdditionalEsignDocumentData.isPDFloaded;
            }
        });

        let taxReturn = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId]?.taxReturn);
        taxReturn.additionalEsignDocuments.map((item, index) => {
            if (item.fileName == updatedAdditionalEsignDocumentData.name) {
                item.documentType = updatedAdditionalEsignDocumentData.documentType;
                item.isModified = true;
            }
        });

        this.setState({ uploadedAdditionalEsignDocumentData: tempUploadedData });
        this.props.updateTaxDocument(taxReturn);
    };
    private validateAttachmentStatus(attachments: TaxDocument.IAttachment[]) {
        if (attachments.filter((x) => x.progressBar && x.progressBar < 100).length > 0) {
            VenusNotifier.Warning(Constants.FinishProcessReturn.StatusMessage.AttachmentValidationMessage, "Error");
            return false;
        }
        return true;
    }

    private addPaperFileData = (paperFileUploadData: PaperFileUploadData[]) => {
        this.setState({ paperFileUploadData: paperFileUploadData });
    };

    private updatePaperFile = (paperFile: PaperFileUploadData) => {
        let tempUploadedData: PaperFileUploadData[] = this.state.paperFileUploadData;

        tempUploadedData.map((item, index) => {
            if (item.fileName == paperFile.fileName) {
                item.mailingAddress = paperFile.mailingAddress;
                (item.formName = paperFile.formName),
                    (item.fileName = paperFile.fileName),
                    (item.authorityId = paperFile.authorityId),
                    (item.pdf = paperFile.pdf);
                item.isPDFloaded = paperFile.isPDFloaded;
            }
        });

        let taxReturn = Helper.cloneTaxReturn(this.props.taxDocuments[this.props.docId]?.taxReturn);
        taxReturn.paperFiles.map((item, index) => {
            if (item.fileName == paperFile.fileName) {
                item.mailingAddress = paperFile.mailingAddress;
                (item.formName = paperFile.formName),
                    (item.fileName = paperFile.fileName),
                    (item.authorityId = paperFile.authorityId),
                    (item.isModified = true);
            }
        });

        this.setState({ paperFileUploadData: tempUploadedData });
        this.props.updateTaxDocument(taxReturn);
    };

    private onNextReturnProcess = (nextProcessingReturnId: number, groupId: number) => {
        let currentlyProcessingtaxReturn: TaxDocument.ITaxReturn = Object.assign(
            {},
            this.props.taxDocuments[this.props.docId]?.taxReturn
        );

        this.setState({
            refreshTaxReturn: true
        });

        if (
            Helper.validateClientInfo(currentlyProcessingtaxReturn) &&
            validateRefudInfo(currentlyProcessingtaxReturn, this.props.authorities) &&
            validateRetentionPeriod(currentlyProcessingtaxReturn) &&
            Helper.validateDeliveryOption(currentlyProcessingtaxReturn, this.props.companySettings)
        ) {
            this.assignReturnOnOpen(currentlyProcessingtaxReturn);
            if (
                currentlyProcessingtaxReturn.documentStatus !== TaxDocument.DocumentStatus.REVIEW &&
                currentlyProcessingtaxReturn.documentStatus !== TaxDocument.DocumentStatus.READYFORDELIVERY
            ) {
                currentlyProcessingtaxReturn.documentStatus = TaxDocument.DocumentStatus.PROCESSING;
            }
            currentlyProcessingtaxReturn.lockedBy = 0;
            currentlyProcessingtaxReturn.isModified = true;

            if (this.state.markAsReadyForDeliveryChanged) {
                if (
                    this.state.markAsReadyForDelivery &&
                    this.props.companySettings.displaySettingsModel.isEnableInvoice &&
                    !this.validateInvoice(currentlyProcessingtaxReturn)
                ) {
                    VenusNotifier.Error(Constants.FinishProcessReturn.StatusMessage.DeliverToClientWithoutInvoiceError, null);
                    currentlyProcessingtaxReturn.documentStatus = TaxDocument.DocumentStatus.PROCESSING;
                    this.setState({
                        markAsReadyForDelivery: false
                    });
                    return;
                } else {
                    currentlyProcessingtaxReturn.documentStatus = this.state.markAsReadyForDelivery
                        ? TaxDocument.DocumentStatus.READYFORDELIVERY
                        : TaxDocument.DocumentStatus.PROCESSING;
                }
            }
            if (!this.props.companySettings.displaySettingsModel.isWatermarkEnabled) {
                currentlyProcessingtaxReturn.documentSettings.deliverySettings.watermarkId = 0;
            }
            if (!this.state.isBusy && this.validateAttachmentStatus(currentlyProcessingtaxReturn.attachments)) {
                this.setState({ isBusy: true, generateTaxPayer: false }, () => {
                    this.props.saveGroupedProcessReturnInfo(
                        currentlyProcessingtaxReturn,
                        this.getSubDocuments(),
                        this.getSelectedReturnsForDelivery(),
                        this.state.isK1Replaced,
                        this.state.isK1Restored,
                        this.state.initialMFJValue !== TaxDocument.isMutual(currentlyProcessingtaxReturn),
                        undefined,
                        (data: any) => {
                            this.props.updateGroupedReturnDocument(currentlyProcessingtaxReturn);
                            this.props.onGroupedReturnProcessOpen(nextProcessingReturnId, groupId, () => { });
                        },
                        undefined,
                        this.props.groupData
                    );
                });
            }
        }
    };

    getSelectedReturnsForDelivery = () => {
        let taxDocument: ISelectedDocumentForDeliveryViewModel[] = [];
        this.props.groupedTaxDocuments.map((taxReturn, i) => {
            if (taxReturn && taxReturn.isSelectedForDelivery && taxReturn.isSelectedForDelivery === true) {
                let document: ISelectedDocumentForDeliveryViewModel = {
                    taxDocumentId: taxReturn.id,
                    documentSettings: taxReturn.documentSettings,
                    engagementType: taxReturn.engagementType
                };

                taxDocument.push(document);
            }
        });
        return taxDocument;
    };

    private markReadyForDelivery = (returnId: number) => {
        ShowLoader();
        this.props.requestTaxDocument(
            returnId,
            true,
            undefined,
            undefined,
            (reloadedTaxReturn) => {
                let efileInfo =
                    reloadedTaxReturn && reloadedTaxReturn.formGroups.filter((x) => x.group === DocumentGroups.EFile)[0];
                if (
                    reloadedTaxReturn &&
                    reloadedTaxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType !==
                    SignatureType.ManualSign &&
                    efileInfo?.forms.length === 0
                ) {
                    VenusNotifier.Error(Constants.FinishProcessReturn.StatusMessage.MarkAsDeliverWithoutEfileError, null);
                    HideLoader();
                    return;
                }
                if (this.props.companySettings.displaySettingsModel.isEnableInvoice && !this.validateInvoice(reloadedTaxReturn)) {
                    VenusNotifier.Error(Constants.FinishProcessReturn.StatusMessage.DeliverToClientWithoutInvoiceError, null);
                    HideLoader();
                    return;
                }
                if (
                    validateTaxReturn(
                        reloadedTaxReturn,
                        this.props.userSettings.settings.useSignatureStamp,
                        this.props.authorities,
                        this.props.companySettings,
                        true,
                        true
                    )
                ) {
                    if (reloadedTaxReturn.lockedBy > 0 && reloadedTaxReturn.assignTo !== this.props.profile?.userId) {
                        // locked, assigned to other user
                        VenusNotifier.Warning(ProcessReturnConstants.ReturnInUse, null);
                        HideLoader();
                        return;
                    }

                    const isGroupedReturn = reloadedTaxReturn.groupId > 0;
                    if (!isGroupedReturn && !Helper.validateDocumentLocation(reloadedTaxReturn)) {
                        HideLoader();
                        return;
                    }

                    if (reloadedTaxReturn.partnerId === null || reloadedTaxReturn.partnerId === undefined) {
                        VenusNotifier.Warning(ClientInfoValidation.inCorrectPartner, null);
                        HideLoader();
                        return;
                    } else {
                        this.props.markAsReadyForDelivery(
                            reloadedTaxReturn.id,
                            this.completeMarkAsReadyForDelivery,
                            SendTaxReturnResources.GridButtonPrefix + SendTaxReturnResources.GridProcessButton
                        );
                    }
                } else {
                    HideLoader();
                }
            },
            undefined,
            true
        );
    };

    private completeMarkAsReadyForDelivery = (returnId: number) => {
        this.props.groupedTaxDocuments.map((item) => {
            if (item.id === returnId) {
                item.documentStatus = DocumentStatus.READYFORDELIVERY;
            }
            return item;
        });

        this.setState({ message: returnId + Constants.GroupedTaxDocumentConstants.StatusMessage.MarkAsReadyForDeliverySuccess });

        HideLoader();
    };

    private getMarkAsDeliveryStatus = (taxReturn: ITaxReturn): boolean => {
        return this.state.markAsReadyForDeliveryChanged
            ? this.state.markAsReadyForDelivery
            : taxReturn.documentStatus == TaxDocument.DocumentStatus.READYFORDELIVERY ||
            taxReturn.documentStatus == TaxDocument.DocumentStatus.REVIEW ||
            taxReturn.documentStatus == TaxDocument.DocumentStatus.APPROVEDFORDELIVERY;
    };

    private handleMarkAsReadyForDelivery = (checked: boolean, taxReturn: ITaxReturn) => {
        if (checked) {
            let documentInfo: ITaxReturn = this.props.taxDocuments[this.props.docId]?.taxReturn;
            let efileInfo = documentInfo && documentInfo.formGroups.filter((x) => x.group === DocumentGroups.EFile)[0];
            if (
                documentInfo &&
                documentInfo.documentSettings.documentSignatureSetting.signatureFormSelectionType !== SignatureType.ManualSign &&
                efileInfo?.forms.length === 0
            ) {
                VenusNotifier.Error(Constants.FinishProcessReturn.StatusMessage.MarkAsDeliverWithoutEfileError, null);
                this.setState({ markAsReadyForDelivery: false, markAsReadyForDeliveryChanged: false });
                return;
            }
            if (
                !validateTaxReturn(
                    documentInfo,
                    this.props.userSettings.settings.useSignatureStamp,
                    this.props.authorities,
                    this.props.companySettings,
                    true,
                    true
                )
            ) {
                this.setState({ markAsReadyForDelivery: false, markAsReadyForDeliveryChanged: false });
                return;
            }
        }
        this.setState({ markAsReadyForDelivery: checked, markAsReadyForDeliveryChanged: true });
    };

    private saveDeletedVouchers = (deletedVouchers: IVoucher[]) => {
        this.setState({ deletedVouchers: deletedVouchers });
    }
}
//=============================================================================
