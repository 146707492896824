import * as React from "react";
import { Card, Row, Col, Button, FormControl } from "react-bootstrap";
import * as TaxDocument from "../../common/TaxReturn";
import {
    isIndividualEnagagementType,
    validateControllerEmail,
    FilterSSN,
    GetCountryCode,
    validateConrollerInfo
} from "../../helper/HelperFunctions";
import { IGroupInfo } from "../../../store/groupedreturns/GroupedReturnProcessState";
import { ControllerInfoProperty } from "../../../store/groupedreturns/GroupedReturnProcessStore";
import { IsDuplicateGroupExtension } from "../../../store/common/ExtensionStore";
import { TaxIdentificationNumber } from "../../common/controls/TaxIdentificationNumber";
import Select from "react-select";
import { PhoneNumberComponent } from "../../common/PhoneNumberComponent";
import { GroupProcessReturnsConstants, FinishProcessReturn } from "../../helper/Constants";
import * as bootbox from "bootbox";
import { EngagementType, ITaxReturnExtension, IDisableExtensionReminders } from "../../common/TaxReturn";

interface IDeliverToClient {
    groupedTaxDocuments: any;
    taxreturn: any;
    cancelFinish: () => void;
    deliverToController: () => void;
    updateTaxDocument: (taxDocument: TaxDocument.ITaxReturn) => void;
    groupInfo: IGroupInfo;
    changeControllerInfo: (item: ControllerInfoProperty, value: any) => void;
}

interface IDeliverToClientState {
    name: string;
    email: string;
    ssn: string;
    mobileNumber: string;
    countryCode: string;
    sendButtonClicked: boolean;
    popupButtonClicked: boolean;
}

export class DeliverToController extends React.Component<IDeliverToClient, IDeliverToClientState> {
    constructor(props: IDeliverToClient) {
        super(props);
        this.state = {
            name: "",
            email: "",
            ssn: "",
            mobileNumber: "",
            countryCode: "",
            sendButtonClicked: false,
            popupButtonClicked: false
        };
        if (props.groupInfo.controllerInfo) {
            this.state = {
                name: props.groupInfo.controllerInfo.name || "",
                email: props.groupInfo.controllerInfo.emailAddress || "",
                ssn: props.groupInfo.controllerInfo.ssn || "",
                mobileNumber: props.groupInfo.controllerInfo.mobileNumber || "",
                countryCode: props.groupInfo.controllerInfo.countryCode || "",
                sendButtonClicked: false,
                popupButtonClicked: false
            };
        }

        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleBlurName = this.handleBlurName.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleBlurEmail = this.handleBlurEmail.bind(this);
        this.handleChangeSSN = this.handleChangeSSN.bind(this);
        this.handleBlurMobileNumber = this.handleBlurMobileNumber.bind(this);
        this.onChangeMobileNumber = this.onChangeMobileNumber.bind(this);
        this.handleBlurCountryCode = this.handleBlurCountryCode.bind(this);
        this.onChangeCountryCode = this.onChangeCountryCode.bind(this);
    }
    componentWillReceiveProps(nextProps: IDeliverToClient) {
        if (nextProps.groupInfo) {
            this.state = {
                name: nextProps.groupInfo.controllerInfo.name,
                email: nextProps.groupInfo.controllerInfo.emailAddress,
                ssn: nextProps.groupInfo.controllerInfo.ssn,
                mobileNumber: nextProps.groupInfo.controllerInfo.mobileNumber,
                countryCode: nextProps.groupInfo.controllerInfo.countryCode,
                sendButtonClicked: false,
                popupButtonClicked: false
            };
        }
    }
    handleChangeName(event: any) {
        this.setState({ name: event.target.value });
    }

    handleBlurName(event: any) {
        this.props.changeControllerInfo(ControllerInfoProperty.name, event.target.value);
    }

    handleChangeEmail(event: any) {
        this.setState({ email: event.target.value });
    }

    handleBlurEmail(event: any) {
        this.props.changeControllerInfo(ControllerInfoProperty.emailAddress, event.target.value);
    }
    handleChangeSSN(value: string) {
        this.props.changeControllerInfo(ControllerInfoProperty.ssn, value);
    }
    handleBlurMobileNumber = (event: any) => {
        this.props.changeControllerInfo(ControllerInfoProperty.mobileNumber, this.state.mobileNumber);
    };
    handleBlurCountryCode = (event: any) => {
        this.props.changeControllerInfo(ControllerInfoProperty.countryCode, this.state.countryCode);
    };
    onChangeCountryCode = (event: any) => {
        let value = event ? event.value : "";
        this.setState({
            countryCode: value
        });
    };

    onChangeMobileNumber = (value: any) => {
        this.setState({
            mobileNumber: value
        });
    };

    deliveryActionButton = (callback: any) => {
        if (this.state.popupButtonClicked == false) {
            this.setState(
                {
                    popupButtonClicked: true
                },
                () => {
                    callback();
                }
            );
        }
    };

    public onDeliverToController = () => {
        if (validateConrollerInfo(this.props.groupInfo) && validateControllerEmail(this.props.groupInfo)) {
            if (this.props.groupedTaxDocuments.length > 0) {
                let _taxReturnExtension: ITaxReturnExtension[] = [],
                    _DisableExtensionReminders: IDisableExtensionReminders[] = [];
                this.props.groupedTaxDocuments.some((item, i, self) => {
                    if (self[i].isSelectedForDelivery || self[i].id == this.props.taxreturn.id) {
                        this.setState({ sendButtonClicked: true });
                        let taxYear: number = self[i].taxYear ? self[i].taxYear : 0;
                        let engagementType: any = self[i].engagementType ? EngagementType[self[i].engagementType] : 0;
                        let taxIdentifier: string = isIndividualEnagagementType(self[i].engagementType)
                            ? self[i].taxpayer.ssn
                            : self[i].partnership.ssn;
                        let ssrid: number = self[i].id ? self[i].id : 0;

                        _taxReturnExtension.push({
                            taxYear: taxYear,
                            engagementType: engagementType,
                            taxIdentifier: taxIdentifier
                        });
                        console.log(_taxReturnExtension);
                        _DisableExtensionReminders.push({
                            taxYear: taxYear,
                            engagementType: engagementType,
                            taxIdentifier: taxIdentifier,
                            ssrId: ssrid
                        });
                    }
                });
                let apiUrl: string = "api/GroupedReturns/GetTaxExtensionsDetailsListAsync";
                IsDuplicateGroupExtension(_taxReturnExtension, apiUrl)
                    .then((isDuplicate) => {
                        if (isDuplicate) {
                            let tempThis = this;
                            let numberOfSelectedReturns = this.props.groupedTaxDocuments.filter(
                                (x) => x.isSelectedForDelivery || x.id == this.props.taxreturn.id
                            ).length;
                            bootbox.dialog({
                                message:
                                    numberOfSelectedReturns == 1
                                        ? FinishProcessReturn.StatusMessage.DisableExtensionRemindersConfirmation.replace(
                                              "<<taxyear>>",
                                              _taxReturnExtension[0].taxYear.toString()
                                          )
                                        : GroupProcessReturnsConstants.DisableExtensionRemindersForGroupedReturnsConfirmation,
                                className: "extensions-modal",
                                buttons: {
                                    cancel: {
                                        label: "No",
                                        className: "btn-cancel btn-default",
                                        callback: function () {
                                            tempThis.deliveryActionButton(() => tempThis.props.deliverToController());
                                        }
                                    },
                                    confirm: {
                                        label: "Yes, turn it off",
                                        className: "btn-confirm",
                                        callback: function () {
                                            tempThis.deliveryActionButton(() => {
                                                apiUrl = "api/GroupedReturns/DisableExtensionRemindersAsync";
                                                IsDuplicateGroupExtension(_DisableExtensionReminders, apiUrl);
                                                tempThis.props.deliverToController();
                                            });
                                        }
                                    }
                                }
                            });
                        } else {
                            this.props.deliverToController();
                        }
                    })
                    .catch((error) => {
                        this.props.deliverToController();
                    });
            }
        }
    };
    public render() {
        return (
            <Card>
                <Card.Body>
                    <div>
                        <Row className="marB15">
                            <Col className="text-right" sm={4} as={Col}>
                                Name
                            </Col>
                            <Col sm={8} as={Col}>
                                <FormControl
                                    type="text"
                                    value={this.state.name}
                                    placeholder="Enter Name"
                                    onChange={this.handleChangeName}
                                    onBlur={this.handleBlurName}
                                    data-test-auto="A8FE0A0F-49BC-4D41-AA55-D6E9DC47EBE2"
                                />
                            </Col>
                        </Row>
                        <Row className="marB15">
                            <Col className="text-right" sm={4} as={Col}>
                                SSN
                            </Col>
                            <Col sm={8} as={Col}>
                                <TaxIdentificationNumber
                                    value={
                                        this.props.groupInfo.controllerInfo
                                            ? FilterSSN(this.props.groupInfo.controllerInfo.ssn)
                                            : ""
                                    }
                                    onChange={this.handleChangeSSN}
                                    placeholder={"Enter SSN"}
                                    mask="000-00-0000"
                                />
                            </Col>
                        </Row>

                        <Row className="marB15">
                            <Col className="text-right" sm={4} as={Col}>
                                Email
                            </Col>
                            <Col sm={8} as={Col}>
                                <FormControl
                                    type="email"
                                    placeholder="Enter Email"
                                    value={this.state.email}
                                    onChange={this.handleChangeEmail}
                                    onBlur={this.handleBlurEmail}
                                    data-test-auto="B23A8320-C234-4F91-8F37-8C431E2FB41C"
                                />
                            </Col>
                        </Row>

                        <Row className="marB15">
                            <Col className="text-right" sm={4} as={Col}>
                                Mobile Number
                            </Col>

                            <Col sm={8} as={Col} className="dispFlex">
                                <Col sm={5} className="padL00 padR02 customClassCC">
                                    <Select
                                        name="form-field-country-code"
                                        value={this.state.countryCode}
                                        onChange={this.onChangeCountryCode}
                                        options={GetCountryCode()}
                                        onBlur={this.handleBlurCountryCode}
                                        data-test-auto="EB1E7C0C-1427-444A-A3B6-4815820DA04F"
                                        clearable={true}
                                        searchable={true}
                                    ></Select>
                                </Col>
                                <Col sm={7} className="pad00">
                                    <PhoneNumberComponent
                                        className={"form-control padL08 phone-number-input"}
                                        phoneNumber={this.state.mobileNumber ? this.state.mobileNumber : ""}
                                        handleChangePhoneNumber={this.onChangeMobileNumber}
                                        onBlur={this.handleBlurMobileNumber}
                                        disabled={false}
                                    ></PhoneNumberComponent>
                                </Col>
                            </Col>
                        </Row>
                    </div>

                    <div>
                        <Col sm={12}>
                            <Button
                                type="button"
                                variant="info"
                                className="float-right"
                                data-test-auto="38ADB87A-749A-42F0-9B5C-485CAAB33884"
                                onClick={this.onDeliverToController}
                                disabled={this.state.sendButtonClicked}
                            >
                                <i className="fa fa-paper-plane"></i>Send
                            </Button>
                            <Button
                                type="button"
                                variant="default"
                                className="float-right marR05 btn-white btn-default"
                                data-test-auto="6EB41ED8-DA04-41E0-AFDA-205244BF5008"
                                onClick={this.props.cancelFinish}
                            >
                                <i className="fas fa-times"></i>Cancel
                            </Button>
                        </Col>
                    </div>
                </Card.Body>
            </Card>
        );
    }
}

export default DeliverToController;
